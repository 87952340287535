import {
  Account,
  OutboxPublisherFile,
  SocialNetwork
} from '@ui-resources-angular';
import {
  getLowestValue,
  getHighestValue,
  oneRecordHasField,
  recordFieldUnionValues
} from '../../../../../apps/angular/common/util';
// import {
//   DocumentMimeType,
//   ImageMimetype,
//   VideoMimetype
// } from '../../../modules/constants';

enum ImageMimetype {
  Jpeg = 'image/jpeg',
  Pjpeg = 'image/pjpeg',
  Png = 'image/png',
  Gif = 'image/gif'
}

enum DocumentMimeType {
  Pdf = 'application/pdf'
}

enum VideoMimetype {
  Mp4 = 'video/mp4',
  Mov = 'video/quicktime',
  WebM = 'video/webm'
}

export enum MediaCategory {
  Post = 'post',
  Story = 'story',
  Reel = 'reel'
}

export interface SizeRestrictions {
  max: number;
}
export interface DurationRestrictions {
  min: number;
  max: number;
}
export interface AspectRatioRestrictions {
  min: number;
  max: number;
}
export interface DimensionsRestrictions {
  width: {
    min: number;
    max: number;
  };
  height: {
    min: number;
    max: number;
  };
}
export interface TitleRestrictions {
  length: {
    min: number;
    max: number;
  };
}

export interface ImageRestrictions {
  min: number;
  max: number;
  remaining?: number;
  mimetypes?: ImageMimetype[];
  size?: SizeRestrictions;
  dimensions?: DimensionsRestrictions;
  aspectRatio?: AspectRatioRestrictions;
  linkRequired?: boolean;
}
export interface VideoRestrictions {
  min: number;
  max: number;
  remaining?: number;
  mimetypes?: VideoMimetype[];
  size?: SizeRestrictions;
  dimensions?: DimensionsRestrictions;
  aspectRatio?: AspectRatioRestrictions;
  duration?: DurationRestrictions;
  title?: TitleRestrictions;
}
export interface GifRestrictions {
  min: number;
  max: number;
  remaining?: number;
  mimetypes?: ImageMimetype[];
  size?: SizeRestrictions;
  dimensions?: DimensionsRestrictions;
  aspectRatio?: AspectRatioRestrictions;
  title?: TitleRestrictions;
}
export interface DocumentRestrictions {
  mimetypes?: DocumentMimeType[];
  size?: SizeRestrictions;
}

export interface IMediaRestrictions {
  image: ImageRestrictions;
  imageStory?: ImageRestrictions;
  video: VideoRestrictions;
  videoStory?: VideoRestrictions;
  reel?: VideoRestrictions;
  gif?: GifRestrictions;
  document?: DocumentRestrictions;

  allSupportedMimetypes?: string[];
  min?: number;
}

export interface ISocialNetworkMediaRestrictions {
  post: IMediaRestrictions;
  reply: IMediaRestrictions;
  privateMessage: IMediaRestrictions;
}

export class MediaRestrictions implements IMediaRestrictions {
  image: ImageRestrictions;
  imageStory: ImageRestrictions;
  video: VideoRestrictions;
  videoStory: VideoRestrictions;
  reel: VideoRestrictions;
  gif: GifRestrictions;
  document: DocumentRestrictions;

  allSupportedMimetypes?;
  min?;

  constructor() {
    this.init();
  }

  init(): MediaRestrictions {
    this.image = MediaRestrictions.initial.image;
    this.imageStory = MediaRestrictions.initial.image;
    this.video = MediaRestrictions.initial.video;
    this.videoStory = MediaRestrictions.initial.video;
    this.reel = MediaRestrictions.initial.video;
    this.gif = MediaRestrictions.initial.gif;
    this.document = MediaRestrictions.initial.document;

    return this;
  }

  forAccount(
    account: Account,
    publisherType: string = 'post'
  ): MediaRestrictions {
    this.image = {
      ...MediaRestrictions.initial.image,
      ...MediaRestrictions.networkSpecific[account.accountTypeName][
        publisherType
      ].image
    };
    this.imageStory = {
      ...MediaRestrictions.initial.image,
      ...MediaRestrictions.networkSpecific[account.accountTypeName][
        publisherType
      ].imageStory
    };
    this.video = {
      ...MediaRestrictions.initial.video,
      ...MediaRestrictions.networkSpecific[account.accountTypeName][
        publisherType
      ].video
    };
    this.videoStory = {
      ...MediaRestrictions.initial.video,
      ...MediaRestrictions.networkSpecific[account.accountTypeName][
        publisherType
      ].videoStory
    };
    this.reel = {
      ...MediaRestrictions.initial.video,
      ...MediaRestrictions.networkSpecific[account.accountTypeName][
        publisherType
      ].reel
    };
    this.gif = {
      ...MediaRestrictions.initial.gif,
      ...MediaRestrictions.networkSpecific[account.accountTypeName][
        publisherType
      ].gif
    };
    this.document =
      MediaRestrictions.networkSpecific[account.accountTypeName][
        publisherType
      ].document;

    // console.log('restrictions, acc: ', this, account);

    return this;
  }

  combine(
    socialNetworks: { config: SocialNetwork; accounts: Account[] }[],
    type: string = 'post',
    mediaType?: string
  ): MediaRestrictions {
    this.image.linkRequired = oneRecordHasField(
      socialNetworks,
      `config.mediaRestrictions.${type}.image.linkRequired`
    );

    this.video.title.length.min = getHighestValue(
      socialNetworks,
      `config.mediaRestrictions.${type}.video.title.length.min`
    );
    this.video.title.length.max = getLowestValue(
      socialNetworks,
      `config.mediaRestrictions.${type}.video.title.length.max`
    );
    this.reel.title.length.min = getHighestValue(
      socialNetworks,
      `config.mediaRestrictions.${type}.reel.title.length.min`
    );
    this.reel.title.length.max = getLowestValue(
      socialNetworks,
      `config.mediaRestrictions.${type}.reel.title.length.max`
    );
    this.gif.title.length.min = getHighestValue(
      socialNetworks,
      `config.mediaRestrictions.${type}.gif.title.length.min`
    );
    this.gif.title.length.max = getLowestValue(
      socialNetworks,
      `config.mediaRestrictions.${type}.gif.title.length.max`
    );

    this.video.duration.min = getHighestValue(
      socialNetworks,
      `config.mediaRestrictions.${type}.video.duration.min`
    );
    this.video.duration.max = getLowestValue(
      socialNetworks,
      `config.mediaRestrictions.${type}.video.duration.max`
    );

    this.videoStory.duration.min = getHighestValue(
      socialNetworks,
      `config.mediaRestrictions.${type}.videoStory.duration.min`
    );
    this.videoStory.duration.max = getLowestValue(
      socialNetworks,
      `config.mediaRestrictions.${type}.videoStory.duration.max`
    );
    this.reel.duration.min = getHighestValue(
      socialNetworks,
      `config.mediaRestrictions.${type}.reel.duration.min`
    );
    this.reel.duration.max = getLowestValue(
      socialNetworks,
      `config.mediaRestrictions.${type}.reel.duration.max`
    );

    ['image', 'imageStory', 'video', 'videoStory', 'reel', 'gif'].forEach(
      (mediaType) => {
        const filteredSocialNetworks = socialNetworks.filter(
          (sn) => !!sn.config.mediaRestrictions[type][mediaType]
        );

        this[mediaType].min = getHighestValue(
          filteredSocialNetworks,
          `config.mediaRestrictions.${type}.${mediaType}.min`
        );
        this[mediaType].max = getLowestValue(
          filteredSocialNetworks,
          `config.mediaRestrictions.${type}.${mediaType}.max`
        );

        this[mediaType].dimensions.width.min = getHighestValue(
          filteredSocialNetworks,
          `config.mediaRestrictions.${type}.${mediaType}.dimensions.width.min`
        );
        this[mediaType].dimensions.width.max = getLowestValue(
          filteredSocialNetworks,
          `config.mediaRestrictions.${type}.${mediaType}.dimensions.width.max`
        );
        this[mediaType].dimensions.height.min = getHighestValue(
          filteredSocialNetworks,
          `config.mediaRestrictions.${type}.${mediaType}.dimensions.height.min`
        );
        this[mediaType].dimensions.height.max = getLowestValue(
          filteredSocialNetworks,
          `config.mediaRestrictions.${type}.${mediaType}.dimensions.height.max`
        );

        this[mediaType].size.max = getLowestValue(
          filteredSocialNetworks,
          `config.mediaRestrictions.${type}.${mediaType}.size.max`
        );

        this[mediaType].mimetypes = recordFieldUnionValues<ImageMimetype>(
          filteredSocialNetworks,
          `config.mediaRestrictions.${type}.${mediaType}.mimetypes`
        );

        if (
          filteredSocialNetworks.some(
            (sn) => !!sn.config.mediaRestrictions[type][mediaType].aspectRatio
          )
        ) {
          this[mediaType].aspectRatio = {
            min: getHighestValue(
              filteredSocialNetworks,
              `config.mediaRestrictions.${type}.${mediaType}.aspectRatio.min`
            ),
            max: getLowestValue(
              filteredSocialNetworks,
              `config.mediaRestrictions.${type}.${mediaType}.aspectRatio.max`
            )
          };
        }
      }
    );

    // console.log('restrictions, combined: ', this);

    return this;
  }

  static get common(): { [key: string]: IMediaRestrictions } {
    return {
      noMedia: {
        image: {
          ...MediaRestrictions.initial.image,
          min: 0,
          max: 0
        },
        video: {
          ...MediaRestrictions.initial.video,
          min: 0,
          max: 0
        },
        gif: {
          ...MediaRestrictions.initial.gif,
          min: 0,
          max: 0
        },
        document: {
          size: {
            max: 0
          }
        }
      }
    };
  }

  static get initial(): IMediaRestrictions {
    return {
      image: {
        mimetypes: [],
        size: {
          max: undefined
        },
        min: 0,
        max: undefined,
        linkRequired: false,
        dimensions: {
          width: {
            min: 0,
            max: undefined
          },
          height: {
            min: 0,
            max: undefined
          }
        },
        remaining: 0
      },
      video: {
        mimetypes: [],
        size: {
          max: undefined
        },
        min: 0,
        max: undefined,
        duration: {
          min: 0,
          max: undefined
        },
        dimensions: {
          width: {
            min: 0,
            max: undefined
          },
          height: {
            min: 0,
            max: undefined
          }
        },
        title: {
          length: {
            min: 0,
            max: undefined
          }
        },
        remaining: 0
      },
      gif: {
        mimetypes: [],
        size: {
          max: undefined
        },
        min: 0,
        max: undefined,
        dimensions: {
          width: {
            min: 0,
            max: undefined
          },
          height: {
            min: 0,
            max: undefined
          }
        },
        title: {
          length: {
            min: 0,
            max: undefined
          }
        },
        remaining: 0
      },
      document: {
        mimetypes: [],
        size: {
          max: undefined
        }
      }
    };
  }

  static get networkSpecific(): {
    [key: string]: ISocialNetworkMediaRestrictions;
  } {
    return {
      // '3'
      facebook: {
        post: {
          image: {
            // https://developers.facebook.com/docs/graph-api/reference/photo/
            mimetypes: [
              ImageMimetype.Jpeg,
              ImageMimetype.Pjpeg,
              ImageMimetype.Png,
              ImageMimetype.Gif
            ],
            min: 0,
            max: 1000,
            size: {
              max: 10 * 1024 * 1024
            },
            dimensions: {
              width: {
                min: 0,
                max: 4032 // seems like there is no limit on FB API, setting 4032 as it's some optimal default resolution of smartphones (12MPX cameras)
              },
              height: {
                min: 0,
                max: 4032
              }
            }
          },
          video: {
            // https://developers.facebook.com/docs/graph-api/reference/video/
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 1024 * 1024 * 1024
            },
            duration: {
              min: 0,
              max: 1200 * 1000 // 20min
            },
            title: {
              length: {
                min: 1,
                max: 255
              }
            },
            min: 0,
            max: 1
          },
          reel: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4],
            size: {
              max: 1 * 1024 * 1024 * 1024
            },
            min: 1,
            max: 1,
            aspectRatio: {
              min: 9 / 16,
              max: 9 / 16
            },
            dimensions: {
              width: {
                min: 540,
                max: 1080
              },
              height: {
                min: 960,
                max: 1920 // will be calculated depending on the width and aspect ratio
              }
            },
            duration: {
              min: 3 * 1000,
              max: 90 * 1000
            },
            title: {
              length: {
                min: 1,
                max: 0
              }
            }
          },
          gif: {
            ...MediaRestrictions.initial.gif,
            mimetypes: [ImageMimetype.Gif],
            size: {
              max: 25 * 1024 * 1024
            },
            title: {
              length: {
                min: 1,
                max: 255
              }
            },
            min: 0,
            max: 1
          }
        },
        reply: {
          video: MediaRestrictions.common.noMedia.video,
          image: {
            mimetypes: [
              ImageMimetype.Jpeg,
              ImageMimetype.Pjpeg,
              ImageMimetype.Png,
              ImageMimetype.Gif
            ],
            min: 0,
            max: 1,
            size: {
              max: 10 * 1024 * 1024
            },
            dimensions: {
              width: {
                min: 0,
                max: 4032
              },
              height: {
                min: 0,
                max: 4032
              }
            }
          },
          gif: {
            mimetypes: [ImageMimetype.Gif],
            min: 0,
            max: 1,
            size: {
              max: 25 * 1024 * 1024 // in bytes
            },
            dimensions: {
              width: {
                min: 0,
                max: 1280
              },
              height: {
                min: 0,
                max: 1080
              }
            }
          }
        },
        privateMessage: MediaRestrictions.common.noMedia
      },
      // '15'
      facebookgroup: {
        post: {
          image: {
            // https://developers.facebook.com/docs/graph-api/reference/photo/
            ...MediaRestrictions.initial.image,
            mimetypes: [
              ImageMimetype.Jpeg,
              ImageMimetype.Pjpeg,
              ImageMimetype.Png,
              ImageMimetype.Gif
            ],
            min: 0,
            max: 1,
            size: {
              max: 10 * 1024 * 1024
            },
            dimensions: {
              width: {
                min: 0,
                max: 4032
              },
              height: {
                min: 0,
                max: 4032
              }
            }
          },
          video: {
            // https://developers.facebook.com/docs/graph-api/reference/video/
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 1024 * 1024 * 1024
            },
            duration: {
              min: 0,
              max: 1200 * 1000 // 20min
            },
            title: {
              length: {
                min: 1,
                max: 255
              }
            },
            min: 0,
            max: 1
          },
          reel: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4],
            size: {
              max: 1 * 1024 * 1024 * 1024
            },
            min: 1,
            max: 1,
            aspectRatio: {
              min: 9 / 16,
              max: 9 / 16
            },
            dimensions: {
              width: {
                min: 540,
                max: 1080
              },
              height: {
                min: 960,
                max: 1920 // will be calculated depending on the width and aspect ratio
              }
            },
            duration: {
              min: 3 * 1000,
              max: 90 * 1000
            },
            title: {
              length: {
                min: 1,
                max: 0
              }
            }
          },
          gif: {
            ...MediaRestrictions.initial.gif,
            mimetypes: [ImageMimetype.Gif],
            size: {
              max: 25 * 1024 * 1024
            },
            title: {
              length: {
                min: 1,
                max: 255
              }
            },
            min: 0,
            max: 1
          }
        },
        reply: {
          video: MediaRestrictions.common.noMedia.video,
          image: {
            mimetypes: [
              ImageMimetype.Jpeg,
              ImageMimetype.Pjpeg,
              ImageMimetype.Png,
              ImageMimetype.Gif
            ],
            min: 0,
            max: 1,
            size: {
              max: 10 * 1024 * 1024
            },
            dimensions: {
              width: {
                min: 0,
                max: 4032
              },
              height: {
                min: 0,
                max: 4032
              }
            }
          },
          gif: {
            mimetypes: [ImageMimetype.Gif],
            min: 0,
            max: 1,
            size: {
              max: 25 * 1024 * 1024 // in bytes
            },
            dimensions: {
              width: {
                min: 0,
                max: 1280
              },
              height: {
                min: 0,
                max: 1080
              }
            }
          }
        },
        privateMessage: MediaRestrictions.common.noMedia
      },
      // '2'
      twitter: {
        // https://developer.twitter.com/en/docs/twitter-api/v1/media/upload-media/uploading-media/media-best-practices
        post: {
          ...MediaRestrictions.initial,
          image: {
            ...MediaRestrictions.initial.image,
            mimetypes: [
              ImageMimetype.Jpeg,
              ImageMimetype.Pjpeg,
              ImageMimetype.Png,
              ImageMimetype.Gif
            ],
            min: 0,
            max: 4,
            size: {
              max: 5 * 1024 * 1024 // in bytes
            },
            dimensions: {
              width: {
                min: 0,
                max: 4032 // see CT-460, // seems like there is no limit on Twitter API, setting 4032 as it's some optimal default resolution of smartphones (12MPX cameras)
              },
              height: {
                min: 0,
                max: 4032
              }
            }
          },
          video: {
            ...MediaRestrictions.initial.video,
            duration: {
              // in ms
              min: 500,
              max: 140 * 1000
            },
            aspectRatio: {
              min: 1 / 3,
              max: 3 / 1
            },
            dimensions: {
              width: {
                min: 32, // in px
                max: 1920
              },
              height: {
                min: 32,
                max: 1920
              }
            },
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 512 * 1024 * 1024 // in bytes
            },
            min: 0,
            max: 1,
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          },
          gif: {
            ...MediaRestrictions.initial.gif,
            mimetypes: [ImageMimetype.Gif],
            min: 0,
            max: 1,
            size: {
              max: 15 * 1024 * 1024 // in bytes
            },
            dimensions: {
              width: {
                min: 0,
                max: 1280
              },
              height: {
                min: 0,
                max: 1080
              }
            },
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          }
        },
        reply: {
          image: {
            mimetypes: [
              ImageMimetype.Jpeg,
              ImageMimetype.Pjpeg,
              ImageMimetype.Png,
              ImageMimetype.Gif
            ],
            min: 0,
            max: 4,
            size: {
              max: 5 * 1024 * 1024 // in bytes
            },
            dimensions: {
              width: {
                min: 0,
                max: 4032 // see CT-460
              },
              height: {
                min: 0,
                max: 4032
              }
            }
          },
          video: {
            duration: {
              // in ms
              min: 500,
              max: 140 * 1000
            },
            aspectRatio: {
              min: 1 / 3,
              max: 3 / 1
            },
            dimensions: {
              width: {
                min: 32, // in px
                max: 1280
              },
              height: {
                min: 32,
                max: 1024
              }
            },
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 512 * 1024 * 1024 // in bytes
            },
            min: 0,
            max: 1
          },
          gif: {
            mimetypes: [ImageMimetype.Gif],
            min: 0,
            max: 1,
            size: {
              max: 15 * 1024 * 1024 // in bytes
            },
            dimensions: {
              width: {
                min: 0,
                max: 1280
              },
              height: {
                min: 0,
                max: 1080
              }
            }
          }
        },
        privateMessage: MediaRestrictions.common.noMedia
      },
      // '4'
      linkedin: {
        post: {
          image: {
            // https://learn.microsoft.com/en-us/linkedin/marketing/integrations/community-management/shares/images-api?view=li-lms-2023-02&tabs=http
            ...MediaRestrictions.initial.image,
            mimetypes: [
              ImageMimetype.Jpeg,
              ImageMimetype.Pjpeg,
              ImageMimetype.Png,
              ImageMimetype.Gif
            ],
            min: 0,
            max: 9,
            size: {
              max: 20 * 1024 * 1024 // in bytes
            },
            dimensions: {
              width: {
                min: 0,
                max: 4032 // limit seems to be higher,but setting 4032 as it's some optimal default resolution of smartphones (12MPX cameras)
              },
              height: {
                min: 0,
                max: 4032
              }
            }
          },
          video: {
            ...MediaRestrictions.initial.video,
            duration: {
              min: 3 * 1000,
              max: 10 * 60 * 1000 // 10 mins
            },
            aspectRatio: {
              min: 1 / 2.4,
              max: 2.4 / 1
            },
            dimensions: {
              width: {
                min: 32, // in px
                max: 2400
              },
              height: {
                min: 32,
                max: 1254
              }
            },
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 5 * 1024 * 1024 * 1024
            },
            min: 0,
            max: 1,
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          },
          gif: {
            ...MediaRestrictions.initial.gif,
            mimetypes: [ImageMimetype.Gif],
            size: {
              max: 25 * 1024 * 1024
            },
            min: 0,
            max: 1,
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          }
        },
        reply: {
          image: {
            mimetypes: [
              ImageMimetype.Jpeg,
              ImageMimetype.Pjpeg,
              ImageMimetype.Png,
              ImageMimetype.Gif
            ],
            min: 0,
            max: 1,
            size: {
              max: 20 * 1024 * 1024 // in bytes
            },
            dimensions: {
              width: {
                min: 0,
                max: 4032
              },
              height: {
                min: 0,
                max: 4032
              }
            }
          },
          video: MediaRestrictions.common.noMedia.video,
          gif: {
            mimetypes: [ImageMimetype.Gif],
            size: {
              max: 25 * 1024 * 1024
            },
            min: 0,
            max: 1
          }
        },
        privateMessage: MediaRestrictions.common.noMedia
      },
      // 17
      reviewtrackers: {
        post: {
          image: MediaRestrictions.common.noMedia.image,
          video: MediaRestrictions.common.noMedia.video,
          document: MediaRestrictions.common.noMedia.document
        },
        reply: MediaRestrictions.common.noMedia,
        privateMessage: MediaRestrictions.common.noMedia
      },
      // '14'
      twiliowhatsapp: {
        post: {
          image: {
            mimetypes: [
              ImageMimetype.Jpeg,
              ImageMimetype.Pjpeg,
              ImageMimetype.Png
            ],
            min: 0,
            max: 1
          },
          video: {
            mimetypes: [VideoMimetype.Mp4],
            size: {
              max: 5 * 1024 * 1024
            },
            min: 0,
            max: 1
          },
          document: {
            mimetypes: [DocumentMimeType.Pdf],
            size: {
              max: 5 * 1024 * 1024
            }
          }
        },
        reply: {
          // allSupportedMimetypes: [
          //   ImageMimetype.Jpeg,
          //   ImageMimetype.Pjpeg,
          //   ImageMimetype.Png,
          //   VideoMimetype.Mp4,
          //   DocumentMimeType.Pdf
          // ],
          image: {
            mimetypes: [
              ImageMimetype.Jpeg,
              ImageMimetype.Pjpeg,
              ImageMimetype.Png
            ],
            min: 0,
            max: 1
          },
          video: undefined
          // video: {
          //   mimetypes: [VideoMimetype.Mp4],
          //   size: {
          //     max: 5 * 1024 * 1024
          //   },
          //   min: 0,
          //   max: 1
          // },
          // gif: {
          //   mimetypes: [ImageMimetype.Gif],
          //   min: 0,
          //   max: 1,
          //   size: {
          //     max: 5 * 1024 * 1024
          //   }
          // },
          // document: {
          //   mimetypes: [DocumentMimeType.Pdf],
          //   size: {
          //     max: 5 * 1024 * 1024
          //   }
          // }
        },
        privateMessage: MediaRestrictions.common.noMedia
      },
      // '6'
      youtube: {
        post: {
          ...MediaRestrictions.initial,
          video: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 1024 * 1024 * 1024
            },
            title: {
              length: {
                min: 1,
                max: 100
              }
            },
            min: 1,
            max: 1
          },
          image: {
            mimetypes: [],
            min: 0,
            max: 0
          },
          gif: MediaRestrictions.common.noMedia.gif
        },
        reply: MediaRestrictions.common.noMedia,
        privateMessage: MediaRestrictions.common.noMedia
      },
      // '7'
      instagram: {
        // https://developers.facebook.com/docs/instagram-api/reference/ig-user/media/
        post: {
          image: {
            ...MediaRestrictions.initial.image,
            mimetypes: [ImageMimetype.Jpeg],
            min: 0,
            max: 10,
            aspectRatio: {
              min: 4 / 5,
              max: 1.91 / 1
            },
            size: {
              max: 8 * 1024 * 1024
            }
          },
          imageStory: {
            ...MediaRestrictions.initial.image,
            mimetypes: [ImageMimetype.Jpeg],
            min: 0,
            max: 1,
            aspectRatio: {
              min: 0.1 / 1,
              max: 10 / 1 // recommended 9:16 to avoid cropping or blank space
            },
            size: {
              max: 8 * 1024 * 1024
            }
          },
          video: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 100 * 1024 * 1024
            },
            min: 0,
            max: 10,
            aspectRatio: {
              min: 4 / 5,
              max: 1.91 / 1 // even though docs say 16/9, IG actually allows up to 1.91/1
            },
            dimensions: {
              width: {
                min: 0,
                max: 1920
              },
              height: {
                min: 0,
                max: undefined // will be calculated depending on the width and aspect ratio
              }
            },
            duration: {
              min: 3 * 1000,
              max: 60 * 1000
            },
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          },
          videoStory: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 100 * 1024 * 1024
            },
            min: 0,
            max: 1,
            aspectRatio: {
              min: 0.1 / 1,
              max: 10 / 1 // recommended 9:16 to avoid cropping or blank space
            },
            dimensions: {
              width: {
                min: 0,
                max: 1920
              },
              height: {
                min: 0,
                max: undefined // will be calculated depending on the width and aspect ratio
              }
            },
            duration: {
              min: 3 * 1000,
              max: 60 * 1000
            },
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          },
          reel: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 1 * 1024 * 1024 * 1024
            },
            min: 1,
            max: 1,
            aspectRatio: {
              min: 0.01 / 1,
              max: 10 / 1 // recommended 9:16 to avoid cropping or blank space
            },
            dimensions: {
              width: {
                min: 0,
                max: 1920
              },
              height: {
                min: 0,
                max: undefined // will be calculated depending on the width and aspect ratio
              }
            },
            duration: {
              min: 3 * 1000,
              max: 15 * 60 * 1000
            },
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          },
          min: 1,
          gif: MediaRestrictions.common.noMedia.gif
        },
        reply: MediaRestrictions.common.noMedia,
        privateMessage: MediaRestrictions.common.noMedia
      },
      // '12'
      instagrambusiness: {
        // https://developers.facebook.com/docs/instagram-api/reference/ig-user/media/
        post: {
          image: {
            ...MediaRestrictions.initial.image,
            mimetypes: [ImageMimetype.Jpeg],
            min: 0,
            max: 10,
            aspectRatio: {
              min: 4 / 5,
              max: 1.91 / 1
            },
            size: {
              max: 8 * 1024 * 1024
            }
          },
          imageStory: {
            ...MediaRestrictions.initial.image,
            mimetypes: [ImageMimetype.Jpeg],
            min: 0,
            max: 1,
            aspectRatio: {
              min: 0.1 / 1,
              max: 10 / 1 // recommended 9:16 to avoid cropping or blank space
            },
            size: {
              max: 8 * 1024 * 1024
            }
          },
          video: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 100 * 1024 * 1024
            },
            min: 0,
            max: 10,
            aspectRatio: {
              min: 4 / 5,
              max: 1.91 / 1 // even though docs say 16/9, IG actually allows up to 1.91/1
            },
            dimensions: {
              width: {
                min: 0,
                max: 1920
              },
              height: {
                min: 0,
                max: undefined // will be calculated depending on the width and aspect ratio
              }
            },
            duration: {
              min: 3 * 1000,
              max: 60 * 1000
            },
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          },
          videoStory: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 100 * 1024 * 1024
            },
            min: 0,
            max: 1,
            aspectRatio: {
              min: 0.1 / 1,
              max: 10 / 1 // recommended 9:16 to avoid cropping or blank space
            },
            dimensions: {
              width: {
                min: 0,
                max: 1920
              },
              height: {
                min: 0,
                max: undefined // will be calculated depending on the width and aspect ratio
              }
            },
            duration: {
              min: 3 * 1000,
              max: 60 * 1000
            },
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          },
          reel: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 1 * 1024 * 1024 * 1024
            },
            min: 1,
            max: 1,
            aspectRatio: {
              min: 0.01 / 1,
              max: 10 / 1 // recommended 9:16 to avoid cropping or blank space
            },
            dimensions: {
              width: {
                min: 0,
                max: 1920
              },
              height: {
                min: 0,
                max: undefined // will be calculated depending on the width and aspect ratio
              }
            },
            duration: {
              min: 3 * 1000,
              max: 15 * 60 * 1000
            },
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          },
          min: 1,
          gif: MediaRestrictions.common.noMedia.gif
        },
        reply: MediaRestrictions.common.noMedia,
        privateMessage: MediaRestrictions.common.noMedia
      },
      // '11'
      livechat: {
        post: {
          image: {
            mimetypes: [
              ImageMimetype.Jpeg,
              ImageMimetype.Pjpeg,
              ImageMimetype.Png,
              ImageMimetype.Gif
            ],
            min: 0,
            max: 1
          },
          video: MediaRestrictions.common.noMedia.video,
          gif: MediaRestrictions.common.noMedia.gif
        },
        reply: MediaRestrictions.common.noMedia,
        privateMessage: MediaRestrictions.common.noMedia
      },
      // '13'
      twiliosms: {
        post: {
          image: MediaRestrictions.common.noMedia.image,
          video: MediaRestrictions.common.noMedia.video,
          document: MediaRestrictions.common.noMedia.document
        },
        reply: {
          image: MediaRestrictions.common.noMedia.image,
          video: MediaRestrictions.common.noMedia.video,
          document: MediaRestrictions.common.noMedia.document
        },
        privateMessage: MediaRestrictions.common.noMedia
      },
      // '16'
      tiktok: {
        post: {
          image: MediaRestrictions.common.noMedia.image,
          video: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.WebM],
            size: {
              max: 500 * 1024 * 1024
            },
            min: 1,
            max: 1,
            aspectRatio: {
              min: 9 / 16,
              max: 9 / 16
            },
            dimensions: {
              width: {
                min: 540,
                max: 1080
              },
              height: {
                min: 0,
                max: 1920 // will be calculated depending on the width and aspect ratio
              }
            },
            duration: {
              min: 3 * 1000,
              max: 3 * 60 * 1000
            },
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          },
          min: 1,
          gif: MediaRestrictions.common.noMedia.gif
        },
        reply: {
          image: MediaRestrictions.common.noMedia.image,
          video: MediaRestrictions.common.noMedia.video,
          document: MediaRestrictions.common.noMedia.document
        },
        privateMessage: MediaRestrictions.common.noMedia
      },
      // 18
      nextdooragency: {
        post: {
          image: {
            ...MediaRestrictions.initial.image,
            mimetypes: [
              ImageMimetype.Jpeg,
              ImageMimetype.Png,
              ImageMimetype.Gif
            ],
            min: 0,
            max: 10,
            size: {
              max: 10 * 1024 * 1024
            },
            dimensions: {
              width: {
                min: 0,
                max: 4032 // seems like there is no limit on FB API, setting 4032 as it's some optimal default resolution of smartphones (12MPX cameras)
              },
              height: {
                min: 0,
                max: 4032
              }
            }
          },
          video: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 500 * 1024 * 1024
            },
            min: 0,
            max: 1,
            duration: {
              min: 0,
              max: 1200 * 1000 // 20min
            }
          },
          min: 0,
          gif: MediaRestrictions.common.noMedia.gif
        },
        reply: MediaRestrictions.common.noMedia,
        privateMessage: MediaRestrictions.common.noMedia
      },
      // 19
      tiktokbusiness: {
        post: {
          image: MediaRestrictions.common.noMedia.image,
          video: {
            ...MediaRestrictions.initial.video,
            mimetypes: [
              VideoMimetype.Mp4,
              VideoMimetype.Mov,
              VideoMimetype.WebM
            ],
            size: {
              max: 500 * 1024 * 1024
            },
            min: 1,
            max: 1,
            dimensions: {
              width: {
                min: 540,
                max: 1920
              },
              height: {
                min: 0,
                max: 1920 // will be calculated depending on the width and aspect ratio
              }
            },
            duration: {
              min: 3 * 1000,
              max: 3 * 60 * 1000
            },
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          },
          min: 1,
          gif: MediaRestrictions.common.noMedia.gif
        },
        reply: {
          image: MediaRestrictions.common.noMedia.image,
          video: MediaRestrictions.common.noMedia.video,
          document: MediaRestrictions.common.noMedia.document
        },
        privateMessage: MediaRestrictions.common.noMedia
      },
      // 20
      nextdooragencyus: {
        post: {
          image: {
            ...MediaRestrictions.initial.image,
            mimetypes: [
              ImageMimetype.Jpeg,
              ImageMimetype.Png,
              ImageMimetype.Gif
            ],
            min: 0,
            max: 10,
            size: {
              max: 10 * 1024 * 1024
            },
            dimensions: {
              width: {
                min: 0,
                max: 4032 // seems like there is no limit on FB API, setting 4032 as it's some optimal default resolution of smartphones (12MPX cameras)
              },
              height: {
                min: 0,
                max: 4032
              }
            }
          },
          video: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 500 * 1024 * 1024
            },
            min: 0,
            max: 1,
            duration: {
              min: 0,
              max: 1200 * 1000 // 20min
            }
          },
          min: 0,
          gif: MediaRestrictions.common.noMedia.gif
        },
        reply: MediaRestrictions.common.noMedia,
        privateMessage: MediaRestrictions.common.noMedia
      },
      // 21
      threads: {
        // https://developers.facebook.com/docs/instagram-api/reference/ig-user/media/
        post: {
          image: {
            ...MediaRestrictions.initial.image,
            mimetypes: [ImageMimetype.Jpeg],
            min: 0,
            max: 10,
            aspectRatio: {
              min: 4 / 5,
              max: 1.91 / 1
            },
            size: {
              max: 8 * 1024 * 1024
            }
          },
          imageStory: {
            ...MediaRestrictions.initial.image,
            mimetypes: [ImageMimetype.Jpeg],
            min: 0,
            max: 1,
            aspectRatio: {
              min: 0.1 / 1,
              max: 10 / 1 // recommended 9:16 to avoid cropping or blank space
            },
            size: {
              max: 8 * 1024 * 1024
            }
          },
          video: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 100 * 1024 * 1024
            },
            min: 0,
            max: 10,
            aspectRatio: {
              min: 4 / 5,
              max: 1.91 / 1 // even though docs say 16/9, IG actually allows up to 1.91/1
            },
            dimensions: {
              width: {
                min: 0,
                max: 1920
              },
              height: {
                min: 0,
                max: undefined // will be calculated depending on the width and aspect ratio
              }
            },
            duration: {
              min: 3 * 1000,
              max: 60 * 1000
            },
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          },
          videoStory: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 100 * 1024 * 1024
            },
            min: 0,
            max: 1,
            aspectRatio: {
              min: 0.1 / 1,
              max: 10 / 1 // recommended 9:16 to avoid cropping or blank space
            },
            dimensions: {
              width: {
                min: 0,
                max: 1920
              },
              height: {
                min: 0,
                max: undefined // will be calculated depending on the width and aspect ratio
              }
            },
            duration: {
              min: 3 * 1000,
              max: 60 * 1000
            },
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          },
          reel: {
            ...MediaRestrictions.initial.video,
            mimetypes: [VideoMimetype.Mp4, VideoMimetype.Mov],
            size: {
              max: 1 * 1024 * 1024 * 1024
            },
            min: 0,
            max: 1,
            aspectRatio: {
              min: 0.01 / 1,
              max: 10 / 1 // recommended 9:16 to avoid cropping or blank space
            },
            dimensions: {
              width: {
                min: 0,
                max: 1920
              },
              height: {
                min: 0,
                max: undefined // will be calculated depending on the width and aspect ratio
              }
            },
            duration: {
              min: 3 * 1000,
              max: 15 * 60 * 1000
            },
            title: {
              length: {
                min: 0,
                max: 0
              }
            }
          },
          min: 0,
          gif: MediaRestrictions.common.noMedia.gif
        },
        reply: {
          image: {
            mimetypes: [
              ImageMimetype.Jpeg,
              ImageMimetype.Pjpeg,
              ImageMimetype.Png,
              ImageMimetype.Gif
            ],
            min: 0,
            max: 1,
            size: {
              max: 10 * 1024 * 1024
            },
            dimensions: {
              width: {
                min: 0,
                max: 4032
              },
              height: {
                min: 0,
                max: 4032
              }
            }
          },
          video: MediaRestrictions.common.noMedia.video,
          document: MediaRestrictions.common.noMedia.document
        },
        privateMessage: MediaRestrictions.common.noMedia
      }
    };
  }
}
