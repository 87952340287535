<div class="multi-select-chips">
  <button
    (click)="showOptionsDropdown = true"
    class="multi-select-chips-trigger"
    [class.multi-line-chips]="selectedChips && selectedChips.length > 2"
  >
    <ng-container *ngIf="!selectedChips?.length">
      <span class="multi-select-chips-trigger-text">{{chipsPlaceholder}}</span>
      <span class="multi-select-chips-trigger-icon ssi ssi-arrow-down-new"></span>
    </ng-container>
    <ng-container *ngIf="selectedChips?.length">
      <span
        class="multi-select-chips-trigger-chip"
        *ngFor="let chip of selectedChips"
      >
        <span
          *ngIf="chip.color"
          class="multi-select-chips-trigger-chip-icon"
          [ngStyle]="{'background': chip.color }"
        >
          <i [ngClass]="chip.icon"></i>
        </span>
        <span class="multi-select-chips-trigger-chip-label">{{ chip.label }}</span>
        <button (click)="$event.stopPropagation(); toggleSelectedChip(chip)">
          <i class="ssi ssi-delete-close"></i>
        </button>
      </span>
      <span class="multi-select-chips-trigger-icon ssi ssi-arrow-down-new"></span>
    </ng-container>
  </button>
  <div
    *ngIf="showOptionsDropdown"
    class="multi-select-chips-dropdown"
    (ssiOffClick)="showOptionsDropdown = false"
  >
    <div class="chips-filter-input">
      <input
        class="multi-select-chips-dropdown-input"
        type="text"
        [placeholder]="filterPlaceholder"
        [(ngModel)]="filterTerm"
        (ngModelChange)="onFilterTermChange()"
      >
      <i class="ssi ssi-magnifying-glass"></i>
    </div>
    <div class="multi-select-chips-dropdown-actions">
      <button
        (click)="toggleSelectAllChips()"
        class="multi-select-chips-dropdown-action"
      >
        <span
          *ngIf="!this.allSelected"
          translate="SELECT_ALL"
        ></span>
        <span
          *ngIf="this.allSelected"
          translate="DESELECT_ALL"
        ></span>
      </button>
    </div>
    <ul class="multi-select-chips-dropdown-list">
      <li
        class="multi-select-chips-dropdown-list-item"
        *ngFor="let chip of chipsDropdownOptions"
      >
        <button
          class="multi-select-chips-dropdown-list-action"
          [ngClass]="{'multi-select-chips-dropdown-list-action-checked': selectedChips?.includes(chip)}"
          (click)="toggleSelectedChip(chip)"
        >
          <span class="multi-select-chips-dropdown-list-action-label">{{chip.label ? chip.label : chip}}</span>
          <span class="multi-select-chips-dropdown-list-action-state">
            <i
              *ngIf="selectedChips?.includes(chip)"
              class="ssi ssi-tick-small"
            ></i>
          </span>
        </button>
      </li>
    </ul>
  </div>
</div>