import './widget-chart-line.component.scss';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import Highcharts, { Options } from 'highcharts';

import { InsightsService, LineChart } from '../../../insights.service';
import { Filter } from '../../../reports/view/view-report.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DrilldownModalComponent } from '../drilldown-modal/drilldown-modal.component';
import { findSentimentConst } from '../../../../../../common/constants';
import {
  FieldName,
  filtersFieldDefinitions,
  formatFilterTypeData
} from '../../constants/filters-field-definitions';
import { HighchartComponent } from '../../../../../../common/components/highchart/highchart.component';
import moment from 'moment';
import { widgetTypes } from '../../constants';
import { forIn } from 'lodash-es';

@Component({
  selector: 'ssi-widget-chart-line',
  templateUrl: './widget-chart-line.component.html',
  styles: []
})
export class WidgetChartLineComponent implements OnInit, OnChanges {
  @Input() widget: LineChart;
  @Input() filters: Filter[];
  @Input() globalFilters: Filter[];
  @Input() streamIds: string[];
  @Input() initRender: boolean = false;
  @Input() id: string;
  @Input() templateMode = false;

  @Output() loaded = new EventEmitter<void>();
  @Output() onApplyFilters = new EventEmitter();
  @Output() saveWidget = new EventEmitter<{ widget: LineChart; id: string }>();

  @ViewChild(HighchartComponent) highchart: HighchartComponent;

  lineConfig: Options = {};
  updateFlag: boolean = false;
  data;
  relabelledData;
  categories: string[];
  noResults: boolean = false;
  instance;

  constructor(
    private insightsService: InsightsService,
    private modal: NgbModal
  ) {}

  ngOnInit() {
    if (this.initRender) {
      this.requestData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.filters &&
        changes.filters.currentValue &&
        !changes.filters.firstChange) ||
      (changes.globalFilters &&
        changes.globalFilters.currentValue &&
        !changes.globalFilters.firstChange) ||
      (changes.globalFilters &&
        !changes.globalFilters.previousValue &&
        changes.globalFilters.currentValue.length !== 0 &&
        changes.globalFilters.firstChange)
    ) {
      this.requestData();
    }
  }

  async requestData() {
    await this.insightsService
      .aggregateWidgetData(this.widget, this.globalFilters, this.streamIds)
      .then(({ data }) => {
        if (data.length === 0) {
          this.loaded.emit();
          this.noResults = true;
          return;
        }
        if (this.widget.type === widgetTypes.trustometer_over_time.key) {
          // trustometer_over_time is only a line chart with a different key
          // and always spread across the time ('Created At')
          this.widget = {
            ...this.widget,
            x_axis: {
              field: FieldName.CreatedAt
            }
          };
        }
        // console.log('data: ', data);
        this.relabelledData = this.insightsService.relabelDataModelForUi(
          this.widget,
          data
        );

        if (
          this.widget.x_axis &&
          this.widget.x_axis.field === FieldName.CreatedAt
        ) {
          this.categories = Object.keys(this.relabelledData).map((key) => {
            const momDate = moment(key);
            const dateFormat = momDate.hour() ? 'DD/MM/YYYYTHH' : 'DD/MM/YYYY';
            return momDate.format(dateFormat);
          });
        } else {
          this.categories = Object.keys(this.relabelledData);
        }

        this.data = [{ name: this.widget.name, data: [] }];

        if (
          this.widget.x_axis &&
          this.widget.x_axis.field === FieldName.Sentiment
        ) {
          this.categories.sort((a, b) => {
            const aConst = findSentimentConst('key3', a);
            const bConst = findSentimentConst('key3', b);
            return bConst.numericKey - aConst.numericKey;
          });
          this.data[0].data = this.categories.map(
            (c) => this.relabelledData[c]
          );
        } else {
          this.data[0].data = Object.values(this.relabelledData);
        }

        if (this.data[0].data.length === 0 && this.categories.length > 0) {
          for (const category of this.categories) {
            this.data[0].data.push(0);
          }
        }

        this.lineConfig = this._getChartConfig(this.data, this.categories);
        this.highchart.rerender();
        this.loaded.emit();
      });
  }

  chartInstance(instance) {
    this.instance = instance;
    if (
      this.widget.display_properties.annotations &&
      this.widget.display_properties.annotations.length > 0
    ) {
      for (const annotation of this.widget.display_properties.annotations) {
        const localeDateString = new Date(
          annotation.datetime
        ).toLocaleDateString('en-GB');
        const pointData = instance.series[0].data.find(
          ({ category }) => category === localeDateString
        );
        if (pointData) {
          const label = pointData.getLabelConfig();
          instance.addAnnotation({
            labels: [
              {
                point: { x: label.point.plotX, y: label.point.plotY },
                text: annotation.title,
                y: -50,
                x: 50
              }
            ]
          });
        }
        this.updateFlag = true;
      }
    }
  }

  private _getChartConfig(data, categories): Options {
    const colours = [
      '#425DEC',
      '#D41D68',
      '#F0B427',
      '#89A4EA',
      '#00bde9',
      '#98efff',
      '#d4e0fa',
      '#ffe6e0',
      '#ff865e'
    ];

    Highcharts.setOptions({
      colors: colours
    });
    return {
      annotations: [
        {
          labelOptions: {
            y: -50,
            x: 50
          }
        }
      ],
      chart: {
        type: 'spline',
        spacing: [50, 0, 0, 0],
        marginRight: 10
      },
      title: {
        text: null
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories,
        gridLineWidth: 0,
        title: {
          text: null,
          style: {
            color: '#101525',
            fontSize: '12px',
            fontWeight: '900'
          },
          margin: -10
        },
        labels: {
          style: {
            color: '#101525',
            fontSize: '10px',
            fontWeight: '700'
          },
          y: 50,
          x: -20,
          align: 'center'
        },
        lineWidth: 0,
        endOnTick: true,
        startOnTick: true
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: null,
          style: {
            color: '#101525',
            fontSize: '12px',
            fontWeight: '900'
          },
          offset: 55
        },
        tickAmount: 8,
        labels: {
          style: {
            color: '#101525',
            fontSize: '10px',
            fontWeight: '700'
          },
          x: -18
        },
        stackLabels: {
          enabled: false
        },
        alternateGridColor: '#F8F9FD'
      },
      legend: {
        align: 'left',
        useHTML: true,
        x: 18,
        verticalAlign: 'top',
        y: -55,
        symbolHeight: 4,
        symbolWidth: 20,
        squareSymbol: false,
        symbolRadius: 2,
        floating: true,
        symbolPadding: 10,
        itemDistance: 30,
        itemStyle: {
          fontSize: '10px',
          fontWeight: '900',
          color: '#101525',
          fontFamily: 'Lato, Arial, sans-serif',
          textRendering: 'geometricPercision',
          lineHeight: '16px'
        }
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormatter() {
          return 'Total: ' + +this.y.toFixed(2);
        }
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false
          },
          dataLabels: {
            format: '{point.name}',
            enabled: true,
            style: {
              color: '#101525',
              fontSize: '14px',
              fontWeight: '700'
            }
          },
          events: {
            click: (event) => {
              console.log('datapoint click:', event);
              console.log('datapoint this.widget:', this.widget);
              const modal = this.modal.open(DrilldownModalComponent, {
                windowClass: 'xxl-modal'
              });
              const filterType =
                filtersFieldDefinitions[this.widget.x_axis.field]
                  .preferedFilterType;

              modal.componentInstance.streamIds = this.streamIds;
              modal.componentInstance.displayApplyFilters = true;
              modal.componentInstance.globalFilters = this.globalFilters;
              modal.componentInstance.widgetFilters = this.widget.filters;
              modal.componentInstance.point = event.point.category;
              modal.componentInstance.annotations = this.widget
                .display_properties.annotations
                ? this.widget.display_properties.annotations
                : [];
              modal.componentInstance.templateMode = this.templateMode;

              const rangeFilterDates = {
                pointClicked: event.point.category,
                allPoints: categories
              };
              modal.componentInstance.selectedFilters = [
                {
                  field: this.widget.x_axis.field,
                  [filterType]: formatFilterTypeData(
                    this.widget.x_axis.field === FieldName.CreatedAt
                      ? rangeFilterDates
                      : event.point.category,
                    filterType
                  )
                }
              ];
              modal.componentInstance.onApplyFilters.subscribe((filters) =>
                this.onApplyFilters.emit(filters)
              );
              modal.componentInstance.updateAnnotations.subscribe(
                (annotations) => {
                  this.widget.display_properties.annotations = annotations;
                  this.saveWidget.emit({ widget: this.widget, id: this.id });
                  this.highchart.rerender();
                }
              );
            }
          }
        },
        series: {
          pointPlacement: 'on'
        }
      },
      series: data
    };
  }
}
