<div class="edit-file-modal">
  <div class="edit-file-modal-header">
    <div class="edit-file-modal-header-left">
      <h2 translate="EDIT_FILE"></h2>
    </div>
    <div class="edit-file-modal-header-right">
      <button
        (click)="activeModal.close(false)"
        class="edit-file-modal-close"
      >
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="edit-file-modal-body">
    <p>Suggested post text and Media Guidance is an optional tool to help guide your colleagues where
      required. You can add one without the other too!
    </p>

    <form #editFileForm="ngForm">
      <div class="edit-file-modal-body-row">
        <label
          class="edit-file-modal-label"
          for="fileName"
          translate="FILENAME"
        ></label>
        <div
          class="edit-file-modal-body-row-input"
          [ngClass]="{'has-error': editFileForm.controls.filename?.invalid}"
        >
          <input
            class="edit-file-modal-input"
            id="fileName"
            name="fileName"
            type="text"
            [maxlength]="maxFilenameLength"
            [(ngModel)]="resolve.file.name"
            (ngModelChange)="onFileNameChange($event)"
          >
          <span
            [ngClass]="{'edit-file-modal-body-row-input-meta-active': resolve.file.name?.length}"
            class="edit-file-modal-body-row-input-meta"
          >
            {{maxFilenameLength - resolve.file.name?.length}}
          </span>
        </div>
      </div>

      <div class="edit-file-modal-body-row">
        <label
          class="edit-file-modal-label"
          for="text"
        >
          <span translate="SUGGESTED_POST_TEXT"></span>
          <i
            class="fa fa-info-circle"
            [ssiTooltip]="'WHEN_SELECTING_THIS_FILE_IN_THE_PUBLISHER_PREPOPULATE_THE_POST_TEXT_WITH_THIS_MESSAGE' | translate"
            tooltipWindowClass="tooltip-chunky"
            [tooltipAppendToBody]="true"
          >
          </i>
        </label>
        <textarea
          class="edit-file-modal-input"
          name="text"
          rows="4"
          placeholder="Type the post copy you require with this media..."
          [(ngModel)]="resolve.file.metadata.outbox_post.text"
        ></textarea>
      </div>

      <div
        *ngIf="[
          ImageMimetype.Jpeg,
          ImageMimetype.Pjpeg,
          ImageMimetype.Png,
          ImageMimetype.Gif
        ].includes(resolve.file.type)"
        class="edit-file-modal-body-row"
      >
        <label
          class="edit-file-modal-label"
          for="altText"
        >
          <span translate="ALT_TEXT"></span>
        </label>
        <textarea
          class="edit-file-modal-input"
          name="altText"
          rows="4"
          placeholder="Add description of your image here..."
          [(ngModel)]="resolve.file.metadata.outbox_post.alt_text"
        ></textarea>
      </div>

      <div class="edit-file-modal-body-row picker-container">
        <label class="edit-file-modal-label">
          <span translate="EXPIRE_ASSET_ON"></span>
          <i
            class="fa fa-info-circle"
            [ssiTooltip]="'OPTIONAL_DATE_AFTER_WHICH_ORLO_WILL_BLOCK_THIS_ASSET_FROM_BEING_USED' | translate"
            tooltipWindowClass="tooltip-chunky"
            [tooltipAppendToBody]="true"
          >
          </i>
        </label>
        <ssi-date-time-picker
          [dateLabel]="'EXPIRE_DATE' | translate"
          [timeLabel]="'EXPIRE_TIME' | translate"
          [pastDateTooltipTitle]="'UNAVAILABLE_DAY_TO_SET_THE_EXPIRY' | translate"
          [pastDateTooltipBody]="'CONTENT_CANT_BE_SET_TO_EXPIRY_IN_THE_PAST' | translate"
          [placeholder]="'NOT_SET' | translate"
          [calHeaderTitle]="'Select Asset Expiry date'"
          name="expiryDate"
          [(ngModel)]="resolve.file.metadata.expiry_date"
          [selectedDate]="resolve.file.metadata.expiry_date"
        >
        </ssi-date-time-picker>

        <orlo-button
          *ngIf="resolve.file.metadata?.expiry_date"
          class="edit-file-modal-button"
          [text]="'REMOVE_EXPIRY' | translate"
          [leadingIcon]="'ssi ssi-small-delete'"
          textColor="#43537f"
          bgColor="#fff1ed"
          (click)="removeAssetExpiration()"
        ></orlo-button>
      </div>
    </form>

    <div class="edit-file-modal-body-row">
      <div class="textarea-container">
        <label class="edit-file-modal-label">
          <span>Media Guidance</span>
          <i
            class="fa fa-info-circle"
            [ssiTooltip]="'POP_A_NOTE_HERE_IF_YOU_NEED_TO_LET_YOUR_COLLEAGUES_KNOW_WHAT_THEY_SHOULD_BE_USING_THIS_MEDIA_FOR' | translate"
            tooltipWindowClass="tooltip-chunky"
            [tooltipAppendToBody]="true"
          >
          </i>
        </label>
        <textarea
          class="edit-file-modal-input"
          name="note"
          maxlength="200"
          rows="4"
          placeholder="You can also just send a note with this file here..."
          [(ngModel)]="resolve.file.metadata.outbox_post.note"
        >
      </textarea>
        <span
          class="char-count"
          [ngClass]="{'invalid': resolve.file.metadata.outbox_post.note?.length === 200}"
        >{{200 - resolve.file.metadata.outbox_post.note?.length}}
        </span>
      </div>
    </div>
  </div>

  <div class="edit-file-modal-footer">
    <div class="edit-file-modal-footer-left">
    </div>
    <div class="edit-file-modal-footer-right">
      <orlo-button
        class="edit-file-modal-button edit-file-modal-button-secondary"
        [text]="'CANCEL' | translate"
        textColor="#43537F"
        bgColor="#fff"
        (click)="activeModal.dismiss()"
      ></orlo-button>
      <orlo-button
        class="edit-file-modal-button"
        [text]="'SAVE' | translate"
        textColor="#fff"
        bgColor="#14BAE3"
        [disabled]="editFileForm?.invalid"
        (click)="activeModal.close(resolve.file)"
      ></orlo-button>
    </div>
  </div>
</div>