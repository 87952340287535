import './inbox.component.scss';

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import moment from 'moment';
import PromiseQueue from 'promise-queue';
import {
  Activity,
  ActivityModel,
  ActivityTags,
  API,
  Campaign,
  socialNetworkSettings,
  User,
  Account,
  ConversationModel,
  Conversation,
  ConversationStatus,
  AccountModel
} from '@ui-resources-angular';
import { StateService, TransitionService } from '@uirouter/angular';
import { WorkflowManagerService } from '../../../../angular/common/services/workflow-manager/workflow-manager.service';
import {
  InboxActivityQuery,
  InboxQueryFactoryService
} from '../../../../angular/common/services/inbox-query-factory/inbox-query-factory.service';
import { RealtimeInboxHelperService } from '../../../../angular/common/services/realtime-inbox-helper/realtime-inbox-helper.service';
import {
  UserPreferences,
  UserPreferencesService
} from '../../../../angular/common/services/user-preferences/user-preferences.service';
import { PopupService } from '../../../../angular/common/services/popup/popup.service';
import { SENTIMENT_CONFIG } from '../../../../angular/common/constants';
import {
  InboxQueryFilter,
  InboxQueryResultListItem,
  InboxQueryResultListItemType
} from '../../../../angular/common/services/inbox-query-factory/queries/common';
import { QueryPreset } from '../../../../angular/common/services/abstract-query-helper/abstract-query-helper.service';
import {
  KeyValueObject,
  trackByProperty
} from '../../../../angular/common/util';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { interval } from 'rxjs/observable/interval';
import { merge } from 'rxjs/observable/merge';
import { of } from 'rxjs/observable/of';
import {
  TeamsService,
  Team,
  Colleague
} from '../../../../angular/common/services/api';
import { InboxConversationQuery } from '../../../../angular/common/services/inbox-query-factory/queries/conversation';
import { InboxQueryType } from '../../../../angular/common/services/inbox-query-factory/queries/common';
import { LiveChatService } from '../../../../angular/common/services/live-chat/live-chat.service';
import { PluginService } from '../../../../angular/common/services/plugin/plugin.service';
import {
  AgentStatus,
  AgentStatusID,
  AgentStatuses,
  ConversationPushModeService
} from '../../../common/services/conversation-push-mode/conversation-push-mode.service';
import {
  CompanyConfig,
  CompanyService
} from '../../../../angular/common/services/company/company.service';
import { SocketsService } from '../../../../angular/common/services/sockets/sockets.service';
import { SocketEventManagerService } from '../../../../angular/common/services/sockets/socket-event-manager.service';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmLeaveModalComponent } from '../../../../angular/common/components/confirm-leave-modal/confirm-leave-modal.component';
import { VideoModalComponent } from '../../../../angular/common/components/video-modal/video-modal.component';
import { BulkProgressModalComponent } from '../../../../angular/common/components/bulk-progress-modal/bulk-progress-modal.component';
import {
  Filter,
  FilterKey,
  Filters,
  FiltersComponent
} from '../../../../angular/common/components/filters/filters.component';
import { InboxDashboardComponent } from './inbox-dashboard/inbox-dashboard.component';
import {
  authUser,
  campaigns,
  colleagues,
  companyConfig,
  teams,
  workflowAccounts
} from '../../common-resolves';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { RealtimeConversationHelperService } from '../../../../angular/common/services/realtime-conversation-helper/realtime-conversation-helper.service';

export const ACTIVITY_CREATED_EVENT = 'activity.created';

export const MAX_PARALLEL_BULK_REQUESTS = 5;

const STATS_UPDATE_INTERVAL = 60 * 60 * 1000;

enum InboxResultOverlayType {
  ActiveFilters = 'activeFilters',
  Filters = 'filters',
  Presets = 'presets',
  UsefulThings = 'usefulThings'
}

enum InboxBulkActionType {
  Assign = 'assign',
  Sentiment = 'sentiment',
  Tag = 'tag'
}

interface InboxQuery<QueryType> {
  query: QueryType;
  newResults: {
    unfiltered: number;
    filtered: number;
    total: number;
  };
  savePresets(presets: QueryPreset[]): Promise<any>;
}

export enum InboxMode {
  Activity = 'activity',
  Conversation = 'conversation'
}

const INBOX_MODE_LS_KEY = 'inbox-mode';

export function socketLoadedResolveFn(socket: SocketsService) {
  return socket.getSocketInstance().connected;
}

export async function userPreferencesLoadedResolveFn(
  userPreferences: UserPreferencesService
) {
  return await userPreferences.getPreferences();
}

export async function tagsResolveFn(activityTags: ActivityTags) {
  return await activityTags.getTags();
}

export async function userCanCreateNewActivityTagsResolveFn(
  activityTags: ActivityTags
) {
  return await activityTags.getCanCreateNewTags();
}

export async function pushEnabledResolveFn(
  conversationPushModeService: ConversationPushModeService
) {
  const socialPushSettings = await conversationPushModeService.getCompanySettings();
  return socialPushSettings && socialPushSettings.enabled;
}

export const socketLoaded = {
  token: 'socketLoaded',
  resolveFn: socketLoadedResolveFn,
  deps: [SocketsService]
};

export const userPreferencesLoaded = {
  token: 'userPreferencesLoaded',
  resolveFn: userPreferencesLoadedResolveFn,
  deps: [UserPreferencesService]
};

export const tags = {
  token: 'tags',
  resolveFn: tagsResolveFn,
  deps: [ActivityTags]
};

export const userCanCreateNewActivityTags = {
  token: 'userCanCreateNewActivityTags',
  resolveFn: userCanCreateNewActivityTagsResolveFn,
  deps: [ActivityTags]
};

export const pushEnabled = {
  token: 'pushEnabled',
  resolveFn: pushEnabledResolveFn,
  deps: [ConversationPushModeService]
};

@Component({
  selector: 'ssi-inbox',
  templateUrl: './inbox.component.html'
})
export class InboxComponent implements OnInit, OnDestroy {
  static resolve = [
    workflowAccounts,
    colleagues,
    authUser,
    teams,
    campaigns,
    companyConfig,
    socketLoaded,
    tags,
    userCanCreateNewActivityTags,
    pushEnabled,
    userPreferencesLoaded
  ];

  @Input() workflowAccounts: Account[];
  @Input() colleagues: Colleague[];
  @Input() authUser: User;
  @Input() teams: Team[];
  @Input() campaigns: Campaign[];
  @Input() companyConfig: CompanyConfig;
  @Input() socketLoaded: boolean;
  @Input() tags: string[];
  @Input() userCanCreateNewActivityTags: boolean;
  @Input() pushEnabled: boolean;
  @Input() userPreferencesLoaded: UserPreferences[];

  @ViewChild('filtersRef') filtersComponent: FiltersComponent;

  isSocialPushMode: boolean;

  trackById = trackByProperty('id');

  protected _isLiveChatActive: boolean = false;
  realtimeHelper;
  realtimeConversationHelper;
  currentDate = new Date();
  selectedResults: InboxQueryResultListItem[] = [];
  tutorialVideos = [];
  userEngagementStatistics;
  resultsOverlay: InboxResultOverlayType | null = null;
  editing;
  bulkAction: {
    type: InboxBulkActionType;
    listSearch: string;
    selectedValue?: any;
  } | null = {
    type: undefined,
    listSearch: null,
    selectedValue: undefined
  };
  tagsPattern = new RegExp('^[\\da-zA-ZÀ-úÄ-Ÿä-ÿ_&/>-]+$');

  pinnedActivities: Activity[];

  sentimentConfigIterable = Object.values(SENTIMENT_CONFIG);

  socialNetworks = socialNetworkSettings;

  InboxResultOverlayType = InboxResultOverlayType;

  InboxMode = InboxMode;

  InboxQueryType = InboxQueryType;

  queries: {
    activity: InboxQuery<InboxActivityQuery>;
    conversation: InboxQuery<InboxConversationQuery>;
    active?: InboxQuery<InboxActivityQuery | InboxConversationQuery>;
    activeConversation?: InboxQuery<
      InboxActivityQuery | InboxConversationQuery
    >;
    onHoldConversation?: InboxQuery<
      InboxActivityQuery | InboxConversationQuery
    >;
  };

  agentOfflineForSocial;

  mode: InboxMode = InboxMode.Conversation;

  isReply: boolean;

  hasNewlyResolvedConversation = false;
  sidebarCollapsed = true;
  searchResultsAreHidden = false;

  hasConversationPushModeEnabled = false;

  public isConversationThreadActive = false;

  crmPinned = false;

  confirmedLeave = false;
  socialPushModeDisabled = false;
  pollingForConversationsToPush = false;
  showActive = true;
  showOnHold = true;

  $socialPushModeChecked: Subscription;
  socialPushModeStats: any; // SocialPushModeCheckedResponse;

  activeFilters: Filter[] = [];
  presetName = '';

  newIncomingReply: InboxQueryResultListItem;
  spamDetectionEnabled = false;

  onDestroy = new Subject();

  constructor(
    private location: Location,
    private $state: StateService,
    private $transitions: TransitionService,
    private $translate: TranslateService,
    private activityModel: ActivityModel,
    private inboxQueryFactory: InboxQueryFactoryService,
    private workflowManager: WorkflowManagerService,
    private localStorageService: LocalStorageService,
    private realtimeInboxHelperService: RealtimeInboxHelperService,
    private userPreferences: UserPreferencesService,
    private api: API,
    private activityTags: ActivityTags,
    private popup: PopupService,
    private conversationModel: ConversationModel,
    private plugin: PluginService,
    private liveChatService: LiveChatService,
    private socketEventManagerService: SocketEventManagerService,
    private socket: SocketsService,
    private conversationPushModeService: ConversationPushModeService,
    private company: CompanyService,
    private modal: NgbModal,
    private realtimeConversationHelperService: RealtimeConversationHelperService
  ) {}

  public get isLiveChatActive() {
    return this.isLiveChatEnabled && !!this._isLiveChatActive;
  }

  public set isLiveChatActive(value: boolean) {
    this._isLiveChatActive = value;
  }

  public get isSidebarCollapsed() {
    return this.sidebarCollapsed;
  }

  public get areSearchResultsHidden() {
    return this.searchResultsAreHidden;
  }

  public get isLiveChatEnabled() {
    return !!this.liveChatService.isEnabled;
  }

  // searchNewConversations() {
  //   console.log('Conversation search');
  //   this.queries.active.query.search();
  // }

  async ngOnInit() {
    this.spamDetectionEnabled = await this.company.hasFeatureAccess(
      'SPAM_DETECTION'
    );
    this.isSocialPushMode = this.$state.params.push;
    this.queries = {
      activity: this.createQuery<InboxActivityQuery>(
        InboxQueryType.Activity,
        'inbox_custom_preset_filters',
        this.$state.params.query
      ),
      conversation: this.createQuery<InboxConversationQuery>(
        InboxQueryType.Conversation,
        'inbox_custom_preset_conversation_filters',
        this.$state.params.query
      )
    };

    const isAssignedToMe = (partialConversation) => {
      return (
        partialConversation.assigned_to_user &&
        partialConversation.assigned_to_user.toString() === this.authUser.id
      );
    };

    // social push mode related
    if (this.isSocialPushMode) {
      if (!this.pushEnabled) {
        this.$state.go('auth.inbox', undefined, {
          reload: true,
          inherit: false
        });
        return;
      }

      this.socketEventManagerService.newConversation
        .pipe(debounceTime(2000))
        .subscribe((partialConversation: Partial<Conversation>) => {
          const currentConversations = this.queries.active.query.result
            .conversations;
          const inList =
            currentConversations &&
            currentConversations.some(
              (conversation) => conversation.id === partialConversation.id
            );

          if (
            (isAssignedToMe(partialConversation) && !inList) ||
            (!isAssignedToMe(partialConversation) && inList) ||
            (isAssignedToMe(partialConversation) &&
              inList &&
              partialConversation.status === ConversationStatus.Resolved)
          ) {
            // this.searchNewConversations();
            console.log('Should search conversations');
            if (this.isConversationThreadActive) {
              this.queries.activity.query.search();
            } else {
              this.createPushModeConversationQueries();
            }
          } else {
            console.log('Do nothing');
          }
        });

      this.pollingForConversationsToPush = true;

      this.$socialPushModeChecked = this.socketEventManagerService.socialPushModeChecked
        .pipe(takeUntil(this.onDestroy))
        .subscribe((pushModeStats) => {
          console.log('KV SEARCH COMPLETE', pushModeStats);
          this.socialPushModeStats = pushModeStats;
          this.pollingForConversationsToPush = false;
          this.$socialPushModeChecked.unsubscribe();
        });

      // const isAssignedToMe = (partialConversation) => {
      //   return (
      //     partialConversation.assigned_to_user &&
      //     partialConversation.assigned_to_user.toString() === this.authUser.id
      //   );
      // };

      this.socketEventManagerService.socialPushModeDisabled.subscribe(
        () => (this.socialPushModeDisabled = true)
      );
    }
    // end of social push mode code

    this.realtimeHelper = this.realtimeInboxHelperService.create({
      socket: this.socket.getSocketInstance(),
      authUser: this.authUser
    });

    this.realtimeConversationHelper = this.realtimeConversationHelperService.create(
      {
        socket: this.socket.getSocketInstance(),
        authUser: this.authUser
      }
    );

    this.conversationPushModeService.pushModeStatusChange.subscribe(
      (status: AgentStatusID) => {
        if (status === AgentStatusID.Offline) {
          this.agentOfflineForSocial = true;
        } else {
          this.agentOfflineForSocial = false;
        }
      }
    );

    const socialPushSettings = await this.conversationPushModeService.getCompanySettings();
    this.hasConversationPushModeEnabled =
      socialPushSettings && socialPushSettings.enabled;

    this.tags = this.tags.sort();

    PromiseQueue.configure(Promise);

    if (
      (this.$state.params.activity && !this.$state.params.conversation) ||
      this.localStorageService.get(INBOX_MODE_LS_KEY) === InboxMode.Activity
    ) {
      this.mode = InboxMode.Activity;
    }

    if (this.$state.params.reply) {
      this.isReply = this.$state.params.reply === 'true';
    }

    merge(of('load now'), interval(STATS_UPDATE_INTERVAL))
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.updateEngagementAnalytics());

    this.queries.conversation.query.activeResult.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((activeResult) => {
        if (activeResult) {
          this.queries.activity.query.conversation =
            activeResult.result.conversation;
          this.queries.active = this.queries.activity;
          this.queries.activity.query.search();
        } else {
          this.queries.activity.query.conversation = null;
          this.queries.activity.query.activeResult.value = null;
          this.queries.active = this.queries.conversation;
        }
      });

    if (this.mode === InboxMode.Conversation) {
      this.queries.active = this.queries.conversation;
      if (this.isSocialPushMode) {
        this.onExitConversation();
        this.createPushModeConversationQueries();
      }
      this.queries.conversation.query.search().then(() => {
        if (this.$state.params.conversation) {
          this.conversationModel
            .findOneById(this.$state.params.conversation)
            .then((conversation) => {
              this.loadConversation(conversation);
            });
        }
      });
    } else {
      this.queries.active = this.queries.activity;
      this.queries.activity.query
        .search()
        .then(() => this.checkAndLoadActivityFromUrl());
    }

    if (this.isSocialPushMode) {
      const socialPushModePresets = [
        {
          key: 'all',
          label: this.$translate.instant('ALL'),
          icon: 'ssi ssi-all-messages-new',
          query: {
            assignment: 'me'
          }
        },
        {
          key: 'active',
          label: this.$translate.instant('ACTIVE'),
          icon: 'ssi ssi-active-pushmode',
          query: {
            status: [],
            assignment: 'me'
          }
        },
        {
          key: 'onHold',
          label: this.$translate.instant('ON_HOLD'),
          icon: 'ssi ssi-on-hold',
          query: {
            assignment: 'me',
            status: [ConversationStatus.OnHold]
          }
        }
      ];

      this.queries.active.query.presets = socialPushModePresets;
    }

    // TODO - handle conversations being created
    fromEvent<Activity>(this.socket.getSocketInstance(), ACTIVITY_CREATED_EVENT)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((activityRaw) => {
        try {
          const wasInStore = !!this.activityModel.get(activityRaw.id);
          const activity = this.activityModel.inject(activityRaw);

          if (
            !(!!this.workflowAccounts && Array.isArray(this.workflowAccounts))
          ) {
            throw new Error(
              `value for 'inbox workflow accounts' is not in the expected format.`
            );
          }

          if (
            activity.inbox.requires_action &&
            this.workflowAccounts.find(
              (account) => +account.id === +activity.account_id
            ) &&
            !activity.inbox.is_silenced
          ) {
            this.queries.activity.newResults.total++;
            if (this.queries.activity.query.testResult(activity)) {
              this.queries.activity.newResults.filtered++;
            } else {
              this.queries.activity.newResults.unfiltered++;
            }
          }

          this.newIncomingReply = this.queries.activity.query.activityToResultListItem(
            activity
          );

          if (!wasInStore) {
            // this.activityModel.eject(activity.id);
          }

          return true;
        } catch (error) {
          console.error(error);

          return false;
        }
      });

    this.plugin.$pluginPinnedObservable().subscribe((bool) => {
      this.crmPinned = bool;
    });

    if (this.isSocialPushMode) {
      this.handleInboxPush();
    }

    await this.liveChatService.login();

    if (this.$state.params && this.$state.params.mode) {
      if (
        this.$state.params.mode === InboxMode.Activity &&
        this.mode === InboxMode.Conversation
      ) {
        this.onExitConversation();
        this.toggleInboxMode();
      } else if (
        this.$state.params.mode === InboxMode.Conversation &&
        this.mode === InboxMode.Activity
      ) {
        this.toggleInboxMode();
      }
    }
  }

  ngOnDestroy() {
    this.realtimeHelper.destroy();
    this.realtimeConversationHelper.destroy();
    this.onDestroy.next();

    if (this.$socialPushModeChecked) {
      this.$socialPushModeChecked.unsubscribe();
    }
  }

  createPushModeConversationQueries() {
    this.queries.onHoldConversation = this.createQuery(
      InboxQueryType.Conversation,
      'inbox_custom_preset_on_hold_conversation_filters'
    );
    this.queries.onHoldConversation.query.reset();
    this.queries.onHoldConversation.query.params['status'] = ['ONHOLD'];
    this.queries.onHoldConversation.query.params.sort = 'DATETIME';
    this.queries.onHoldConversation.query.params['assignment'] = 'me';
    this.queries.onHoldConversation.query.params.order = 'ASC';

    this.queries.activeConversation = this.createQuery(
      InboxQueryType.Conversation,
      'inbox_custom_preset_active_conversation_filters'
    );
    this.queries.activeConversation.query.reset();
    this.queries.activeConversation.query.params['status'] = [
      'UNREAD',
      'UNACTIONED',
      'ACTIONED'
    ];
    this.queries.activeConversation.query.params.sort = 'DATETIME';
    this.queries.activeConversation.query.params['assignment'] = 'me';
    this.queries.activeConversation.query.params.order = 'ASC';

    this.queries.onHoldConversation.query.search();
    this.queries.activeConversation.query.search();

    this.queries.onHoldConversation.query.activeResult.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((activeResult) => {
        // console.log('onHold activeResult:', activeResult);
        if (activeResult) {
          this.queries.activity.query.conversation =
            activeResult.result.conversation;
          this.queries.active = this.queries.activity;
          this.queries.onHoldConversation = this.queries.activity; // ??
          this.queries.activity.query.search();
        } else {
          this.queries.activity.query.conversation = null;
          this.queries.activity.query.activeResult.value = null;
          this.queries.active = this.queries.onHoldConversation;
        }
      });

    this.queries.activeConversation.query.activeResult.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((activeResult) => {
        // console.log('activeConversation activeResult:', activeResult);
        if (activeResult) {
          this.queries.activity.query.conversation =
            activeResult.result.conversation;
          this.queries.active = this.queries.activity;
          this.queries.activeConversation = this.queries.activity; // ??
          this.queries.activity.query.search();
        } else {
          this.queries.activity.query.conversation = null;
          this.queries.activity.query.activeResult.value = null;
          this.queries.active = this.queries.activeConversation;
        }
      });

    console.log('this.queries:', this.queries);
    // potentially need this
    // this.queries.active.query.search();
  }

  createQuery = <QueryType>(
    type: InboxQueryType,
    savedPresetPreferencesKey: string,
    urlQuery?: string
  ): InboxQuery<QueryType> => {
    const persistentStorageKey = `inbox-${type}-query-${
      this.workflowManager.getCurrentId() || 'all'
    }`;
    const persistentStorageKeyAll = `inbox-${type}-query-all`;

    const getSavedQuery = () => {
      if (this.$state.params && this.$state.params.modifiedQuery) {
        return Object.assign(
          JSON.parse(this.$state.params.modifiedQuery),
          this.localStorageService.get(persistentStorageKey)
        );
      } else if (this.localStorageService.get(persistentStorageKeyAll)) {
        return Object.assign(
          this.localStorageService.get(persistentStorageKeyAll),
          this.localStorageService.get(persistentStorageKey)
        );
      } else {
        return this.localStorageService.get(persistentStorageKey);
      }
    };

    const baseParams = {
      allAccounts: this.workflowAccounts,
      colleagues: this.colleagues,
      teams: this.teams,
      authUser: this.authUser,
      onSearch: (rawQuery, { offset }) => {
        this.localStorageService.set(persistentStorageKey, rawQuery);
        if (offset === 0) {
          this.queries[type].newResults = {
            total: 0,
            unfiltered: 0,
            filtered: 0
          };
          this.selectedResults = [];
        }
      },
      savedQuery: urlQuery ? JSON.parse(urlQuery) : getSavedQuery(),
      customPresets: this.userPreferencesLoaded[savedPresetPreferencesKey]
    };

    let query;

    if (type === InboxQueryType.Activity) {
      query = this.inboxQueryFactory.createActivityQuery({
        campaigns: this.campaigns,
        ...baseParams
      });
    } else {
      query = this.inboxQueryFactory.createConversationQuery(baseParams);
    }

    query.activeResult.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((activeResult: InboxQueryResultListItem) => {
        const id = activeResult ? activeResult.result.id : null;
        if (id) {
          this.$state.go('auth.inbox', { [type]: id }, { reload: false });
        } else {
          this.$state.go(
            'auth.inbox',
            { conversation: null, activity: null },
            { reload: false }
          );
        }
      });

    return {
      query,
      newResults: {
        unfiltered: 0,
        filtered: 0,
        total: 0
      },
      savePresets: (presets) => {
        return this.userPreferences.savePreferences({
          [savedPresetPreferencesKey]: presets
        });
      }
    };
  };

  async changeAgentStatus(newStatusId: AgentStatusID) {
    try {
      await this.conversationPushModeService.changeAgentStatus(newStatusId);
    } catch (error) {
      console.error('Error changing agent status:', error);
    }
  }

  async handleInboxPush() {
    this.$transitions.onStart({}, async (transition) => {
      if (this.socialPushModeDisabled) {
        this.$state.target('auth.inbox');
        return;
      }

      const current = transition.from();
      const target = transition.to();
      const externalTransition =
        current.name !== target.name && target.name !== 'auth.push';
      const agentStatus = await this.conversationPushModeService.getAgentStatus();
      const isAgentOnline = agentStatus === 'ONLINE';

      if (
        this.confirmedLeave ||
        !externalTransition ||
        (!isAgentOnline && !this.confirmedLeave)
      ) {
        return true;
      }
      const confirmLeaveModal = this.modal.open(ConfirmLeaveModalComponent, {
        windowClass: 'xl-modal orlo-modal',
        centered: true
      });
      confirmLeaveModal.result.then(async (result) => {
        if (result) {
          this.confirmedLeave = true;
          await this.changeAgentStatus(AgentStatusID.Busy);
          this.$state.go(target.name, {}, { notify: false });
        }
      });

      return false;
    });

    const showExitModal = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = 'You are still Online';

      const exitModalInstance = this.modal.open(ConfirmLeaveModalComponent, {
        windowClass: 'xl-modal orlo-modal',
        centered: true
      });
      exitModalInstance.result.then(async (result) => {
        if (result) {
          await this.changeAgentStatus(AgentStatusID.Busy);
          this.$state.go('auth.inbox');
        }
      });
    };

    this.conversationPushModeService.pushModeStatusChange.subscribe(
      (status: AgentStatusID) => {
        if (status === AgentStatusID.Online && !this.socialPushModeDisabled) {
          window.onbeforeunload = (ev: BeforeUnloadEvent) => showExitModal(ev);
        } else {
          window.onbeforeunload = () => {};
        }
      }
    );
  }

  checkAndLoadActivityFromUrl() {
    if (this.$state.params.activity) {
      this.activityModel
        .findOneById(this.$state.params.activity)
        .then((activity) => {
          this.loadActivity(activity);
        });
    }
  }

  onChangeActivity() {
    if (this.hasNewlyResolvedConversation) {
      this.onDismissConversationResolutionConfirmation();
    }

    this.searchResultsAreHidden = true;
    this.sidebarCollapsed = true;
  }

  onDismissConversationResolutionConfirmation() {
    this.hasNewlyResolvedConversation = false;
  }

  onExitConversation() {
    this.showActive = true;
    this.showOnHold = true;
    this.searchResultsAreHidden = false;
    this.sidebarCollapsed = true;

    this.isConversationThreadActive = false;
    this.queries.conversation.query.activeResult.value = null;
    if (this.isSocialPushMode) {
      this.createPushModeConversationQueries();
    }
    this.queries.active.query.search();
  }

  onExitActivity() {
    this.showActive = true;
    this.showOnHold = true;
    // Exit the inbox view on smaller screen sizes and show search results
    this.searchResultsAreHidden = false;
    this.sidebarCollapsed = true;
    this.isConversationThreadActive = false;
    this.queries.activity.query.activeResult.value = null;
    this.queries.active.query.search();
  }

  onExitLiveChat() {
    if (this.mode === InboxMode.Conversation) {
      this.mode = InboxMode.Conversation;
      this.queries.active = this.queries.conversation;
    } else {
      this.mode = InboxMode.Activity;
      this.queries.activity.query.conversation = null;
      this.queries.active = this.queries.activity;
    }
    // this.onExitConversation();
    // this.queries.activity.query.conversation = null;
    // this.queries.active = this.queries.activity;
    // this.queries.active.query.activeResult.value = null;
    // this.queries.active.query.search();
    // this.selectedResults = [];
    // this.reloadResults();
  }

  onResolveConversation() {
    this.hasNewlyResolvedConversation = true;
    if (this.isSocialPushMode) {
      this.showActive = true;
      this.showOnHold = true;
    }
    this.onExitConversation();
  }

  onHoldConversationStatusChanged() {
    this.showOnHold = true;
    this.onExitConversation();
  }

  toggleInboxMode() {
    // this.queries.active.query.activatePreset({ query: {} });
    if (this.mode === InboxMode.Conversation) {
      this.mode = InboxMode.Activity;
      this.queries.activity.query.conversation = null;
      this.queries.active = this.queries.activity;
    } else {
      this.mode = InboxMode.Conversation;
      this.queries.active = this.queries.conversation;
    }
    this.localStorageService.set(INBOX_MODE_LS_KEY, this.mode);
    this.selectedResults = [];
    this.queries.active.query.activeResult.value = null;
    this.queries.active.query.search();
  }

  loadActivity(activity: Activity) {
    console.log('loadActivity activity:', activity);
    try {
      if (
        !(
          !!this.queries &&
          !!this.queries.activity &&
          !!this.queries.activity.query &&
          !!this.queries.activity.query.result &&
          !!this.queries.activity.query.result.list &&
          Array.isArray(this.queries.activity.query.result.list)
        )
      ) {
        throw new Error(
          `value for 'inbox queries activity query result list' is not in the expected format.`
        );
      }

      this.queries.activity.query.activeResult.value = this.queries.activity.query.result.list.find(
        (item) =>
          item.type === InboxQueryResultListItemType.Activity &&
          item.result.activity === activity
      );
      // if loading an activity not in the results list e.g from the query string
      if (!this.queries.activity.query.activeResult.value) {
        this.queries.activity.query.activeResult.value = this.queries.activity.query.activityToResultListItem(
          activity
        );
      }
      this.queries.activity.query.localRefresh();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  loadConversation(conversation: Conversation, queryName = 'conversation') {
    try {
      this.checkAndLoadActivityFromUrl();
      if (
        !(
          !!this.queries &&
          !!this.queries[queryName] &&
          !!this.queries[queryName].query &&
          !!this.queries[queryName].query.result &&
          !!this.queries[queryName].query.result.list &&
          Array.isArray(this.queries[queryName].query.result.list)
        )
      ) {
        throw new Error(
          `value for 'inbox queries conversation query result list' is not in the expected format.`
        );
      }

      this.queries[queryName].query.activeResult.value = this.queries[
        queryName
      ].query.result.list.find(
        (item) =>
          item.type === InboxQueryResultListItemType.Conversation &&
          item.result.conversation === conversation
      );

      // if loading an activity not in the results list e.g from the query string
      if (!this.queries[queryName].query.activeResult.value) {
        this.queries[queryName].query.activeResult.value = this.queries[
          queryName
        ].query.conversationToResultListItem(conversation);
      }
      this.queries[queryName].query.localRefresh();
      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  afterSplitConversation(conversation: Conversation, activity: Activity) {
    this.queries.conversation.query.search().then(() => {
      this.loadConversation(conversation);
      this.loadActivity(activity);
    });
  }

  closeActivity() {
    this.searchResultsAreHidden = false;
    this.queries.activity.query.activeResult.value = null;
  }

  showVideoTutorial(video: { url: string; label: string }) {
    const videoModalInstance = this.modal.open(VideoModalComponent, {
      // windowClass: 'xl-modal orlo-modal',
      windowClass: 'modal-vertical modal-border-none modal-background-none',
      size: 'lg',
      centered: true
    });
    videoModalInstance.componentInstance.setVideoUrl(video.url);
  }

  saveCustomPreset(
    label: string,
    query: KeyValueObject,
    editExisting: QueryPreset
  ) {
    let preset;
    if (editExisting) {
      preset = Object.assign(editExisting, { label, query });
      this.queries.active.query.customPresets = this.queries.active.query.customPresets.map(
        (iPreset) => {
          if (iPreset.id === editExisting.id) {
            return preset;
          }
          return iPreset;
        }
      );
    } else {
      preset = {
        id: this.queries.active.query.customPresets.length,
        label,
        query
      };
      this.queries.active.query.customPresets.push(preset);
    }
    this.resultsOverlay = null;
    this.editing = null;
    this.presetName = '';
    this.queries.active.query.activatePreset(preset);
    this.queries.active.savePresets(this.queries.active.query.customPresets);
  }

  editPreset(preset: QueryPreset) {
    this.editing = { preset };
    this.resultsOverlay = InboxResultOverlayType.Filters;
    this.queries.active.query.activatePreset(preset);
  }

  async deletePreset(preset: QueryPreset) {
    const deletePreset = await this.popup.confirm({
      title: this.$translate.instant('DELETE_PRESET')
    });
    if (!deletePreset) {
      return;
    }

    this.queries.active.query.customPresets = this.queries.active.query.customPresets.filter(
      (iPreset) => iPreset.id !== preset.id
    );
    this.resultsOverlay = null;
    this.editing = null;
    this.presetName = '';
    this.queries.active.savePresets(this.queries.active.query.customPresets);
  }

  toggleSidebarCollapsed(value: boolean) {
    this.sidebarCollapsed = value;
  }

  toggleResultsOverlay(type: InboxResultOverlayType) {
    if (this.resultsOverlay === type) {
      this.resultsOverlay = null;
    } else if (
      type === InboxResultOverlayType.Filters &&
      this.queries.active.query.queryState.activePresetIsCustom
    ) {
      this.popup
        .confirm({
          title: this.$translate.instant('ONE_MOMENT'),
          message: this.$translate.instant('YOU_ARE_CURRENTLY_WITHIN_A_PRESET'),
          okText: this.$translate.instant('CLEAR_AND_ADD_FILTER')
        })
        .then((shouldClearFilters) => {
          if (shouldClearFilters) {
            this.queries.active.query.activatePreset({ query: {} });
            this.resultsOverlay = type;
          }
        });
    } else {
      this.resultsOverlay = type;
    }
  }

  editFilter(filter: Filter) {
    this.editing = { filter };
    this.resultsOverlay = InboxResultOverlayType.Filters;
  }

  onBeforeFilterClear(filter: Filter): void {
    // fired from filters component

    if (filter && filter.key === FilterKey.Campaign) {
      // for some reason the campaign filter keeps getting added to the query
      // (temp fix until we get rid of absract query builder)
      this.queries.active.query.apiQuery['campaign_ids'] = [];
    }
  }

  async removeFilter(filter: Filter) {
    // const filterDefaultValue = filter.getDefaultValue
    //   ? filter.getDefaultValue()
    //   : filter.defaultValue;

    // this.queries.active.query.apiQuery[
    //   filter.key
    // ] = this.queries.active.query.serializeValue(filter, filterDefaultValue);

    await this.filtersComponent.clearFilter(filter);
    this.filtersComponent.apply();
    this.resultsOverlay = null;
  }

  async clearFilters() {
    await this.filtersComponent.clearFilters();
    this.filtersComponent.apply();
    this.resultsOverlay = null;
  }

  exportInbox() {
    this.resultsOverlay = null;
    this.api
      .post('inbox/exportInbox', this.queries.activity.query.apiQuery)
      .then(() => {
        this.popup.alert({
          title: this.$translate.instant('PLEASE_CHECK_YOUR_EMAIL'),
          message: this.$translate.instant(
            'AN_EMAIL_WILL_BE_SENT_TO_YOU_SHORTLY_WITH_THE_EXPORTED_MESSAGES'
          )
        });
      });
  }

  selectResult(result: InboxQueryResultListItem) {
    if (!this.selectedResults.includes(result)) {
      this.selectedResults = [
        // create a new array to force change detection to run
        ...this.selectedResults,
        result
      ];
    }
  }

  deselectResult(result: InboxQueryResultListItem) {
    this.selectedResults = this.selectedResults.filter(
      (iResult) => iResult !== result
    );
  }

  deselectAllResults() {
    this.selectedResults = [];
  }

  toggleBulkAction(type: InboxBulkActionType) {
    if (this.bulkAction && this.bulkAction.type === type) {
      this.bulkAction = null;
    } else {
      this.bulkAction = {
        type,
        listSearch: null
      };
    }
  }

  markAllActioned() {
    this.popup
      .confirm({
        title: this.$translate.instant('ARE_YOU_SURE'),
        template: this.$translate.instant(
          'MARKING_ALL_MESSAGES_ACTIONED_CANNOT_BE_UNDONE'
        )
      })
      .then((confirmed) => {
        if (
          confirmed &&
          this.queries.active.query instanceof InboxActivityQuery
        ) {
          return this.queries.active.query.markAllActioned().then(() => {
            this.queries.active.query.search();
          });
        }
      });
  }

  markAllUnactioned() {
    this.popup
      .confirm({
        title: this.$translate.instant('ARE_YOU_SURE'),
        template: this.$translate.instant(
          'MARKING_ALL_MESSAGES_UNACTIONED_CANNOT_BE_UNDONE'
        )
      })
      .then((confirmed) => {
        if (
          confirmed &&
          this.queries.active.query instanceof InboxActivityQuery
        ) {
          return this.queries.active.query.markAllUnactioned().then(() => {
            this.queries.active.query.search();
          });
        }
      });
  }

  createTag(tag: string) {
    if (!this.tags.includes(tag)) {
      this.tags.push(tag);
      this.tags.sort();
      this.bulkAction.selectedValue = this.bulkAction.selectedValue || [];
      this.bulkAction.selectedValue.push(tag);
      this.bulkAction.listSearch = '';
    }
    this.activityTags.clearCache();
  }

  assignSelectedResults(userOrTeam: Colleague | Team) {
    this.bulkActionSelectedResults((item: InboxQueryResultListItem) => {
      if (item.type === InboxQueryResultListItemType.Activity) {
        return item.result.activity.assignTo(userOrTeam);
      } else {
        return item.result.conversation.assignTo(userOrTeam);
      }
    });
  }

  unassignSelectedResults() {
    this.bulkActionSelectedResults((item: InboxQueryResultListItem) => {
      if (item.type === InboxQueryResultListItemType.Activity) {
        return item.result.activity.assignTo(null);
      } else {
        return item.result.conversation.assignTo(null);
      }
    });
  }

  bulkMarkAsSpam() {
    this.bulkActionSelectedResults((item: InboxQueryResultListItem) => {
      if (item.type === InboxQueryResultListItemType.Activity) {
        return item.result.activity.markAsSpam();
      }
    });
  }

  changeSentimentOnSelectedResults(sentiment: number) {
    this.bulkActionSelectedResults((item: InboxQueryResultListItem) =>
      item.result.activity.changeSentiment(sentiment)
    );
  }

  tagSelectedResults(newTags: string[]) {
    this.bulkActionSelectedResults(async (item: InboxQueryResultListItem) => {
      let existingTags = [];
      let allTags = [];
      let uniqueTags = [];

      if (item.result.hasOwnProperty('conversation')) {
        const threadActivity = await this.activityModel.findOneById(
          item.result.conversation.thread_id
        );
        allTags = [...(threadActivity.tags || []), ...newTags];
        uniqueTags = Array.from(new Set(allTags).values());
        return item.result.conversation.setTags(uniqueTags);
      }

      existingTags = item.result.activity.tags || [];
      allTags = [...existingTags, ...newTags];
      uniqueTags = Array.from(new Set(allTags).values());

      return item.result.activity.setTags(uniqueTags);
    });
  }

  onConversationThreadChange() {
    this.isConversationThreadActive = true;
  }

  private bulkActionSelectedResults(
    bulkActionResult: (result: InboxQueryResultListItem) => Promise<any>,
    results: InboxQueryResultListItem[] = this.selectedResults
  ) {
    const queue = new PromiseQueue(MAX_PARALLEL_BULK_REQUESTS);
    const progress = {
      total: results.length,
      completed: 0
    };

    const bulkProgressModalInstance = this.modal.open(
      BulkProgressModalComponent,
      {
        windowClass: 'xl-modal orlo-modal',
        centered: true
      }
    );
    bulkProgressModalInstance.componentInstance.progress = progress;

    const promises = results.map((result) => {
      return queue.add(() => {
        return bulkActionResult(result).finally(() => {
          progress.completed++;
        });
      });
    });

    Promise.all(promises).finally(() => {
      // this.deselectAllResults();
      this.bulkAction = null;
      this.modal.dismissAll();
    });
  }

  private updateEngagementAnalytics() {
    return this.api
      .post('activity/aggregationLite', {
        account_ids: this.workflowAccounts.map((account) => account.id),
        start: moment().startOf('day').toDate(),
        end: moment().startOf('minute').toDate()
      })
      .then(({ data }) => {
        this.userEngagementStatistics = data;
      });
  }

  reloadResults(queryOverrides?: KeyValueObject) {
    this.editing = {};
    this.closeActivity();
    this.queries.active.query.search(0, 20, queryOverrides);

    setTimeout(() => {
      // timeout for a nice animation
      this.resultsOverlay = null;
    }, 400);
  }

  onApplyFilters(filters: Filters): void {
    // console.log('apply: ', filters);
    // console.log('component: ', this.filtersComponent);
    // console.log('activeFilters: ', this.activeFilters);

    const query = this.filtersComponent.toApiParams();
    const queryOverrides = JSON.parse(JSON.stringify(query));

    this.reloadResults(queryOverrides);
  }
}
