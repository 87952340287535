<div class="listening-modal">
  <div class="listening-modal-header">
    <div class="listening-modal-header-left">
      <h2>{{instagramHashtagId ? 'Delete Instagram Hashtag' : 'Create a new Hashtag'}}</h2>
    </div>
    <div class="listening-modal-header-right">
      <button
        (click)="activeModal.close(false)"
        class="listening-modal-close"
      >
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <ng-container *ngIf="!instagramHashtagId">
    <div class="listening-modal-body">
      <div class="listening-modal-action listening-modal-body-row">
        <div class="input-wrapper">
          <label>Instagram Hashtag</label>
          <div class="input-wrap">
            <input
              [(ngModel)]="instagramHashtag"
              type="text"
              [attr.placeholder]="'Add the hashtag you want to monitor...'"
            >
            <i class="ssi ssi-hashtag-empty-state"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="listening-modal-footer">
      <div class="listening-modal-footer-left">
      </div>
      <div class="listening-modal-footer-right">
        <button
          class="listening-modal-button cancel"
          (click)="activeModal.close(false)"
        >Cancel</button>
        <button
          class="listening-modal-button confirm"
          [disabled]="!instagramHashtag.length"
          (click)="addHashtag(); activeModal.close()"
        >Add Hashtag</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="instagramHashtagId">
    <div class="listening-modal-body">
      <p>
        Are you sure you want to delete this Hashtag? This cannot be undone
      </p>
      <div class="listening-modal-action listening-modal-body-row"></div>
    </div>

    <div class="listening-modal-footer">
      <div class="listening-modal-footer-left"></div>
      <div class="listening-modal-footer-right">
        <button
          class="listening-modal-button cancel"
          (click)="activeModal.close(false)"
        >Cancel</button>
        <button
          class="listening-modal-button confirm delete-2"
          [disabled]="false"
          (click)="deleteHashtag(); activeModal.close()"
        >
          <i class="ssi ssi-small-delete"></i>
          <span>Delete Hashtag</span>
        </button>
      </div>
    </div>
  </ng-container>
</div>