import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectChipsComponent } from './multi-select-chips.component';
import { FormsModule } from '@angular/forms';
import { OffClickModule } from '../../../../../../common/directives/off-click/off-click.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, OffClickModule, FormsModule, TranslateModule],
  declarations: [MultiSelectChipsComponent],
  exports: [MultiSelectChipsComponent]
})
export class MultiSelectChipsModule {}
