<div
  class="wide-version"
  [class.hidden-on-small-screens]="sidebarCollapsed"
>
  <div
    class="inbox-sidebar-results-count"
    *ngIf="!!activityQuery.activeResult.value"
  >
    <span
      translate="_COUNT__MESSAGES"
      [translateParams]="{count: inboxQuery.result.total}"
    ></span>
  </div>


  <div class="inbox-sidebar-inner">
    <div class="inbox-sidebar-top">


      <div
        class="inbox-sidebar-messages"
        [class.margin-bottom]="isSocialPushMode"
        *ngIf="(!isSocialPushMode || (isSocialPushMode && isActivityQuery()))"
      >
        <ul>
          <li
            class="inbox-sidebar-messages-total"
            [ngClass]="{ active: inboxQuery?.result.total > 0 }"
          >
            <span class="count">
              {{ inboxQuery?.result.total }}
            </span>

            <span
              class="label"
              translate="{{isSocialPushMode ? 'TOTAL' : 'MESSAGES'}}"
            ></span>
          </li>

          <li
            class="inbox-sidebar-messages-unread"
            [ngClass]="{active: newMessagesTotalCount > 0}"
            (click)="inboxQuery.activatePreset({query: {}})"
          >
            <span class="count">
              {{ newMessagesTotalCount }}
            </span>

            <span
              class="label"
              translate="{{isSocialPushMode ? 'UNREAD' : 'ALL_NEW'}}"
            ></span>
          </li>

          <li
            *ngIf="!isSocialPushMode"
            class="inbox-sidebar-messages-filtered"
            [ngClass]="{active: newMessagesFilteredCount > 0}"
            (click)="inboxQuery.search()"
          >
            <span class="count">
              {{ newMessagesFilteredCount }}
            </span>

            <span
              class="label"
              translate="FILTERED"
            ></span>
          </li>

          <li
            *ngIf="!isSocialPushMode"
            class="inbox-sidebar-messages-assigned"
            [ngClass]="{active: userEngagementStatistics?.assigned_to_user > 0}"
          >
            <span class="count">
              {{ userEngagementStatistics?.assigned_to_user }}
            </span>

            <span
              class="label"
              translate="ASSIGNED"
            ></span>
          </li>
        </ul>

        <button (click)="inboxQuery.search()">
          <i class="ssi ssi-refresh-new refresh-icon"></i>
        </button>
      </div>

      <a
        class="inbox-sidebar-item inbox-sidebar-item-spacing-bottom inbox-sidebar-item-spacing-top inbox-sidebar-item-btn-list-filters inbox-sidebar-item-btn-list-filters-clear"
        (click)="onClearFilters.emit()"
        *ngIf="activeFilters.length && !inboxQuery.queryState.activePresetIsCustom && (!isSocialPushMode || (isSocialPushMode && isActivityQuery()))"
      >
        <span>Clear all filters</span>
        <i class="ssi ssi-times"></i>
      </a>

      <a
        class="inbox-sidebar-item inbox-sidebar-item-bg-dark inbox-sidebar-item-bg-light-hover inbox-sidebar-item-add-filter inbox-sidebar-item-spacing-bottom"
        (click)="onToggleFilters.emit()"
        *ngIf="!isLiveChatActive && (!isSocialPushMode || (isSocialPushMode && isActivityQuery()))"
      >
        <span translate="ADD_FILTER"></span> <i class="ssi ssi-filters pull-right"></i>
      </a>

      <div
        class="inbox-sidebar-filters-list"
        *ngIf="!isLiveChatActive && activeFilters.length && !inboxQuery.queryState.activePresetIsCustom"
        [ngbCollapse]="!sidebarFiltersExpanded"
      >
        <ul *ngIf="filtersComponentRef?.filtersIterable.length">
          <li *ngFor="let filter of activeFilters">
            <a
              class="btn-edit"
              (click)="onEditFilter.emit({filter: filter})"
            >
              <i class="ssi ssi-correctedit ssi-fw"></i>
            </a>

            <span class="filter-label">
              {{ filter.getAppliedValuesLabel() }}
            </span>

            <a
              class="btn-remove"
              (click)="onRemoveFilter.emit({filter: filter})"
            >
              <i class="fa fa-times"></i>
            </a>
          </li>
        </ul>
      </div>

      <a
        class="inbox-sidebar-item inbox-sidebar-item-spacing-bottom inbox-sidebar-item-spacing-top inbox-sidebar-item-btn-list-filters"
        (click)="sidebarFiltersExpanded = !sidebarFiltersExpanded"
        *ngIf="!isLiveChatActive && activeFilters.length && !inboxQuery.queryState.activePresetIsCustom && (!isSocialPushMode || (isSocialPushMode && isActivityQuery()))"
      >
        <span
          *ngIf="activeFilters.length === 1"
          translate="_COUNT__FILTER_ADDED"
          [translateParams]="{count: activeFilters.length}"
        >
        </span>

        <span
          *ngIf="activeFilters.length > 1"
          translate="_COUNT__FILTERS_ADDED"
          [translateParams]="{count: activeFilters.length}"
        >
        </span>

        <i
          class="ssi"
          [ngClass]="{'ssi-arrow-up': sidebarFiltersExpanded, 'ssi-arrow-down-correct': !sidebarFiltersExpanded}"
        >
        </i>
      </a>


      <div
        class="inbox-sidebar-active-preset"
        *ngIf="!isLiveChatActive && inboxQuery?.queryState.activePresetIsCustom"
      >
        <ul>
          <li>
            <a
              class="btn-edit"
              (click)="onEditPreset.emit({preset: inboxQuery.queryState.activePreset})"
            >
              <i class="ssi ssi-correctedit ssi-fw"></i>
            </a>

            <span class="preset-label">
              {{ inboxQuery.queryState.activePreset.label }}
            </span>

            <a
              class="btn-remove"
              (click)="inboxQuery.activatePreset({query: {}})"
            >
              <i class="fa fa-times"></i>
            </a>
          </li>
        </ul>
      </div>


      <ul
        class="bulk-actions-menu"
        *ngIf="!isLiveChatActive && selectedResults.length > 0"
      >
        <li>
          <button
            class="inbox-sidebar-item-bulk-deselect"
            (click)="onDeselectSelectedResults.emit()"
          >
            <span translate="DESELECT_ALL"></span>
            <i class="ssi ssi-times"></i>
          </button>
        </li>


        <li>
          <button
            class="inbox-sidebar-item-bulk-assign"
            (click)="onToggleBulkAction.emit({action: 'assign'})"
          >
            <span translate="BULK_ASSIGN"></span>
            <i class="ssi ssi-assign-correct"></i>
          </button>
        </li>


        <li>
          <button
            class="inbox-sidebar-item-bulk-sentiment"
            (click)="onToggleBulkAction.emit({action: 'sentiment'})"
            *ngIf="inboxQuery.type === 'activity'"
          >
            <span translate="BULK_SENTIMENT"></span>
            <i class="ssi ssi-amend-sentiment-new"></i>
          </button>
        </li>


        <li>
          <button
            class="inbox-sidebar-item-bulk-tag"
            (click)="onToggleBulkAction.emit({action: 'tag'})"
            *ngIf="['activity'].includes(inboxQuery.type)"
          >
            <span translate="BULK_TAG"></span>
            <i class="ssi ssi-tag-test"></i>
          </button>
        </li>

        <li *ngIf="spamDetectionEnabled">
          <button
            class="inbox-sidebar-item-bulk-spam"
            (click)="onBulkMarkAsSpam.emit()"
            *ngIf="inboxQuery.type === 'activity'"
          >
            <span>Bulk spam</span>
            <i class="ssi ssi-inbox-spam"></i>
          </button>
        </li>


        <li
          class="inbox-sidebar-item-bulk-tip"
          (mouseenter)="showBulkTip = true"
          (mouseleave)="showBulkTip = false"
        >
          <dl>
            <dt class="title">
              <span translate="BULK_TIP"></span>
              <i class="ssi ssi-pro-tip"></i>
            </dt>

            <dd
              class="body"
              *ngIf="showBulkTip"
            >
              <span translate="TO_SELECT_MULTIPLE_POSTS_AT_ONCE_HOLD_THE"></span>

              <em translate="SHIFT_KEY"></em>

              <span translate="AND_CLICK_THE_POSTS_YOU_WANT_WITH_YOUR_MOUSE"></span>
            </dd>
          </dl>
        </li>
        
      </ul>
    </div>


    <div class="inbox-sidebar-bottom">
      <a
        *ngIf="!isLiveChatActive && (!isSocialPushMode || (isSocialPushMode && isActivityQuery())) && inboxQuery?.customPresets.length > 0 && (inboxMode === 'activity' || inboxQuery.type === 'conversation')"
        (click)="showCustomPresets = !showCustomPresets"
        class="inbox-sidebar-item inbox-sidebar-item-bg-light-hover inbox-sidebar-item-custom-presets-toggle"
      >
        <span translate="MY_PRESETS"></span>

        <i
          class="ssi"
          [ngClass]="{'ssi-arrow-down-correct text-primary': showCustomPresets, 'ssi-arrow-up': !showCustomPresets}"
        ></i>
      </a>

      <div
        class="inbox-sidebar-item-spacing-bottom inbox-sidebar-custom-presets-list"
        *ngIf="!isLiveChatActive && inboxQuery && (!isSocialPushMode || (isSocialPushMode && isActivityQuery()))"
        [ngbCollapse]="!showCustomPresets || inboxQuery.customPresets.length === 0 || (inboxMode === 'conversation' && inboxQuery.type === 'activity')"
      >
        <ul>
          <li
            *ngFor="let preset of inboxQuery.customPresets"
            [ngClass]="{selected: inboxQuery.queryState.activePreset === preset}"
          >
            <a
              class="btn-edit"
              (click)="onEditPreset.emit({preset: preset})"
            >
              <i class="ssi ssi-correctedit"></i>
            </a>

            <a
              class="preset-label"
              (click)="inboxQuery.activatePreset(preset)"
            >
              <span>
                {{ preset.label }}
              </span>

              <i class="fa fa-fw fa-circle pull-right"></i>
            </a>
          </li>
        </ul>
      </div>

      <div
        class="inbox-sidebar-presets-list"
        *ngIf="!isLiveChatActive && inboxMode === 'activity' || !isLiveChatActive && inboxQuery?.type === 'conversation'"
      >
        <ul>
          <li
            class="selected"
            *ngIf="!showCustomPresets && inboxQuery.queryState.activePresetIsCustom"
          >
            <span>
              {{ inboxQuery.queryState.activePreset.label }}
            </span>

            <i class="fa fa-circle fa-fw"></i>
          </li>

          <li
            *ngFor="let preset of inboxQuery.presets"
            [ngClass]="{selected: inboxQuery.queryState.activePreset === preset}"
            (click)="activatePreset(preset)"
          >
            <span>
              {{ preset.label }}
            </span>

            <i class="fa fa-circle fa-fw"></i>
          </li>
        </ul>
      </div>

      <a
        class="inbox-sidebar-item inbox-sidebar-item-spacing-bottom inbox-sidebar-item-conversations-button"
        [ngClass]="{ 'active': inboxMode === 'conversation' }"
        (click)="onToggleInboxMode.emit()"
        *ngIf="!isLiveChatActive && !isSocialPushMode"
      >
        <span
          class="button-text"
          translate="CONVERSATIONS"
        ></span>

        <i
          class="fa fa-fw fa-power-off pull-right button-icon"
          [ngClass]="{'text-primary': inboxMode === 'conversation'}"
        ></i>
      </a>

      <a
        *ngIf="hasConversationPushModeEnabled && !isSocialPushMode"
        uiSref="auth.inbox"
        [uiParams]="{push: true, mode: 'conversation', query: null}"
        class="inbox-sidebar-item inbox-sidebar-item-spacing-bottom social-push-btn"
      >
        Social Push Mode
      </a>
      <a
        (click)="inboxQuery.activatePreset({query: {}})"
        *ngIf="hasConversationPushModeEnabled && isSocialPushMode"
        uiSref="auth.inbox"
        [uiParams]="{query: ({assignment: ''} | jsonStringify)}"
        [uiOptions]="{reload: true, inherit: false}"
        class="inbox-sidebar-item inbox-sidebar-item-spacing-bottom social-push-btn exit"
      >
        <i class="ssi ssi-line-arrow-small-down"></i>
        Exit Push Mode
      </a>

      <ssi-inbox-useful-things
        *ngIf="inboxQuery?.type === 'activity'"
        [isPopupTemplate]="false"
        [tutorialVideos]="tutorialVideos"
        (onShowVideoTutorial)="onShowVideoTutorial.emit({video: $event})"
        (onMarkAllActioned)="onMarkAllActioned.emit()"
        (onMarkAllUnactioned)="onMarkAllUnactioned.emit()"
        (onExportInbox)="onExportInbox.emit()"
      ></ssi-inbox-useful-things>
    </div>
  </div>
</div>

<!-- Inbox sidebar slim version -->
<div
  class="slim-version"
  [class.must-show]="resultsOverlay"
>
  <div
    class="inbox-sidebar-slim-header"
    *ngIf="!!inboxQuery?.activeResult.value && (inboxMode === 'activity' || inboxQuery.type === 'conversation')"
  >
    <div class="inbox-sidebar-slim-item inbox-sidebar-slim-item-count">
      <div class="inbox-sidebar-slim-item-inner">
        <div class="count">{{ inboxQuery.result.total }}</div>
        <div
          class="count-label"
          translate="MESSAGES"
        ></div>
      </div>
    </div>
  </div>

  <div
    class="inbox-sidebar-slim-body"
    [ngClass]="{'conversation-mode': inboxMode === 'conversation'}"
  >

    <div class="inbox-sidebar-slim-top">

      <dl
        class="inbox-sidebar-slim-messages"
        *ngIf="!isSocialPushMode"
      >
        <dd
          class="refresh-button-container"
          *ngIf="!isSocialPushMode"
        >
          <button
            class="refresh-button"
            (click)="inboxQuery.search()"
          >
            <i class="ssi ssi-refresh-new button-icon"></i>
          </button>
        </dd>

        <dt
          class="inbox-sidebar-slim-messages-total"
          [ngClass]="{ active: inboxQuery?.result.total > 0 }"
        >
          <span class="total-value">
            {{ inboxQuery?.result.total }}
          </span>

          <span
            class="total-label"
            translate="{{isSocialPushMode ? 'TOTAL' : 'MESSAGES'}}"
          ></span>
        </dt>

        <dd class="statistics-container">
          <ul>
            <li
              class="inbox-sidebar-slim-messages-unread statistic"
              [ngClass]="{active: newMessagesTotalCount > 0}"
              (click)="inboxQuery.activatePreset({query: {}})"
            >
              <span class="statistic-value">
                {{ newMessagesTotalCount }}
              </span>

              <span
                class="statistic-label"
                translate="ALL_NEW"
              ></span>
            </li>

            <li
              *ngIf="!isSocialPushMode"
              class="inbox-sidebar-slim-messages-filtered statistic"
              [ngClass]="{active: newMessagesFilteredCount > 0}"
              (click)="inboxQuery.search()"
            >
              <span class="statistic-value">
                {{ newMessagesFilteredCount }}
              </span>

              <span
                class="statistic-label"
                translate="FILTERED"
              ></span>
            </li>

            <li
              *ngIf="!isSocialPushMode"
              class="inbox-sidebar-slim-messages-assigned statistic"
              [ngClass]="{active: userEngagementStatistics?.assigned_to_user > 0}"
            >
              <span class="statistic-value">
                {{ userEngagementStatistics?.assigned_to_user }}
              </span>

              <span
                class="statistic-label"
                translate="ASSIGNED"
              ></span>
            </li>
          </ul>
        </dd>
      </dl>
    </div>

    <nav class="inbox-sidebar-slim-bottom">
      <ul
        class="inbox-sidebar-slim-standard-tools"
        *ngIf="!selectedResults.length"
      >
        <li
          *ngIf="!isLiveChatActive && activeFilters.length  && !isSocialPushMode"
          class="inbox-sidebar-slim-item inbox-sidebar-slim-item-clickable inbox-sidebar-slim-item-icon inbox-sidebar-slim-item-icon-count"
          [ngClass]="{active: activeFiltersVisible}"
          (click)="onToggleActiveFilters.emit()"
          [ngbPopover]="'_COUNT__FILTERS_ADDED' | translate:{count: activeFilters.length}"
          triggers="mouseenter:mouseleave"
          popoverClass="popover-dark"
          container="body"
        >
          <span class="count">
            {{ activeFilters.length }}
          </span>

          <i class="ssi ssi-correctedit"></i>
        </li>

        <li
          *ngIf="!isLiveChatActive && !isSocialPushMode"
          class="inbox-sidebar-slim-item inbox-sidebar-slim-item-clickable inbox-sidebar-slim-item-icon"
          [ngClass]="{active: filtersVisible}"
          (click)="onToggleFilters.emit()"
          [ngbPopover]="'ADD_FILTER' | translate"
          triggers="mouseenter:mouseleave"
          popoverClass="popover-dark"
          container="body"
        >
          <i class="ssi ssi-filters"></i>
        </li>

        <li
          *ngIf="!isLiveChatActive && activeFilters.length && !isSocialPushMode"
          class="inbox-sidebar-slim-item inbox-sidebar-slim-item-clickable inbox-sidebar-slim-item-icon"
          [ngClass]="{active: activeFiltersVisible}"
          (click)="inboxQuery.activatePreset({query: {}})"
          [ngbPopover]="'CLEAR_ALL_FILTERS' | translate"
          triggers="mouseenter:mouseleave"
          popoverClass="popover-dark"
          container="body"
        >
          <i class="ssi ssi-fail"></i>
        </li>

        <li
          *ngIf="!isLiveChatActive && (inboxMode === 'activity' || inboxQuery?.type === 'conversation') && !isSocialPushMode"
          class="inbox-sidebar-slim-item inbox-sidebar-slim-item-clickable inbox-sidebar-slim-item-icon"
          [ngClass]="{active: presetsVisible}"
          (click)="onTogglePresets.emit()"
          [ngbPopover]="'MY_PRESETS' | translate"
          triggers="mouseenter:mouseleave"
          popoverClass="popover-dark"
          container="body"
        >
          <i class="fa fa-star-o"></i>
        </li>

        <ng-container *ngIf="!isLiveChatActive && (inboxMode === 'activity' || inboxQuery?.type === 'conversation')">
          <li
            class="inbox-sidebar-slim-item inbox-sidebar-slim-item-clickable inbox-sidebar-slim-item-icon"
            [ngClass]="{active: inboxQuery.queryState.activePreset === preset}"
            *ngFor="let preset of inboxQuery.presets"
            (click)="activatePreset(preset)"
            [ngbPopover]="preset.label"
            triggers="mouseenter:mouseleave"
            popoverClass="popover-dark"
            container="body"
          >
            <i [ngClass]="preset.icon"></i>
          </li>
        </ng-container>


        <li
          *ngIf="!isLiveChatActive && !isSocialPushMode"
          class="inbox-sidebar-slim-item inbox-sidebar-slim-item-clickable inbox-sidebar-slim-item-icon"
          [ngClass]="{active: inboxMode === 'conversation'}"
          [ngbPopover]="'CONVERSATION_MODE_PIPE__STATUS__' | translate: { status: inboxMode === 'conversation' ? 'On' : 'Off' }"
          triggers="mouseenter:mouseleave"
          popoverClass="popover-dark"
          container="body"
          (click)="onToggleInboxMode.emit()"
        >
          <i class="ssi ssi-conversations-on-off"></i>
        </li>

        <li
          *ngIf="hasConversationPushModeEnabled && !isSocialPushMode"
          class="inbox-sidebar-slim-item inbox-sidebar-slim-item-clickable inbox-sidebar-slim-item-icon"
          uiSref="auth.inbox"
          [uiParams]="{push: true, mode: 'conversation'}"
          [ngbPopover]="'SOCIAL_PUSH_MODE' | translate"
          triggers="mouseenter:mouseleave"
          popoverClass="popover-dark"
          container="body"
        >
          <i class="ssi ssi-push-mode"></i>
        </li>
        <li
          *ngIf="hasConversationPushModeEnabled && isSocialPushMode"
          class="inbox-sidebar-slim-item inbox-sidebar-slim-item-clickable inbox-sidebar-slim-item-icon social-push-btn exit"
          (click)="inboxQuery.activatePreset({query: {}})"
          uiSref="auth.inbox"
          [uiParams]="{query: ({assignment: ''} | jsonStringify)}"
          [uiOptions]="{reload: true, inherit: false}"
          [ngbPopover]="'EXIT_PUSH_MODE' | translate"
          triggers="mouseenter:mouseleave"
          popoverClass="popover-dark"
          container="body"
        >
          <i class="ssi ssi-line-arrow-small-down"></i>
        </li>
      </ul>

      <ul
        class="inbox-sidebar-slim-bulk-tools"
        *ngIf="!isLiveChatActive && selectedResults.length > 0"
      >
        <li
          class="inbox-sidebar-slim-item inbox-sidebar-slim-item-clickable inbox-sidebar-slim-item-icon bulk-tool-deselect"
          (click)="onDeselectSelectedResults.emit()"
          [ngbPopover]="'DESELECT_ALL' | translate"
          triggers="mouseenter:mouseleave"
          popoverClass="popover-dark"
          container="body"
        >
          <i class="ssi ssi-times slim-item-icon"></i>
        </li>

        <li
          class="inbox-sidebar-slim-item inbox-sidebar-slim-item-icon bulk-tool-tip"
          [ngbPopover]="'TO_SELECT_MULTIPLE_POSTS_AT_ONCE_HOLD_THE__KEY__AND_CLICK_THE_POSTS_YOU_WANT_WITH_YOUR_MOUSE' | translate: {key: ('SHIFT_KEY' | translate)}"
          [popoverTitle]="'BULK_TIP' | translate"
          triggers="mouseenter:mouseleave"
          popoverClass="popover-dark"
          container="body"
        >
          <i class="fa fa-diamond slim-item-icon"></i>
        </li>

        <li
          class="inbox-sidebar-slim-item inbox-sidebar-slim-item-clickable inbox-sidebar-slim-item-icon inbox-sidebar-slim-item-bulk-assign bulk-tool-assign"
          (click)="onToggleBulkAction.emit({action: 'assign'})"
          [ngbPopover]="'BULK_ASSIGN' | translate"
          triggers="mouseenter:mouseleave"
          popoverClass="popover-dark"
          container="body"
        >
          <i class="ssi ssi-user-assign slim-item-icon"></i>
        </li>

        <li
          *ngIf="inboxQuery.type === 'activity'"
          class="inbox-sidebar-slim-item inbox-sidebar-slim-item-clickable inbox-sidebar-slim-item-icon inbox-sidebar-slim-item-bulk-sentiment bulk-tool-sentiment"
          (click)="onToggleBulkAction.emit({action: 'sentiment'})"
          [ngbPopover]="'BULK_SENTIMENT' | translate"
          triggers="mouseenter:mouseleave"
          popoverClass="popover-dark"
          container="body"
        >
          <i class="ssi ssi-v-positive slim-item-icon"></i>
        </li>

        <li
          *ngIf="['conversation', 'activity'].includes(inboxQuery.type)"
          class="inbox-sidebar-slim-item inbox-sidebar-slim-item-clickable inbox-sidebar-slim-item-icon inbox-sidebar-slim-item-bulk-tag bulk-tool-tag"
          (click)="onToggleBulkAction.emit({action: 'tag'})"
          [ngbPopover]="'BULK_TAG' | translate"
          triggers="mouseenter:mouseleave"
          popoverClass="popover-dark"
          container="body"
        >
          <i class="ssi ssi-tag-test slim-item-icon"></i>
        </li>

        <li class="inbox-sidebar-slim-item inbox-sidebar-slim-item-count bulk-tool-count">
          <span class="count-value">
            {{ selectedResults.length }}
          </span>

          <span
            class="count-label"
            translate="SELECTED"
          ></span>
        </li>
      </ul>

      <ul
        class="inbox-sidebar-slim-tools"
        *ngIf="!isSocialPushMode"
      >
        <li
          class="inbox-sidebar-slim-item inbox-sidebar-slim-item-clickable inbox-sidebar-slim-item-icon spacer-top useful-things"
          [ngClass]="{active: usefulThingsVisible}"
          (click)="onToggleUsefulThings.emit()"
          [ngbPopover]="'USEFUL_THINGS' | translate"
          triggers="mouseenter:mouseleave"
          popoverClass="popover-dark"
          container="body"
        >
          <i class="ssi ssi-pro-tip"></i>
        </li>
      </ul>

    </nav>

  </div>
</div>