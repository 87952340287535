<div class="cc-filters-container"
     [class.standalone]="standalone"
     [hidden]="!visible">

  <div class="filters-wrapper">
    <div class="filters-section"
         [class.standalone]="standalone">

      <h3 *ngIf="standalone"> Apply Filters </h3>
      <br *ngIf="standalone">

      <ng-container *ngIf="filters.keyword || filters.booleanQuery">
        <div class="input-search-container"
             *ssiVar="filters.keyword || filters.booleanQuery as filter">
          <input type="search"
                 class="g-input"
                 placeholder="{{filter.placeholder}}"
                 (keyup)="onKeywordSearchInputKeyup($event)"
                 [(ngModel)]="keywordSearchTempModelValue">

          <button *ngIf="filters.booleanQuery"
                  type="button"
                  class="g-btn g-btn-secondary pro-tip-btn"
                  [ssiTooltip]="'Pro tips to help you build a query'"
                  [tooltipWindowClass]="'tooltip-chunky'"
                  (click)="modal.open(proTipModal, {
                    windowClass: 'insights-advanced-search-pro-tip-modal',
                    centered: true
                  })">
            <i class="ssi ssi-pro-tip"></i>
          </button>

          <i class="ssi {{filter.icon}} loupe-icon"
             [class.pro-tip-btn-visible]="filters.booleanQuery"></i>

          <div class="search-chip"
               *ngIf="filter.modelValue">
            <span>{{filter.modelValue}}</span>
            <i class="ssi ssi-delete-close"
               (click)="removeKeywordSearchChip()"></i>
          </div>
        </div>
      </ng-container>
      <br>

      <div class="accordion">
        <div *ngFor="let filter of filtersIterable | filter: insideAccordion"
             class="accordion-item">

          <div class="filter-head"
               (click)="filter.expanded = !filter.expanded">
            <div class="left">
              <i class="ssi {{filter.icon}} filter-head-icon"></i>
              <span class="title">{{filter.title}}</span>
            </div>
            <div class="right"
                 *ssiVar="filter.getAppliedValuesCount(filter.key) as appliedValuesCount">
              <button *ngIf="appliedValuesCount > 0"
                      type="button"
                      class="g-btn g-btn-sm g-btn-secondary bg-red clear-filter-btn"
                      (click)="clearFilter(filter)">
                <i class="ssi ssi-delete-close"></i>
                <span>
                  Clear {{appliedValuesCount}} {{appliedValuesCount === 1 ? 'filter' : 'filters'}}
                </span>
              </button>
              <i class="ssi ssi-arrow-down-new chevron"
                 [class.up]="filter.expanded"></i>
            </div>
          </div>

          <div class="filter-body"
               *ngIf="filter.expanded"
               [ngSwitch]="filter.type">

            <ng-container *ngSwitchCase="FilterType.MultiSelect">
              <ssi-list-select2 name="{{filter.key}}"
                                class="{{filter.key}}-selector"
                                [required]="true"
                                [multiple]="true"
                                [filterable]="!!filter.filterPlaceholder"
                                [filterPlaceholder]="filter.filterPlaceholder"
                                [toggleAllLabel]="'Select all'"
                                [options]="filter.options"
                                [(ngModel)]="filter.modelValue"
                                (ngModelChange)="onFilterModelValueChange($event, filter)">

                <ng-template *ngIf="filter === filters.account"
                             ssiTemplateSelector="optionIcon"
                             let-account="option"
                             let-selected="selected">
                  <ssi-social-network-icon [accountTypeId]="account.account_type_id"
                                           [color]="selected ? '#14bae3' : '#43537f'"
                                           variant="fa"></ssi-social-network-icon>
                </ng-template>

                <ng-template *ngIf="filter !== filters.account"
                             ssiTemplateSelector="optionIcon"
                             let-option="option">
                  <i *ngIf="option.icon"
                     class="ssi {{option.icon}}"></i>
                </ng-template>
              </ssi-list-select2>
            </ng-container>

            <ng-container *ngSwitchCase="FilterType.MultiSelectTree">
              <ssi-list-select2 name="{{filter.key}}"
                                class="{{filter.key}}-selector"
                                [required]="true"
                                [multiple]="true"
                                [filterable]="!!filter.filterPlaceholder"
                                [filterPlaceholder]="filter.filterPlaceholder"
                                [filterFn]="filter.filterFn"
                                [toggleAllLabel]="'Select all'"
                                [toggleAllEnabled]="false"
                                [options]="filter.options"
                                [(ngModel)]="filter.modelValue"
                                (ngModelChange)="onFilterModelValueChange($event, filter);"
                                (onOptionToggle)="onOptionToggle($event, filter)"
                                (optionsFiltered)="onOptionsFiltered($event, filter)">

                <ng-container *ngIf="filter === filters.campaign">
                  <ng-template ssiTemplateSelector="optionIcon"
                               let-parentCampaign="option"
                               let-selected="selected">
                    <i *ngIf="parentCampaign._children"
                       class="ssi ssi-arrow-down-new chevron expand-campaign-icon"
                       [class.down]="parentCampaign._children.expanded"
                       (click)="$event.stopPropagation(); expandCollapseCampaign(parentCampaign)"></i>
                  </ng-template>

                  <ng-template ssiTemplateSelector="expandedContent"
                               let-parentCampaign="option"
                               let-selected="selected">
                    <ssi-list-select2 *ngIf="parentCampaign._children && parentCampaign._children.expanded"
                                      name="{{parentCampaign.id}}-children"
                                      class="{{filter.key}}-children-selector"
                                      [required]="true"
                                      [multiple]="true"
                                      [filterable]="true"
                                      [filterPlaceholder]="'Search child campaigns...'"
                                      [toggleAllLabel]="'Select all'"
                                      [toggleAllEnabled]="true"
                                      [options]="parentCampaign._children.filteredOptions"
                                      [(ngModel)]="parentCampaign._children.modelValue"
                                      (ngModelChange)="onFilterModelValueChange($event, filter);"
                                      (onOptionToggle)="onChildCampaignToggle($event, parentCampaign)">

                      <ng-template ssiTemplateSelector="optionIcon">
                        <!-- Just so it keeps space filled -->
                      </ng-template>
                    </ssi-list-select2>
                  </ng-template>
                </ng-container>

              </ssi-list-select2>
            </ng-container>

            <ng-container *ngSwitchCase="FilterType.Select">
              <ssi-list-select2 name="{{filter.key}}"
                                class="{{filter.key}}-selector"
                                [required]="true"
                                [multiple]="false"
                                [filterable]="!!filter.filterPlaceholder"
                                [filterPlaceholder]="filter.filterPlaceholder"
                                [options]="filter.options"
                                [(ngModel)]="filter.modelValue"
                                (ngModelChange)="onFilterModelValueChange($event, filter)">

                <ng-template ssiTemplateSelector="optionIcon"
                             let-option="option">
                  <i *ngIf="option.icon"
                     class="ssi {{option.icon}}"></i>
                </ng-template>
              </ssi-list-select2>
            </ng-container>

            <ng-container *ngSwitchCase="FilterType.DateRange">
              <div class="date-range-filter-container">
                <div class="form-field half">
                  <label translate="START_DATE"></label>
                  <div class="input-wrapper"
                       #startDateContainer>
                    <input mwlFlatpickr
                           class="date-picker-input"
                           type="text"
                           name="startDate"
                           [(ngModel)]="filter.modelValue.start"
                           (ngModelChange)="onFilterModelValueChange($event, filter);"
                           [altInput]="true"
                           [convertModelValue]="true"
                           [enableTime]="true"
                           [defaultHour]="00"
                           [defaultMinute]="00"
                           [defaultSeconds]="00"
                           dateFormat="Y-m-d H:i"
                           altFormat="M J Y, H:i"
                           [minDate]=""
                           [maxDate]="filter.modelValue.end"
                           [time24hr]="true"
                           [appendTo]="startDateContainer.nativeElement"
                           placeholder="Select..."
                           [required]="true">
                  </div>
                </div>
                <div class="form-field half">
                  <label translate="END_DATE"></label>
                  <div class="input-wrapper"
                       #endDateContainer>
                    <input mwlFlatpickr
                           class="date-picker-input"
                           type="text"
                           name="endDate"
                           [(ngModel)]="filter.modelValue.end"
                           (ngModelChange)="onFilterModelValueChange($event, filter);"
                           [altInput]="true"
                           [convertModelValue]="true"
                           [enableTime]="true"
                           [defaultHour]="23"
                           [defaultMinute]="59"
                           [defaultSeconds]="59"
                           dateFormat="Y-m-d H:i"
                           altFormat="M J Y, H:i"
                           [minDate]="filter.modelValue.start"
                           [maxDate]=""
                           [time24hr]="true"
                           [appendTo]="endDateContainer.nativeElement"
                           placeholder="Select..."
                           [required]="true">
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="FilterType.TagsIncludeExcludeSelect">
              <div class="tags-include-exclude-select-filter-container">
                <ssi-list-select2
                  name="tag-toggle"
                  class="{{filter.key}}-selector"
                  [options]="filter.extraModelOptions"
                  [(ngModel)]="filter.extraModelValue"
                  (ngModelChange)="onFilterModelValueChange($event, filter)"
                >
                </ssi-list-select2>
                <label>Include tags</label>
                <ssi-tags-select-create class="{{filter.key}}-selector"
                                        [tagType]="'inbox'"
                                        [headButtonVariant]="'chips'"
                                        [placeholder]="'Select tags to include...'"
                                        [selectedTags]="filter.modelValue.include"
                                        (selectedTagsChange)="filter.modelValue.include = $event; onFilterModelValueChange($event, filter);"></ssi-tags-select-create>

                <label>Exclude tags</label>
                <ssi-tags-select-create class="{{filter.key}}-selector"
                                        [tagType]="'inbox'"
                                        [headButtonVariant]="'chips'"
                                        [placeholder]="'Select tags to exclude...'"
                                        [selectedTags]="filter.modelValue.exclude"
                                        (selectedTagsChange)="filter.modelValue.exclude = $event; onFilterModelValueChange($event, filter);"></ssi-tags-select-create>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="FilterType.InputSearch">
              <div class="input-search-container">
                <input type="search"
                       class="g-input"
                       placeholder="{{filter.placeholder}}"
                       [(ngModel)]="filter.modelValue"
                       (ngModelChange)="onFilterModelValueChange($event, filter);">

                <i class="ssi {{filter.icon}} loupe-icon"></i>
              </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
            </ng-container>

          </div>
        </div>
      </div>

    </div>

    <div class="foot-section"
         *ngIf="standalone">
      <button type="button"
              class="g-btn g-btn-secondary bg-red restore-btn"
              *ngIf="activeFilters.length"
              (click)="clearFilters()">Clear filters</button>

      <button type="button"
              class="g-btn g-btn-secondary bg-white cancel-btn"
              (click)="hide()">Cancel</button>
      <button type="button"
              class="g-btn apply-btn"
              [disabled]="!appliedModelsChanged"
              (click)="apply($event)">Apply filters</button>
    </div>
  </div>
</div>

<ng-template #proTipModal
             let-close="close">
  <button type="button"
          class="modal-close-button"
          (click)="close()">
    <i class="ssi ssi-addsmall"></i>
  </button>
  <div class="pro-modal-header">
    <h2 class="pro-modal-title">Advanced Search Pro Tips</h2>
    <p>Drill down through the noise and make your results as refined and focused as ever. The advanced search uses boolean operators to allow more complex filters to be run against your Insights data.
      Here's a little cheat sheet to help guide you when you're building a boolean search query:</p>
  </div>
  <div class="pro-modal-body">
    <div class="pro-modal-body-well">
      <h3>Your cheatsheet</h3>
      <div class="pro-modal-body-content">
        <dl class="pro-modal-body-legend">
          <dt>
            <span class="pro-modal-body-key pro-modal-body-key-yellow">cat dog mat</span>
          </dt>
          <dd>If you're looking for combinations of words, leave a space between each one. This example will return results that include <strong>all of these words</strong></dd>
          <dt>
            <span class="pro-modal-body-key pro-modal-body-key-green">“the cat sat on the mat”</span>
          </dt>
          <dd>For results that contain an exact word or phrase, place speech marks ( “” ) either side. This example will return results that include <strong>the exact phrase</strong></dd>
          <dt>
            <span class="pro-modal-body-key pro-modal-body-key-blue">cat | dog</span>
          </dt>
          <dd>To search for results that contain one word or another, use the pipe ( | ) symbol. This example will return <strong>any results that contain either word</strong></dd>
          <dt>
            <span class="pro-modal-body-key pro-modal-body-key-orange">cat -dog -mat</span>
          </dt>
          <dd>To remove results that contain a certain word, use the ( - ) minus symbol just before the world you want to filter out. This example will return results that contain cat, <strong>but
              don't contain dog or mat</strong></dd>
        </dl>
        <img src="https://www.orlo.app/app/web/production/e993c7845534372477d9ee154c59728e.png"
             alt="person taking notes">
      </div>
    </div>
  </div>
</ng-template>