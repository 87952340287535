<ul
  class="message-box-buttons"
  [ngClass]="{'message-box-buttons-selfpenned': selfPenned}"
>
  <li
    *ngIf="isInbox && !selfPenned && !activity?.inbox.priority"
    cdkOverlayOrigin
    #priorityActionTrigger="cdkOverlayOrigin"
    [ssiTooltip]="'Set Priority'"
    tooltipWindowClass="tooltip-chunky"
    tooltipPlacement="bottom"
    class="message-box-message-action"
    (click)="
          $event.stopPropagation();
          showActionsChange.emit({
            showActions: true,
            origin: priorityActionTrigger,
            activeAction: 'priority'
          })"
  >
    <i class="message-box-message-action-icon ssi ssi-priority "></i>
  </li>
  <li
    *ngIf="isInbox && !selfPenned && activity?.inbox.priority"
    cdkOverlayOrigin
    #priorityActionTrigger="cdkOverlayOrigin"
    class="message-box-message-action"
    (click)="
        $event.stopPropagation();
        showActionsChange.emit({
          showActions: true,
          origin: priorityActionTrigger,
          activeAction: 'priority'
        })"
  >
    <ng-container>
      <ssi-inbox-priority-indicator
        [ssiTooltip]="'Change Priority'"
        tooltipWindowClass="tooltip-chunky"
        tooltipPlacement="bottom"
        [priority]="activity?.inbox.priority"
        [type]="'badge'"
        [tooltipText]="activity?.inbox.priority ? 'Change Priority' : 'Set Priority'"
      ></ssi-inbox-priority-indicator>
    </ng-container>
  </li>

  <!-- <ng-container *ngIf="isInbox && !selfPenned && !account?.isYoutube()">
    <li
      *ngIf="sentiment === false"
      cdkOverlayOrigin
      #sentimentActionTrigger="cdkOverlayOrigin"
      class="message-box-message-action"
      (click)="
        $event.stopPropagation();
        showActionsChange.emit({
          showActions: true,
          origin: sentimentActionTrigger,
          activeAction: 'sentiment'
        })"
    >
      <i
        ssiTooltip="Amend sentiment"
        tooltipWindowClass="tooltip-chunky"
        tooltipPlacement="bottom"
        class="message-box-message-action-icon ssi ssi-add-sentiment"
      ></i>
    </li>
    <li
      *ngIf="sentiment || sentiment === 0"
      cdkOverlayOrigin
      #sentimentActionTrigger="cdkOverlayOrigin"
      class="message-box-message-action"
      (click)="
        $event.stopPropagation();
        showActionsChange.emit({
          showActions: true,
          origin: sentimentActionTrigger,
          activeAction: 'sentiment'
        })"
    >
      <ng-container *ngFor="let sentimentOption of sentimentOptions">
        <i
          *ngIf="sentiment === sentimentOption.score"
          [ssiTooltip]="sentimentOption.label"
          tooltipWindowClass="tooltip-chunky"
          tooltipPlacement="bottom"
          [ngClass]="[sentimentOption.icon, sentimentOption.class]"
          class="message-box-message-action-icon message-box-message-action-icon-custom-colour ssi"
        ></i>
      </ng-container>
    </li>
  </ng-container> -->
  <!-- <li
    *ngIf="isInbox && !selfPenned"
    cdkOverlayOrigin
    #tagsActionTrigger="cdkOverlayOrigin"
    (click)="
      $event.stopPropagation();
      showActionsChange.emit({
        showActions: true,
        origin: tagsActionTrigger,
        activeAction: 'tag'
      })"
    [ssiTooltip]="tagsTooltip || 'Add a tag'"
    tooltipWindowClass="tooltip-chunky"
    tooltipPlacement="bottom"
    class="message-box-message-action">
    <i class="message-box-message-action-icon ssi ssi-tag-test"></i>
    <span class="message-box-message-action-meta">
      {{(activeTags && activeTags.length > 0) ? activeTags.length : ''}}
    </span>
  </li> -->
  <li
    *ngIf="notes.display"
    class="message-box-message-action"
    cdkOverlayOrigin
    #notesActionTrigger="cdkOverlayOrigin"
    (click)="
      $event.stopPropagation();
      showActionsChange.emit({
        showActions: true,
        origin: notesActionTrigger,
        activeAction: 'notes'
      })"
    ssiTooltip="Add a note"
    tooltipWindowClass="tooltip-chunky"
    tooltipPlacement="bottom"
  >
    <i class="message-box-message-action-icon ssi ssi-orlo-note"></i>
  </li>
  <li
    *ngIf="showViewNatively"
    class="message-box-message-action"
    (click)="$event.stopPropagation(); viewNativelyClicked.emit()"
    ssiTooltip="View natively"
    tooltipWindowClass="tooltip-chunky"
    tooltipPlacement="bottom"
  >
    <ssi-icon
      class="message-box-message-action-icon"
      icon="viewNatively"
      height="14"
      width="14"
    ></ssi-icon>
  </li>
  <li
    *ngIf="showRedact"
    class="message-box-message-action"
    (click)="$event.stopPropagation(); redactMessage();"
    [ssiTooltip]="'Redact message'"
    tooltipWindowClass="tooltip-chunky"
    tooltipPlacement="bottom"
  >
    <i class="message-box-message-action-icon ssi ssi-redacted"></i>
  </li>
  <li
    *ngIf="activity?.hasSensitiveContent && activity?.sensitiveContentRevealed"
    class="message-box-message-action"
    (click)="$event.stopPropagation(); hideMedia(activity);"
    [ssiTooltip]="'Hide images'"
    tooltipWindowClass="tooltip-chunky"
    tooltipPlacement="bottom"
  >
    <i class="message-box-message-action-icon ssi ssi-hide-image"></i>
  </li>
  <li
    *ngIf="likeConfig.display"
    class="message-box-message-action"
    (click)="$event.stopPropagation(); toggleLikeClicked.emit()"
    [ssiTooltip]="likeConfig.status"
    tooltipWindowClass="tooltip-chunky"
    tooltipPlacement="bottom"
  >
    <i
      [ngClass]="likeConfig.icon"
      class="message-box-message-action-icon"
    ></i>
  </li>
  <li
    *ngIf="hideCommentConfig.display"
    class="message-box-message-action"
    (click)="$event.stopPropagation(); toggleShowHideCommentClicked.emit(!hideCommentConfig.status)"
    [ssiTooltip]="(hideCommentConfig.status ? 'Show' : 'Hide') + ' comment'"
    tooltipWindowClass="tooltip-chunky"
    tooltipPlacement="bottom"
  >
    <i class="message-box-message-action-icon ssi ssi-hidden"></i>
  </li>

  <li
    *ngIf="showConversationAssignTo && !assignedSuccess"
    class="message-box-message-action"
    (click)="$event.stopPropagation(); conversationAssignToClicked.emit()"
    ssiTooltip="Assign message"
    tooltipWindowClass="tooltip-chunky"
    tooltipPlacement="bottom"
  >
    <i class="message-box-message-action-icon ssi ssi-assign-correct"></i>
  </li>

  <li
    *ngIf="assignedSuccess"
    class="message-box-message-action message-box-message-action-primary"
    (click)="$event.stopPropagation(); conversationAssignToClicked.emit()"
    [ssiTooltip]="'Assigned to ' + (assignedSuccess.fullName || assignedSuccess.name)"
    tooltipWindowClass="tooltip-chunky"
    tooltipPlacement="bottom"
  >
    <i class="message-box-message-action-icon message-box-message-action-icon-custom-colour ssi ssi-request-approval"></i>
  </li>

  <li
    *ngIf="!isInbox && !activity.inbox.requires_action && !activity.interaction.deleted_at"
    class="message-box-message-action"
    (click)="$event.stopPropagation(); activity.pushToInbox()"
    [ssiTooltip]="'PUSH_TO_INBOX' | translate"
    tooltipWindowClass="tooltip-chunky"
    tooltipPlacement="bottom"
  >
    <i class="message-box-message-action-icon ssi ssi-forward"></i>
  </li>

  <li
    *ngIf="spamDetectionEnabled && !selfPenned"
    class="message-box-message-action"
    (click)="$event.stopPropagation(); markAsSpam()"
    [ssiTooltip]="activity?.markedAsSpam ? 'Unmark this message as Spam' : 'Mark this message as Spam'"
    tooltipWindowClass="tooltip-chunky"
    tooltipPlacement="bottom"
  >
    <i class="message-box-message-action-icon ssi ssi-inbox-spam spam-icon" [class.active]="activity?.markedAsSpam"></i>
  </li>

  <li
    *ngIf="showDelete"
    class="message-box-message-action"
    (click)="$event.stopPropagation(); deleteMenu.show($event);"
    [ssiTooltip]="activity.interaction.object_type === 'comment' ? 'Delete Comment' : 'Delete Post'"
    tooltipWindowClass="tooltip-chunky"
    tooltipPlacement="bottom"
  >
    <i class="message-box-message-action-icon ssi ssi-small-delete"></i>
  </li>

  <ng-container *ngIf="userCanApprove && !approvedActioned">
    <li class="message-box-message-action message-box-message-action-wide">
      <button
        (click)="$event.stopPropagation(); approvePost();"
        class="message-box-message-action-button message-box-message-action-button-primary"
      >Approve Reply</button>
    </li>
    <li class="message-box-message-action message-box-message-action-wide">
      <button
        class="message-box-message-action-button message-box-message-action-button-warning"
        (click)="$event.stopPropagation(); disapproveReply()"
      >Disapprove Reply</button>
    </li>
  </ng-container>

  <ng-container *ngIf="isConvertedToActivity && !userCanApprove && !approvedActioned">
    <li class="message-box-message-action message-box-message-action-wide message-box-message-action-tall">
      <p class="message-box-message-action-info">
        <i class="ssi ssi-total-approvals-requested"></i>
        <span>Awaiting approval from your Admin</span>
      </p>
    </li>
  </ng-container>

  <li
    *ngIf="activity?.isTempActivity && activity?.reply_requires_validation"
    class="message-box-message-action message-box-message-action-wide message-box-message-action-tall"
  >
    <p class="message-box-message-action-info">
      <i class="ssi ssi-total-approvals-requested"></i>
      <span>Sent for approval to your Admin</span>
    </p>
  </li>
</ul>

<ssi-context-menu #deleteMenu
                  (ssiOffClick)="deleteMenu.hide();">
  <ng-template let-ctx>
    <ssi-confirm-action title="Are you sure you want to delete this {{activity.interaction.object_type === 'comment' ? 'comment' : 'post'}}?"
                        dismissText="Cancel"
                        confirmText="Delete"
                        dismissTextColour="#43537f"
                        confirmTextColour="#F88C68"
                        (onDismiss)="deleteMenu.hide();"
                        (onConfirm)="deleteMenu.hide(); deleteClicked.emit();"></ssi-confirm-action>
  </ng-template>
</ssi-context-menu>

<ng-template #disapprovedSnackbarTemplate let-dismiss="dismiss">
  <div class="snackbar-container">
    <i class="ssi ssi-completed-notification"></i>
    <p>Post disapproved</p>
    <button (click)="dismiss()"><i class="ssi ssi-times"></i></button>
  </div>
</ng-template>

<ng-template #approvedSnackbarTemplate let-dismiss="dismiss">
  <div class="snackbar-container">
    <i class="ssi ssi-completed-notification"></i>
    <p>Post approved</p>
    <button (click)="dismiss()"><i class="ssi ssi-times"></i></button>
  </div>
</ng-template>
