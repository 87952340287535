import './drilldown-modal.component.scss';

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  Emotions,
  emotions,
  emotionsIterableSortedAZ,
  Sentiment,
  sentimentsIterable
} from '../../../../../../common/constants';
import {
  Colleague,
  MonitoringStreamsService,
  Team
} from '../../../../../../common/services/api';
import {
  InsightsPost,
  InsightsPostType,
  InsightsService
} from '../../../insights.service';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SpinnerComponent } from '../../../../../../common/components/spinner/spinner.component';
import { interval, merge, Subject, Subscription, throwError } from 'rxjs';
import { SocketEventManagerService } from '../../../../../../common/services/sockets/socket-event-manager.service';
import { AssignMessageComponent } from '../../../../../../common/components/assign-message/assign-message.component';
import { Option } from '../../../../../../common/components/dropdown-select-2/dropdown-select-2.component';
import { ActivityTags } from '@ui-resources-angular';
import { NotificationService } from '../../../../../../common/services/notification/notification.service';
import { format, parse } from 'date-fns';

export enum ClusteringStatusSource {
  clusteringDone = 'clusteringDone',
  clusteringFailed = 'clusteringFailed',
  apiPolling = 'apiPolling'
}

export interface ClusterDocument {
  id: number;
  size: number;
  doc_id: string; // '1_7d450c9c45b5b45c68b7be8f63f42576_a7dc8612602d39f907a5dc9cda7abf75';
  doc_index: string; // 'search_stream_result_v2';
  centroid_relative_location: Array<number>; // [-32.384490966796875, -73.25836181640625];
  min_similarity: number; // 0.6;
  aggs: Array<any>;
  content: string; // 'RT @NcubeBokang: officially a Hospice Companion volunteer💗 https://t.co/us36MaQrNy';
  members: Array<any>; // [];
  openai_theme: string; // 'Compassionate volunteering.';
}

export function applyEllipsis() {
  const series = this.series[0];
  const options = series.options.dataLabels;

  series.points.forEach((p) => {
    const label = p.dataLabel;
    const bbox = label.getBBox(true);
    const dlBox = p.dlBox || p.shapeArgs;
    label.css({
      width: dlBox.width,
      textOverflow: options.style.textOverflow
    });
    label.align(
      {
        width: bbox.width,
        height: bbox.height,
        align: options.align,
        verticalAlign: options.verticalAlign
      },
      null,
      p.dlBox
    );

    setTimeout(() => {
      // so highcharts can recalculate width and height
      window.dispatchEvent(new Event('resize'));
    }, 200);
  });
}

export const highchartColors = [
  '#14BAE3',
  '#D41D68',
  '#B2C614',
  '#425DEC',
  '#F88C68',
  '#F0B427',
  '#85A7FF',
  '#12ACA4',
  '#FFA7D1',
  '#FF0D0D'
];

@Component({
  selector: 'ssi-drilldown-modal',
  templateUrl: './drilldown-modal.component.html',
  styles: []
})
export class DrilldownModalComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('clustersLoadingSpinner') clustersLoadingSpinner: SpinnerComponent;

  @Input() selectedFilters: any;
  @Input() clusterId: number;
  @Input() jobTicket: string;
  @Input() widgetFilters: any;
  @Input() globalFilters: any;
  @Input() streamIds: string[];
  @Input() displayApplyFilters = false;
  @Input() enableThemeClusters = true;
  @Input() point: string;
  @Input() annotations;
  @Input() templateMode = false;

  @Output() onApplyFilters = new EventEmitter();
  @Output() updateAnnotations = new EventEmitter();

  clusterFilters: any;
  globalAndWidgetFilters: any;
  activeStreamIds: string[];
  activeFilters: any; // widgetFilters and globalFilters merged

  posts: InsightsPost[] = [];
  clusterPosts: InsightsPost[] = [];
  postsToRender: InsightsPost[] = [];
  clusterPostsToRender: InsightsPost[] = [];
  ticket: string;

  clusterData: Array<ClusterDocument>;

  pollingDocumentStatus$: Subscription;
  clusteringDoneSubscription$: Subscription;
  clusteringFailedSubscription$: Subscription;
  documentReady$ = new Subject();
  onDestroy = new Subject();

  activeTab: 'results' | 'clusters' | 'annotations' = 'results';
  querySubmitted;
  clusteringJobFailed = false;
  loadingClusters;
  loadingPosts;
  sentimentsConstIterable: Sentiment[] = sentimentsIterable;

  selectedPosts: InsightsPost[] = [];
  tags: Option[] = [];
  selectedTags: Option[] = [];
  selectedEmotions: Option[] = [];
  emotions: Emotions = emotions;
  emotionsSorted = emotionsIterableSortedAZ.map(
    ({ icon, ...keepAttrs }) => keepAttrs
  );
  showSentimentMenu = false;
  disableBulkEmotions = false;
  disableBulkDelete = false;
  bulkSentimentUpdate: number;
  containerWidth: number;
  annotationTitleMaxLength = 65;
  annotationModel = {
    datetime: '',
    title: '',
    description: ''
  };
  annotationFormOpen = false;
  currentAnnotationPoint = '';

  constructor(
    private ngZone: NgZone,
    public modalInstance: NgbActiveModal,
    public modal: NgbModal,
    private insightsService: InsightsService,
    private monitoringStreamsService: MonitoringStreamsService,
    private socketEventManagerService: SocketEventManagerService,
    private notificationService: NotificationService,
    protected activityTags: ActivityTags
  ) {}

  ngOnInit() {
    this.getFilters();
    // console.log('filters: ', this.activeFilters);

    if (this.point) {
      const parseDMY = (s) => {
        const [d, m, y] = s.split(/\D/);
        return new Date(y, m - 1, d);
      };

      this.annotationModel.datetime = this.formatDate(parseDMY(this.point));
      this.currentAnnotationPoint = this.annotationModel.datetime;

      this.annotations = this.annotations.map((annotation) => {
        annotation.datetime = this.formatDate(annotation.datetime);
        return annotation;
      });
    }
  }

  async getFilters() {
    this.activeFilters = [
      ...(this.insightsService.relabelDataModelForApi(this.selectedFilters) ||
        []),
      ...(this.globalFilters || []),
      ...this.widgetFilters
    ];
  }

  changeTab(tabName: 'results' | 'clusters' | 'annotations') {
    this.activeTab = tabName;
    if (this.activeTab === 'clusters' && !this.querySubmitted) {
      this.getClustersData();
    }
  }

  private async getClustersData() {
    this.querySubmitted = true;
    this.loadingClusters = true;
    this.getFilters();
    console.log('this.globalFilters:', this.globalFilters);
    this.activeStreamIds =
      (await this.insightsService.convertSavedStreamFilters(
        this.globalFilters
      )) || this.streamIds;

    const {
      success,
      ticket: myQueryTicket
    } = await this.insightsService.getInsightsCluster(
      this.activeFilters,
      this.activeStreamIds
    );
    this.querySubmitted = success;

    if (!this.querySubmitted) {
      return;
    }

    const stopConditions$ = merge(this.documentReady$, this.onDestroy).pipe(
      tap((s) => console.log('Stop checking for clusters', s))
    );

    const clusteringObservables = merge(
      this.socketEventManagerService.clusteringDone.pipe(
        map((data) => ({ source: ClusteringStatusSource.clusteringDone, data }))
      ),
      this.socketEventManagerService.clusteringFailed.pipe(
        map((data) => ({
          source: ClusteringStatusSource.clusteringFailed,
          data
        }))
      ),
      interval(5000).pipe(
        takeUntil(stopConditions$),
        startWith(0),
        switchMap(() => {
          console.log('Polling the ticket:', myQueryTicket);
          return this.insightsService.getInsightsClusterDocumentStatus(
            myQueryTicket
          );
        }),
        map((data) => ({ source: ClusteringStatusSource.apiPolling, data }))
      )
    );

    // this.clusteringDoneSubscription$ = this.socketEventManagerService.clusteringDone

    this.pollingDocumentStatus$ = clusteringObservables.subscribe(
      async (res) => {
        console.log('Document ready response:', res);
        if (
          res.source === ClusteringStatusSource.apiPolling &&
          !res.data.completed
        ) {
          return;
        }

        if (res.source === ClusteringStatusSource.clusteringFailed) {
          return throwError('WebSockets sent clusteringFailed event');
        }

        this.documentReady$.next();
        const clusterDocument: any[] = await this.insightsService.getInsightsClusterDocument(
          res.data.ticket
        );

        console.log('Document ready clusterDocument:', clusterDocument);
        this.clusterData = clusterDocument.slice(0, 5);
        this.ngZone.run(() => {
          this.loadingClusters = false;
        });

        this.formatDrilldownData();
      },
      (err) => {
        console.error('Error polling document status:', err);

        this.ngZone.run(() => {
          this.clusteringJobFailed = true;
        });
      }
    );
  }

  private formatDrilldownData(): void {
    // console.log('this.heatmapClustersData:', this.heatmapClustersData);
  }

  selectClusterContent(content: string) {
    const filter: any = {
      field: 'Content Vector',
      semantic_search: {
        query: content,
        min_score: 0.6
      }
    };
    console.log('this.globalFilters:', this.globalFilters);

    // ssi-insights-posts component is listening for clusterFilters input changes and re-loads accordingly
    this.clusterFilters = [filter, ...this.activeFilters];
  }

  onPostsPageChange(opts: any): void {
    this.postsToRender = this.posts.slice(opts.startIndex, opts.endIndex);
  }

  onClusterPostsPageChange(opts: any): void {
    this.clusterPostsToRender = this.clusterPosts.slice(
      opts.startIndex,
      opts.endIndex
    );
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy.next();

    if (this.pollingDocumentStatus$) {
      this.pollingDocumentStatus$.unsubscribe();
    }
  }

  applyFilter(): void {
    console.log(
      this.insightsService.relabelDataModelForApi(this.selectedFilters)
    );
    this.onApplyFilters.emit(
      this.insightsService.relabelDataModelForApi(this.selectedFilters)
    );
    this.modalInstance.dismiss();
  }

  onPostSelectToggled(post: InsightsPost): void {
    if (this.isPostSelected(post)) {
      this.selectedPosts.splice(this.selectedPosts.indexOf(post), 1);

      let foundUnannotatedPost = false;
      let foundActivityPost = false;

      for (const selectedPost of this.selectedPosts) {
        if (selectedPost.data.insights === undefined) {
          foundUnannotatedPost = true;
        }

        if (selectedPost.type === InsightsPostType.Activity) {
          foundActivityPost = true;
        }
      }

      if (!foundUnannotatedPost) {
        this.disableBulkEmotions = false;
      }
      if (!foundActivityPost) {
        this.disableBulkDelete = false;
      }
    } else {
      if (!post.data.insights) {
        this.disableBulkEmotions = true;
      }

      if (post.type === InsightsPostType.Activity) {
        this.disableBulkDelete = true;
      }
      this.selectedPosts.push(post);
    }
  }

  isPostSelected(post: InsightsPost): boolean {
    return this.selectedPosts.indexOf(post) > -1;
  }

  clearSelectedPosts() {
    this.selectedPosts.splice(0, this.selectedPosts.length);
  }

  assignPost(): void {
    const selectedPostCount = this.selectedPosts.length;
    const modal = this.modal.open(AssignMessageComponent, {
      windowClass: 'rounded-corners-40',
      centered: true
    });
    modal.componentInstance.selectedInsightsResults = this.selectedPosts;
    modal.componentInstance.isMonitoringActivity = true;

    modal.componentInstance.onAssigned.subscribe(
      (assignedUserOrTeam: Colleague | Team) => {
        const userOrTeamName =
          assignedUserOrTeam instanceof Colleague
            ? assignedUserOrTeam.fullName
            : assignedUserOrTeam.name;

        this.notificationService.open(
          `${selectedPostCount} post results have been assigned to ${userOrTeamName}`,
          {
            class: 'ssi ssi-completed-notification',
            color: '#B2C614'
          },
          5000
        );

        this.clearSelectedPosts();
        setTimeout(() => {
          this.getFilters();
        }, 1500);
      }
    );
  }

  populateTags(): void {
    this.activityTags.getTags().then((tags: string[]) => {
      this.tags = tags.map((tag) => {
        return {
          id: tag,
          label: tag
        };
      });
    });
  }

  updateTags(selectedTags: Option[]): void {
    for (const item of this.selectedPosts) {
      item.data.setTags(
        this.selectedTags.map((t) => t.id),
        item.type === InsightsPostType.Monitoring
      );
    }
  }

  changeSentiment(sentiment: number) {
    this.bulkSentimentUpdate = sentiment;
  }

  updateEmotions(selectedEmotions: Option[]): void {
    for (const item of this.selectedPosts) {
      item.data.updateEmotions(
        this.selectedEmotions.map((e) => e.key),
        item.type === InsightsPostType.Monitoring
      );
    }
  }

  deletePosts() {
    if (this.disableBulkDelete) {
      return;
    }

    let resultsDeleted = 0;
    for (const item of this.selectedPosts) {
      if (item.type === InsightsPostType.Monitoring) {
        item.data.remove(item.type === InsightsPostType.Monitoring);
        resultsDeleted++;
      }
    }

    this.notificationService.open(
      `${resultsDeleted} post results have been deleted`,
      {
        class: 'ssi ssi-completed-notification',
        color: '#B2C614'
      },
      5000
    );

    this.clearSelectedPosts();
    setTimeout(() => {
      this.getFilters();
    }, 1500);
  }

  onContainerWidthChange(width: string): void {
    this.containerWidth = parseInt(width, 10);
  }

  saveAnnotation() {
    const index = this.annotations.findIndex(
      (annotation) => annotation.datetime === this.annotationModel.datetime
    );
    if (index !== -1) {
      this.annotations[index] = this.annotationModel;
    } else {
      this.annotations.push(this.annotationModel);
    }
    this.updateAnnotations.emit(this.annotations);
    this.annotationFormOpen = false;
    this.annotationModel = {
      datetime: this.currentAnnotationPoint,
      title: '',
      description: ''
    };
  }

  formatDate(date) {
    return format(date, 'dddd DD MMMM YYYY', { locale: 'en' });
  }

  editAnnotation(annotation) {
    this.annotationModel = Object.assign({}, annotation);
    this.annotationFormOpen = true;
  }

  deleteAnnotation(datetime) {
    const index = this.annotations.findIndex(
      (annotation) => annotation.datetime === datetime
    );
    this.annotations.splice(index, 1);
    this.updateAnnotations.emit(this.annotations);
  }
}
