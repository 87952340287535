<div class="stream-builder-nav">
  <div class="back">
    <a uiSref="^.query"><i class="ssi ssi-line-arrow-small-up left"></i>{{ 'BACK' | translate }}</a>
    <span
      *ngIf="streamQuery.id"
      (click)="deleteMenu.hide(); deleteMenu.show($event, {streamQuery: streamQuery});"
      class="stream-builder-nav-action"
    >
      <i class="ssi ssi-delete"></i><span translate="DELETE_STREAM"></span>
    </span>
  </div>
  <div class="nav-list">
    <h3 class="active">{{ 'DESCRIPTION' | translate }}<i class="ssi ssi-tick"></i></h3>
    <div class="line active completed"></div>
    <h3 class="active">{{ 'BUILD_QUERY' | translate }}<i class="ssi ssi-tick"></i></h3>
    <div class="line active completed final"></div>
    <h3
      class="active"
      translate="SOURCES__SAVE"
    ></h3>
  </div>
  <div
    [class.disabled]="!streamQuery.sources.length && !streamQuery.listening_group_ids.length "
    class="next"
  >
    <orlo-button
      [state]="'Primary'"
      [text]="'SAVE_STREAM' | translate"
      (click)="saveStep()"
      [disabled]="!streamQuery.sources.length && !streamQuery.listening_group_ids.length"
      [textColor]="!streamQuery.sources.length && !streamQuery.listening_group_ids.length ? '#838EAB' : '#FFFFFF'"
      [bgColor]="!streamQuery.sources.length && !streamQuery.listening_group_ids.length ? '#F4F4FA' : '#0D827C'"
    ></orlo-button>
  </div>
</div>
<div class="step-content">

  <div class="row-content language-filtering">
    <div class="form-field">
      <div class="form-field">
        <h2 translate="SPECIFY_SOURCES"></h2>
        <p translate="MESSAGES_WILL_BE_COLLECTED_FROM_THESE_SOURCES"></p>
      </div>
      <div
        class="dropdown-container"
        *ngIf="sourcesIterable"
      >
        <label translate="INCLUDE_THESE_SOURCES"></label>
        <div class="input-wrapper">
          <ssi-multi-select-chips
            [chipsPlaceholder]="'SELECT_SOURCES_YOU_WANT_TO_GATHER_POST_RESULTS_FROM' | translate"
            [filterPlaceholder]="'SEARCH_FOR_SOURCES_TO_GATHER_POST_RESULTS_FROM' | translate"
            [selectedChips]="selectedStreamSources"
            [chipsOptions]="sourcesIterable"
            (onSelectedChipsChange)="onSelectedSourcesChange($event)"
          ></ssi-multi-select-chips>
        </div>
      </div>
      <div
        class="dropdown-container"
        *ngIf="socialListeningEnabled && listeningGroups"
      >
        <label translate="INCLUDE_THESE_FACEBOOK_LISTENING_GROUPS"></label>
        <div class="input-wrapper">
          <ssi-multi-select-chips
            [chipsPlaceholder]="'SELECT_SOURCES_YOU_WANT_TO_GATHER_POST_RESULTS_FROM' | translate"
            [filterPlaceholder]="'SEARCH_FOR_SOURCES_TO_GATHER_POST_RESULTS_FROM' | translate"
            [selectedChips]="selectedListeningGroups"
            [chipsOptions]="listeningGroups"
            (onSelectedChipsChange)="onSelectedListeningGroupChange($event)"
          ></ssi-multi-select-chips>
        </div>
      </div>
      <div
        class="dropdown-container"
        *ngIf="hashtagMonitoringEnabled && instagramHashtags"
      >
        <label translate="INCLUDE_THESE_INSTAGRAM_HASHTAGS"></label>
        <div class="input-wrapper">
          <ssi-multi-select-chips
            [chipsPlaceholder]="'SELECT_THE_HASHTAGS_YOU_WANT_TO_GATHER_POST_RESULTS_FROM' | translate"
            [filterPlaceholder]="'SEARCH_FOR_HASHTAGS_TO_GATHER_POST_RESULTS_FROM' | translate"
            [selectedChips]="selectedInstagramHashtags"
            [chipsOptions]="instagramHashtags"
            (onSelectedChipsChange)="onSelectedInstagramHashtagChange($event)"
          ></ssi-multi-select-chips>
        </div>
      </div>
      <div class="row-content toggle-source">
        <ssi-toggle-switch
          [(ngModel)]="twitterSource"
          (ngModelChange)="onTwitterSourceToggle($event)"
          [label]="'INCLUDE_X_AS_A_SOURCE' | translate"
          toggleText="X source {{ twitterSource ? 'on' : 'off'}}"
        ></ssi-toggle-switch>

        <ssi-rounded-box-check
          (click)="includeRetweets = true"
          [selected]="includeRetweets && twitterSource"
          [borderedInital]="true"
          [suggestiveCheck]="includeRetweets"
          [text]="'INCLUDE_REPOSTS' | translate"
          borderColor="#14BAE3"
          bgColor="#F8F9FD"
        > </ssi-rounded-box-check>
        <ssi-rounded-box-check
          (click)="includeRetweets = false"
          [selected]="!includeRetweets"
          [text]="'NO_REPOSTS' | translate"
          borderColor="#14BAE3"
          bgColor="#F8F9FD"
        ></ssi-rounded-box-check>
      </div>
    </div>
  </div>

  <hr>

  <div class="row-content language-filtering">
    <div class="form-field">
      <h2 translate="SELECT_LANGUAGE"></h2>
      <p translate="POST_RESULTS_WILL_BE_COLLECTED_IN_THE_LANGUAGES_YOU_CHOOSE"></p>
      <div class="dropdown-container">
        <label translate="LANGUAGES"></label>
        <div class="input-wrapper">
          <ssi-dropdown-select-2
            name="languages"
            class="languages-selector"
            headButtonStyleVariant="analytics"
            size="lg"
            [required]="true"
            [filterable]="true"
            [filterPlaceholder]="'Search for a language...'"
            [options]="languages"
            [(ngModel)]="selectedLanguage"
            (ngModelChange)="onSelectedLanguageChange($event)"
          >
            <ng-template
              ssiTemplateSelector="headButtonLabel"
              let-expanded="expanded"
            >
              <div
                class="select-language-placeholder"
                *ngIf="!selectedLanguage"
              >
                <span translate="ANY_LANGUAGE"></span>
              </div>
              <div
                class="select-language-label"
                *ngIf="selectedLanguage"
              >
                <span>{{selectedLanguage.label}}</span>
              </div>
            </ng-template>
          </ssi-dropdown-select-2>
        </div>
      </div>
    </div>
  </div>
</div>

<ssi-context-menu
  #deleteMenu
  (ssiOffClick)="deleteMenu.hide();"
>
  <ng-template let-ctx>
    <ssi-confirm-action
      title="Are you sure you want to delete?"
      dismissText="Cancel"
      confirmText="Delete"
      dismissTextColour="#43537f"
      confirmTextColour="#F88C68"
      (onDismiss)="deleteMenu.hide();"
      (onConfirm)="deleteMenu.hide(); deleteStream(ctx.streamQuery);"
    ></ssi-confirm-action>
  </ng-template>
</ssi-context-menu>