import './social-network-icon.component.scss';

import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';

import { AccountTypeId, SocialType } from '../../enums';

@Component({
  selector: 'ssi-social-network-icon',
  templateUrl: './social-network-icon.component.html',
  styles: []
})
export class SocialNetworkIconComponent implements OnInit, OnChanges {
  @Input() accountTypeId?: AccountTypeId | string; // string can be passed too
  @Input() socialType?: SocialType;
  @Input() variant: 'svg' | 'fa' = 'svg';
  @Input() size = 50; // for svg
  @Input() color = ''; // for fa

  AccountTypeId = AccountTypeId;
  SocialType = SocialType;

  constructor() {}

  ngOnInit() {
    this.accountTypeId = Number(this.accountTypeId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.accountTypeId = Number(this.accountTypeId);
  }

  isTwitter(): boolean {
    return (
      this.accountTypeId === AccountTypeId.Twitter ||
      this.accountTypeId === AccountTypeId.TwitterAds ||
      this.socialType === SocialType.Twitter
    );
  }

  isFacebook(): boolean {
    return (
      this.accountTypeId === AccountTypeId.Facebook ||
      this.accountTypeId === AccountTypeId.FacebookGroup ||
      this.socialType === SocialType.Facebook
    );
  }

  isLinkedIn(): boolean {
    return (
      this.accountTypeId === AccountTypeId.LinkedIn ||
      this.accountTypeId === AccountTypeId.LinkedInAds ||
      this.socialType === SocialType.Linkedin
    );
  }

  isYouTube(): boolean {
    return (
      this.accountTypeId === AccountTypeId.YouTube ||
      this.socialType === SocialType.Youtube
    );
  }

  isInstagram(): boolean {
    return (
      this.accountTypeId === AccountTypeId.Instagram ||
      this.socialType === SocialType.Instagram ||
      this.socialType === SocialType.InstagramGraph
    );
  }

  isLiveChat(): boolean {
    return (
      this.accountTypeId === AccountTypeId.LiveChat ||
      this.socialType === SocialType.Livechat
    );
  }

  isWhatsApp(): boolean {
    return (
      this.accountTypeId === AccountTypeId.TwilioWhatsApp ||
      this.socialType === SocialType.Whatsapp
    );
  }

  isSMS(): boolean {
    return (
      this.accountTypeId === AccountTypeId.TwilioSMS ||
      this.socialType === SocialType.Sms
    );
  }

  isTikTok(): boolean {
    return (
      this.accountTypeId === AccountTypeId.TikTok ||
      this.socialType === SocialType.Tiktok
    );
  }

  isReviewTrackers(): boolean {
    return (
      this.accountTypeId === AccountTypeId.ReviewTrackers ||
      this.socialType === SocialType.ReviewTrackers
    );
  }

  isMeta(): boolean {
    return this.accountTypeId === AccountTypeId.FacebookAds;
  }

  isNextDoor(): boolean {
    return (
      this.accountTypeId === AccountTypeId.Nextdoor ||
      this.socialType === SocialType.Nextdoor
    );
  }

  isTikTokBusiness(): boolean {
    return (
      this.accountTypeId === AccountTypeId.TikTokBusiness ||
      this.socialType === SocialType.TiktokBusiness
    );
  }

  isNextDoorUS(): boolean {
    return (
      this.accountTypeId === AccountTypeId.NextdoorUS ||
      this.socialType === SocialType.NextdoorUS
    );
  }

  isThreads(): boolean {
    return (
      this.accountTypeId === AccountTypeId.Threads ||
      this.socialType === SocialType.Threads
    );
  }
}
