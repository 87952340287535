import './highchart.component.scss';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  EventEmitter,
  Output,
  ChangeDetectorRef
} from '@angular/core';

async function loadHighcharts() {
  const highcharts = await import('highcharts');
  const [
    heatmap,
    exporting,
    noDataToDisplay,
    sankey,
    wordcloud,
    treemap,
    AnnotationsModule
  ]: any[] = await Promise.all([
    import('highcharts/modules/heatmap'),
    import('highcharts/modules/exporting'),
    import('highcharts/modules/no-data-to-display'),
    import('highcharts/modules/sankey'),
    import('highcharts/modules/wordcloud'),
    import('highcharts/modules/treemap'),
    import('highcharts/modules/annotations')
  ]);

  heatmap.default(highcharts);
  exporting.default(highcharts);
  noDataToDisplay.default(highcharts);
  sankey.default(highcharts);
  wordcloud.default(highcharts);
  treemap.default(highcharts);
  AnnotationsModule.default(highcharts);

  return highcharts;
}

@Component({
  selector: 'ssi-highchart',
  templateUrl: './highchart.component.html'
})
export class HighchartComponent implements OnInit, OnChanges {
  @Input() config;
  @Input() theme: string;
  @Input() updateFlag: boolean = false;
  @Output() highchartsLoaded = new EventEmitter();
  @Output() chartInstance = new EventEmitter();

  @Output()
  updateFlagChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  highcharts;
  chartsLoaded = false;
  rerendering = false;

  constructor(private cdRef: ChangeDetectorRef) {}

  public get hasConfigData() {
    return (
      !!this.chartsLoaded &&
      !!this.config &&
      !!this.config.series &&
      Array.isArray(this.config.series) &&
      !!this.config.series.length &&
      !!this.config.series[0].data
    );
  }

  async ngOnInit() {
    this.highcharts = await loadHighcharts();
    this.chartsLoaded = true;
    this.highchartsLoaded.emit();
  }

  ngOnChanges(changes) {
    if (changes.updateFlag) {
      this.updateFlagChange.emit(false);
    }
    if (changes.config) {
      this.rerender();
    }
  }

  rerender(): void {
    // Adding this since refresh/updateFlag mechanism wasn't working in some cases (e.g. if some of the values provided to highcharts don't change?)
    // Seems like a highcharts bug or bug in the highcharts wrapper component for Angular we're using?
    // See CT-1940 - issue #4 from the ticket description, or CT-1943
    // TODO: do we need to replace all 'updateFlag' implementations with this one?
    this.rerendering = true;
    this.cdRef.detectChanges();
    this.rerendering = false;
  }

  refresh(): void {
    this.updateFlag = true;
  }
}
