import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Store } from '../store';
import { ApiService } from '../api.service';
import { Colleague } from './colleague.model';
import { ColleaguesStore } from './colleagues.store';

@Injectable({ providedIn: 'root' })
export class ColleaguesService {
  store = new ColleaguesStore();
  endpoint = `${this.api.url}/user/colleagues`;

  constructor(protected api: ApiService) {}

  getAll(opts = { refreshStore: false }): Promise<Colleague[]> {
    if (this.store.value.length && !opts.refreshStore) {
      return Promise.resolve(this.store.value);
    }

    return this.api
      .get(this.endpoint)
      .pipe(
        map((response: any) => {
          this.store.value = Object.values(response);
          return this.store.value;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  async getAllActive(): Promise<Colleague[]> {
    const colleagues = await this.getAll();
    return colleagues.filter((c) => c.is_active);
  }

  async findOneById(id: string): Promise<Colleague> {
    const colleagues = await this.getAll();
    return colleagues.filter((c) => c.id === id)[0];
  }
}
