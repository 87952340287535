import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import {
  Activity,
  ActivityModel,
  AccountModel,
  socialNetworkSettings,
  Account
} from '@ui-resources-angular';
import { WidgetType, WidgetTypes, widgetTypes } from './common/constants/';
import {
  FieldName,
  FieldType,
  AggregateFunction,
  DefinedField
} from './common/constants/filters-field-definitions';
import {
  ApiService,
  MonitoringStream,
  MonitoringStreamsService
} from '../../../common/services/api';
import { Filter } from './reports/view/view-report.component';
import {
  LANGUAGES,
  TIMEZONE_OFFSET,
  emotions,
  emotionsIterable
} from '../../../common/constants';
import { WorkflowManagerService } from '../../../common/services/workflow-manager/workflow-manager.service';
import {
  sentiments,
  findSentimentConst
} from '../../../common/constants/sentiments';
import { ageOptions } from '../../../common/constants/age-options';
import {
  mapToIterable,
  getKeyByValue,
  sumObjectsByKey
} from '../../../common/utils';
import { SocialType } from '../../../common/enums';
import moment from 'moment';
import { C } from '@angular/cdk/keycodes';

export enum LocationTier {
  Country = 'Country',
  State = 'State',
  City = 'City',
  Locality = 'Locality'
}

export interface Schema {
  fields: DefinedField[];
}

type NonEmptyArray<T> = [T, ...T[]];

interface Dimension {
  field: FieldName;
}

export interface Measure {
  field: FieldName;
  function: AggregateFunction;
}

type OptionalDimension = Dimension | null;
type OptionalMeasure = Measure | null;

export interface BarChart extends InsightsWidget {
  type: 'bar';
  x_axis: Dimension;
  break_down_by: OptionalDimension;
  y_axis: OptionalMeasure;
}

export interface Donut extends InsightsWidget {
  type: 'donut';
  break_down_by: Dimension;
  value: OptionalMeasure;
}

export interface KPI extends InsightsWidget {
  type: 'kpi';
  group_by: OptionalDimension;
  values: Measure[]; // 0 or more
}

export interface Pie extends InsightsWidget {
  type: 'pie';
  break_down_by: Dimension;
  value: OptionalMeasure;
}

export interface Table extends InsightsWidget {
  type: 'table';
  columns: NonEmptyArray<Dimension | Measure>; // min 1
}

export interface LineChart extends InsightsWidget {
  type: 'line';
  x_axis: Dimension;
  y_axis: OptionalMeasure;
}

export interface SentimentGauge extends InsightsWidget {
  type: 'sentiment';
}
// -2,-1,0,1,2

export interface Big extends InsightsWidget {
  type: 'big';
  categories: { label: string; filters: Filter[] }[];
  break_down_by: Dimension;
  values: Measure[]; // 0 or more
}

export interface Swot {
  type: 'swot';
}

export interface TopInterests {
  type: 'interests';
}

export interface TopIndustries {
  type: 'industries';
}

export interface ThemeCloud {
  type: 'theme_cloud';
}

export type DataWidget =
  | BarChart
  | Donut
  | KPI
  | Pie
  | Table
  | LineChart
  | SentimentGauge
  | Big
  | Swot
  | TopInterests
  | TopIndustries
  | ThemeCloud;

export interface InsightsWidget {
  type: keyof WidgetTypes;
  name: string;
  description: string;
  break_down_by: Dimension;
  display_properties: {
    x: number;
    y: number;
    w: number;
    h: number;
    annotations?: {
      datetime: string;
      title: string;
      description: string;
    }[];
  };
  filters: WidgetFilter[];
  typeConst: WidgetType;
}

export interface WidgetFilter {
  field: string;
  eq: boolean;
}

export interface InsightsReport {
  account_ids?: number[];
  id?: string;
  name: string;
  created_by?: string;
  is_shared: boolean;
  created_at?: string;
  updated_at?: string;
  widgets?: InsightsWidget[];
  filters?: Filter[]; // used on updateReport (Save report)
  search_stream_ids?: string[];
}

export enum InsightsPostType {
  Activity = 'activity',
  Monitoring = 'monitoring'
}

export interface InsightsPost {
  type: InsightsPostType;
  data: Activity; // monitoring results are instantiated from ActivityModel as well
}

export interface InsightsSearchResponse {
  results: InsightsPost[];
  next: {
    offset: number;
  };
}

const AgeValueKey = {
  '<=18': 0,
  '19-29': 19,
  '30-39': 30,
  '>=40': 40
};

const AgeValueReadableKey = {
  '18 & below': 0,
  '19 to 29': 19,
  '30 to 39': 30,
  '40 & above': 40
};

export const sanitizeFiltersForAPI = (filters) => {
  // Rework so logic is base off eq/all ect..
  return filters.map((filterObj) => {
    if (filterObj.key === 'isPrivate') {
      const eq = filterObj.all === 'Private results' ? true : false;
      const { label, key, all, ...filterNew } = filterObj;
      filterNew['field'] = 'Is Private';
      filterNew['eq'] = eq;
      return filterNew;
    } else if (filterObj.key === 'responseTime') {
      const { label, key, all, ...filterNew } = filterObj;
      return filterNew;
    } else if (filterObj.key === 'sentiment') {
      const sentimentConst = findSentimentConst('key2', filterObj.all);
      const { label, key, all, ...filterNew } = filterObj;
      filterNew['in'] = sentimentConst.numericKey;
      return filterNew;
    } else if (filterObj.key === 'keyword') {
      const { label, key, all, match, query, ...filterNew } = filterObj;
      filterNew['field'] = 'Content';
      filterNew['match'] = filterObj.match;
      filterNew['query'] = filterObj.query;
      return filterNew;
    } else if (filterObj.key === 'author') {
      const { label, key, all, ...filterNew } = filterObj;
      filterNew['field'] = 'Author Name';
      return filterNew;
    } else {
      const { label, key, all, ...filterNew } = filterObj;
      if (filterObj.all) {
        filterNew['in'] = [filterObj.all];
      }
      return filterNew;
    }
  });
};

export const dedupeAndMergeFiltersForAPI = (filters) => {
  const fields = [...new Set(filters.map(({ field }) => field))];
  for (const field of fields) {
    if (
      filters
        .find((globalFilter) => globalFilter.field === field)
        .hasOwnProperty('in')
    ) {
      // take all 'in' filters of same field and merge to single filter
      const matchingFilters = filters.filter(
        (globalFilter) => globalFilter.field === field
      );
      const combinedFilter = { field, in: [] };
      for (const globalFilter of matchingFilters) {
        combinedFilter.in = combinedFilter.in.concat(globalFilter.in);
      }
      filters = filters.filter((globalFilter) => globalFilter.field !== field);

      filters.push(combinedFilter as Filter);
    } else if (
      filters
        .find((globalFilter) => globalFilter.field === field)
        .hasOwnProperty('all')
    ) {
      const matchingFilters = filters.filter(
        (globalFilter) => globalFilter.field === field
      );
      const combinedFilter = { field, all: [] };
      for (const globalFilter of matchingFilters) {
        combinedFilter.all = combinedFilter.all.concat(globalFilter.all);
      }
      filters = filters.filter((globalFilter) => globalFilter.field !== field);
      filters.push(combinedFilter as Filter);
    }
  }
  return filters;
};

export const monitoringResultsSocialTypes = {
  moreover: {
    sourceLabel: 'Press & Media',
    key: SocialType.Moreover
  },
  instagram_graph_hashtag: {
    sourceLabel: 'Instagram Hashtag Monitoring',
    key: SocialType.InstagramGraphHashtag
  }
};

export const networksWithInsightsSources: {
  sourceLabel: string;
  key: string;
}[] = mapToIterable(socialNetworkSettings)
  .map((network) => {
    return {
      sourceLabel: network.accountTypeLabel,
      key: network.publishKeyInsights
    };
  })
  .concat(
    monitoringResultsSocialTypes.moreover,
    monitoringResultsSocialTypes.instagram_graph_hashtag
  );

export interface InisightsClusterDocument {
  /**
   * It's going to be either activity or monitoring object, can't contain both
   */
  activity?: Activity;
  /**
   * It's going to be either activity or monitoring object, can't contain both
   */
  monitoring?: any; //
  content?: string;
  centroid_doc: { id: string; index: string };
  centroid_relative_location: number[];
  id: number;
  min_similarity: number;
  size: number;

  openai_theme?: string;
  aggs?: any[];
  doc_id?: string;
  doc_index?: string;
  members?: any[];
}

@Injectable({ providedIn: 'root' })
export class InsightsService {
  constructor(
    protected injector: Injector,
    protected api: ApiService,
    protected activityModel: ActivityModel,
    protected accountModel: AccountModel,
    private monitoringStreamsService: MonitoringStreamsService,
    private workflowManager: WorkflowManagerService
  ) {}

  getTemplateReports(): Promise<InsightsReport[]> {
    const endpoint = `${this.api.url}/insights/insightsTemplateReports `;

    return this.api
      .get(endpoint)
      .pipe(
        map((response: { reports: InsightsReport[] }) => {
          return response.reports;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  getReports(): Promise<InsightsReport[]> {
    const endpoint = `${this.api.url}/insights/insightsReport `;

    return this.api
      .get(endpoint)
      .pipe(
        map((response: { reports: InsightsReport[] }) => {
          return response.reports;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  getReport(id: string): Promise<InsightsReport> {
    const endpoint = `${this.api.url}/insights/insightsReport `;
    const opts = {
      params: {
        id
      }
    };

    return this.api
      .get(endpoint, opts)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  createReport(
    report: InsightsReport,
    workflowAccounts: Account[],
    streams: MonitoringStream[]
  ): Promise<InsightsReport> {
    const endpoint = `${this.api.url}/insights/insightsReport`;

    if (!Array.isArray(report.account_ids) || !report.account_ids.length) {
      // if no accounts selected then pass all accounts to the backend, see: CT-4933
      report.account_ids = workflowAccounts.map((a) => Number(a.id));
    }

    if (
      !Array.isArray(report.search_stream_ids) ||
      (!report.search_stream_ids.length && streams.length > 0)
    ) {
      report.search_stream_ids = streams.map(({ id }) => id);
    }

    return this.api
      .post(endpoint, report)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  updateReport(
    id: string,
    report: InsightsReport,
    workflowAccounts: Account[]
  ): Promise<InsightsReport> {
    console.log('updateReport:', report);
    const endpoint = `${this.api.url}/insights/insightsReport `;
    const opts = {
      params: {
        id
      }
    };

    // if (!Array.isArray(report.account_ids) || !report.account_ids.length) {
    //   // if no accounts selected then pass all accounts to the backend, see: CT-4933
    //   report.account_ids = workflowAccounts.map((a) => Number(a.id));
    // }

    return this.api
      .post(endpoint, report, opts)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  deleteReport(id: string) {
    const endpoint = `${this.api.url}/insights/insightsReport `;
    const opts = {
      params: {
        id
      }
    };

    return this.api
      .delete(endpoint, opts)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  async getClusteredPosts(
    jobTicket: string,
    clusterId: number,
    offset: number,
    limit: number
  ): Promise<InsightsSearchResponse> {
    const endpoint = `${this.api.url}/insights/insightsClusterDrilldown`;

    return this.api
      .get(endpoint, {
        params: {
          ticket: jobTicket,
          cluster_id: clusterId,
          offset,
          limit
        }
      })
      .pipe(
        map((response: any) => {
          response.results.forEach((post: InsightsPost) => {
            // instantiate monitoring results from ActivityModel too
            post.data = this.activityModel.inject(post.data);
          });

          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  async getPosts(
    globalFilters: Filter[] = [],
    streamIds: string[] = [],
    offset: number,
    limit: number
  ): Promise<InsightsSearchResponse> {
    const endpoint = `${this.api.url}/insights/insightsSearch`;
    // globalFilters = this.relabelDataModelForApi(globalFilters);

    let account_ids = [];
    let search_stream_ids = [];

    const hasAccountFilterApplied = globalFilters.find(
      ({ field }) => field === 'Inbox Accounts'
    );
    const hasSavedStreamFilterApplied = globalFilters.find(
      ({ field }) => field === 'Saved Streams'
    );

    if (hasAccountFilterApplied && !hasSavedStreamFilterApplied) {
      account_ids = await this.convertAccountFilters(globalFilters, true).then(
        (accounts) => accounts
      );
    } else if (!hasAccountFilterApplied && hasSavedStreamFilterApplied) {
      search_stream_ids = await this.convertSavedStreamFilters(
        globalFilters,
        true
      ).then((streams) => streams);
    } else {
      account_ids = await this.convertAccountFilters(globalFilters, true).then(
        (accounts) => accounts
      );
      search_stream_ids = await this.convertSavedStreamFilters(
        globalFilters,
        true
      ).then((streams) => streams);
    }

    const payload = {
      account_ids,
      search_stream_ids,
      filters: globalFilters.filter(
        ({ field }) =>
          field && field !== 'Saved Streams' && field !== 'Inbox Accounts'
      ),
      offset,
      limit
    };

    return this.api
      .post(endpoint, payload)
      .pipe(
        map((response: any) => {
          response.results.forEach((post: InsightsPost) => {
            // instantiate monitoring results from ActivityModel too
            post.data = this.activityModel.inject(post.data);
          });

          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  /**
   * https://github.com/orlo/orlo/tree/master/insights#get-insightsinsightswidgetwallet
   */
  getWidgets(): Promise<InsightsWidget[]> {
    const endpoint = `${this.api.url}/insights/insightsWidgetWallet`;

    return this.api
      .get(endpoint)
      .pipe(
        map((response: { widgets: InsightsWidget[] }) => {
          const supportedWidgets = response.widgets.filter((widget) =>
            widgetTypes.hasOwnProperty(widget.type)
          );
          supportedWidgets.forEach((w) => {
            w.typeConst = widgetTypes[w.type];
            // this is override until backend starts returning empty categories array
            // because we're not using those ones, but the custom categories on the table
            // widget wallet's big definition isn't used on the frontend, it's custom widget
            // if (w.type === widgetTypes.big.key) {
            //   w['categories'] = [];
            // }
            // UNRECOMMENT ON RELEASE
          });
          return supportedWidgets;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  async convertSavedStreamFilters(
    globalFilters,
    returnFull = false
  ): Promise<Array<string>> {
    const savedStreamFilter =
      globalFilters &&
      globalFilters.find(
        (filter) => filter && filter.field === 'Saved Streams'
      );
    const savedStreams = await this.monitoringStreamsService
      .getAll()
      .then((streams) => streams);

    if (savedStreamFilter) {
      // Filter out deleted streams
      return savedStreamFilter.in.filter((streamName) =>
        savedStreams.find((stream) => stream.id === streamName)
      );
    } else if (!savedStreamFilter && returnFull) {
      // Switch to allow all streamIds to be sent to the API as default when fetching data for widgets with no stream filters
      return savedStreams.map((stream) => stream.id);
    } else {
      return [];
    }
  }

  async convertAccountFilters(
    globalFilters,
    returnFull = false
  ): Promise<Array<number>> {
    const workflowAccounts = await this.accountModel.findAllAccounts(
      this.workflowManager.getCurrentId()
    );

    const accountFilter =
      globalFilters &&
      globalFilters.find(
        (filter) => filter && filter.field === 'Inbox Accounts'
      );

    if (accountFilter) {
      // Filter out removed/insufficient permission accounts before it reaches API
      return accountFilter.in.filter((accountId) =>
        workflowAccounts.find(({ id }) => id === accountId.toString())
      );
    } else if (!accountFilter && returnFull) {
      return workflowAccounts.map(({ id }) => Number(id));
    } else {
      return [];
    }
  }

  /**
   * https://github.com/orlo/orlo/tree/master/insights#post-insightsinsightswidgetdata
   */
  async aggregateWidgetData(
    widget: DataWidget,
    globalFilters: Filter[] = [],
    streamIds: string[] = []
  ): Promise<any> {
    const endpoint = `${this.api.url}/insights/insightsWidgetData`;
    const hasAccountFilterApplied = globalFilters.find(
      ({ field }) => field === 'Inbox Accounts'
    );
    const hasSavedStreamFilterApplied = globalFilters.find(
      ({ field }) => field === 'Saved Streams'
    );
    let account_ids = [];
    let search_stream_ids = [];

    if (hasAccountFilterApplied && !hasSavedStreamFilterApplied) {
      account_ids = await this.convertAccountFilters(globalFilters, true).then(
        (accounts) => accounts
      );
    } else if (!hasAccountFilterApplied && hasSavedStreamFilterApplied) {
      search_stream_ids = await this.convertSavedStreamFilters(
        globalFilters,
        true
      ).then((streams) => streams);
    } else {
      account_ids = await this.convertAccountFilters(globalFilters, true).then(
        (accounts) => accounts
      );
      search_stream_ids = await this.convertSavedStreamFilters(
        globalFilters,
        true
      ).then((streams) => streams);
    }

    const payload = {
      account_ids,
      search_stream_ids,
      filters: globalFilters.filter(
        ({ field }) =>
          field && field !== 'Saved Streams' && field !== 'Inbox Accounts'
      ),
      tz_offset: TIMEZONE_OFFSET,
      widget
    };

    return await this.api
      .post(endpoint, JSON.stringify(payload))
      .pipe(
        map((response: any) => response),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  relabelDataModelForUi(widget, data) {
    const networks = mapToIterable(socialNetworkSettings);
    // relabels API data with semantic labels, what a horrid can of worms
    let newDataModel = {};

    function relabelNestedData(type: FieldName, nestedData, mainKey) {
      let relabeledKey;
      const nData = {};
      for (const subKey in nestedData[mainKey]) {
        switch (type) {
          case FieldName.Sentiment:
            const sentimentConst = findSentimentConst('numericKey', subKey);
            nData[sentimentConst.key3] = nestedData[mainKey][subKey];
            break;
          case FieldName.Age:
            nData[ageOptions[subKey].label] = nestedData[mainKey][subKey];
            break;
          case FieldName.Gender:
            relabeledKey =
              subKey === 'org'
                ? 'Organisation'
                : subKey.charAt(0).toUpperCase() + subKey.slice(1);
            nData[relabeledKey] = nestedData[mainKey][subKey];
            break;
          case FieldName.Channel:
            relabeledKey = networksWithInsightsSources.find(
              (channel) => subKey === channel.key
            );
            if (relabeledKey) {
              relabeledKey = relabeledKey.sourceLabel;
              nData[relabeledKey] = nestedData[mainKey][subKey];
            }
            break;
          case FieldName.Language:
            relabeledKey = LANGUAGES[subKey] || 'Unknown';
            nData[relabeledKey] = nData[relabeledKey]
              ? nData[relabeledKey] + nestedData[mainKey][subKey]
              : nestedData[mainKey][subKey];
            break;
          case FieldName.Emotion:
            relabeledKey =
              emotionsIterable.filter((emotion) => emotion.key === subKey)
                .length > 0
                ? emotions[subKey].label
                : 'Unknown';
            nData[relabeledKey] = nestedData[mainKey][subKey];
            break;
          case FieldName.CreatedAt:
            relabeledKey = moment(subKey).format('DD/MM/YYYY').toString();
            nData[relabeledKey] = nestedData[mainKey][subKey];
            break;
          default:
            nData[subKey] = nestedData[mainKey][subKey];
            break;
        }
      }

      return nData;
    }

    if (
      (widget.x_axis && widget.break_down_by) ||
      (widget.y_axis && widget.break_down_by)
    ) {
      // Handles the nested broken down data format
      for (const key in data) {
        let relabeledKey;
        switch (widget.x_axis.field) {
          case FieldName.Sentiment:
            // console.log('nestedData[mainKey]: ', nestedData[mainKey]);
            const sentimentConst = findSentimentConst('numericKey', key);
            newDataModel[sentimentConst.key3] = relabelNestedData(
              widget.break_down_by.field,
              data,
              key
            );
            break;
          case FieldName.Age:
            newDataModel[ageOptions[key].label] = relabelNestedData(
              widget.break_down_by.field,
              data,
              key
            );
            break;
          case FieldName.Gender:
            relabeledKey =
              key === 'org'
                ? 'Organisation'
                : key.charAt(0).toUpperCase() + key.slice(1);
            newDataModel[relabeledKey] = relabelNestedData(
              widget.break_down_by.field,
              data,
              key
            );
            break;
          case FieldName.Channel:
            const matchedSource = networksWithInsightsSources.find(
              (channel) => key === channel.key
            );
            if (matchedSource) {
              relabeledKey = matchedSource.sourceLabel;
            }
            newDataModel[relabeledKey] = relabelNestedData(
              widget.break_down_by.field,
              data,
              key
            );
            break;
          case FieldName.Language:
            relabeledKey = LANGUAGES[key] || 'Unknown';
            const nestedData = relabelNestedData(
              widget.break_down_by.field,
              data,
              key
            );
            newDataModel[relabeledKey] = newDataModel[relabeledKey]
              ? sumObjectsByKey(newDataModel[relabeledKey], nestedData)
              : nestedData;
            break;
            relabeledKey =
              emotionsIterable.filter((emotion) => emotion.key === key).length >
              0
                ? emotions[key].label
                : 'Unknown';
          default:
            newDataModel[key] = relabelNestedData(
              widget.break_down_by.field,
              data,
              key
            );
            break;
        }
      }
      return newDataModel;
    }

    if (
      (widget.break_down_by &&
        widget.break_down_by.field === FieldName.Sentiment) ||
      (widget.x_axis && widget.x_axis.field === FieldName.Sentiment) ||
      (widget.group_by && widget.group_by.field === FieldName.Sentiment)
    ) {
      for (const key in data) {
        const sentimentConst = findSentimentConst('numericKey', key);
        newDataModel[sentimentConst.key3] = data[key];
      }
    } else if (
      (widget.break_down_by && widget.break_down_by.field === FieldName.Age) ||
      (widget.x_axis && widget.x_axis.field === FieldName.Age) ||
      (widget.group_by && widget.group_by.field === FieldName.Age)
    ) {
      for (const key in data) {
        newDataModel[ageOptions[key].label] = data[key];
      }
    } else if (
      (widget.break_down_by &&
        widget.break_down_by.field === FieldName.Channel) ||
      (widget.x_axis && widget.x_axis.field === FieldName.Channel) ||
      (widget.group_by && widget.group_by.field === FieldName.Channel)
    ) {
      for (const key in data) {
        const matchedSource = networksWithInsightsSources.find(
          (channel) => key === channel.key
        );
        if (matchedSource) {
          newDataModel[matchedSource.sourceLabel] = data[key];
        }
      }
    } else if (
      (widget.break_down_by &&
        widget.break_down_by.field === FieldName.Gender) ||
      (widget.x_axis && widget.x_axis.field === FieldName.Gender) ||
      (widget.group_by && widget.group_by.field === FieldName.Gender)
    ) {
      for (const key in data) {
        const relabeledKey =
          key === 'org'
            ? 'Organisation'
            : key.charAt(0).toUpperCase() + key.slice(1);
        newDataModel[relabeledKey] = data[key];
      }
    } else if (
      (widget.break_down_by &&
        widget.break_down_by.field === FieldName.Language) ||
      (widget.x_axis && widget.x_axis.field === FieldName.Language) ||
      (widget.group_by && widget.group_by.field === FieldName.Language)
    ) {
      for (const key in data) {
        const relabeledKey = LANGUAGES[key] || 'Unknown';
        newDataModel[relabeledKey] = newDataModel[relabeledKey]
          ? newDataModel[relabeledKey] + data[key]
          : data[key];
      }
    } else if (
      (widget.break_down_by &&
        widget.break_down_by.field === FieldName.Emotion) ||
      (widget.x_axis && widget.x_axis.field === FieldName.Emotion) ||
      (widget.group_by && widget.group_by.field === FieldName.Emotion)
    ) {
      for (const key in data) {
        const relabeledKey =
          emotionsIterable.filter((emotion) => emotion.key === key).length > 0
            ? emotions[key].label
            : 'Unknown';
        newDataModel[relabeledKey] = data[key];
      }
    } else {
      newDataModel = data;
    }
    return newDataModel;
  }

  relabelDataModelForApi(filters) {
    const networks = mapToIterable(socialNetworkSettings);
    if (!filters) {
      return [];
    }
    return filters.map((filter) => {
      switch (filter.field) {
        case FieldName.Sentiment:
          const sentimentConst = findSentimentConst('key3', filter.eq);
          return {
            field: filter.field,
            eq: sentimentConst.numericKey
          };
          break;
        case FieldName.Age:
          return {
            field: filter.field,
            eq: AgeValueReadableKey[filter.eq]
          };
          break;
        case FieldName.Channel:
          return {
            field: filter.field,
            in: filter.in.map((filteredChannel: string) => {
              const foundFilter = networksWithInsightsSources.find(
                (channel) => filteredChannel === channel.sourceLabel
              );

              return foundFilter.key;
            })
          };
          break;
        case FieldName.Gender:
          return {
            field: filter.field,
            eq:
              filter.eq === 'Organisation'
                ? 'org'
                : filter.eq.charAt(0).toLowerCase() + filter.eq.slice(1)
          };
          break;
        case FieldName.Language:
          const values = filter.in.some((l) => l === 'Unknown')
            ? ['und', 'in', 'qme', 'zxx'] // languge keys merged into 'unknown' (that don't exist but come from the backend - send it back to the backend as it is)
            : filter.in.map((l) => getKeyByValue(LANGUAGES, l));

          return {
            field: filter.field,
            in: values
          };
          break;
        case FieldName.Emotion:
          return {
            field: filter.field,
            in: filter.in.map((e) => e.toLowerCase())
          };
          break;
        case FieldName.Author:
          return {
            field: `${filter.field} Name`,
            in: filter.in.map((e) => e.toLowerCase())
          };
          break;
        case FieldName.Visibility:
          return {
            field: `Is Private`,
            eq: filter.eq
          };
          break;
        default:
          return filter;
          break;
      }
    });
  }

  getInsightSchema(): Promise<Schema> {
    const endpoint = `${this.api.url}/insights/insightsSchema`;

    return this.api
      .get(endpoint)
      .pipe(
        map((response: any) => {
          console.log('response: ', response);
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  async getInsightsCluster(
    globalFilters: Filter[] = [],
    streamIds: string[] = []
  ): Promise<{ success: boolean; ticket: string }> {
    const endpoint = `${this.api.url}/insights/insightsCluster`;

    // The filters for drilling down are added per chart and don't behave the same as the global filter system. This method makes sure they are set back to integers before going to the API
    // globalFilters = this.relabelDataModelForApi(globalFilters);

    const hasAccountFilterApplied = globalFilters.find(
      ({ field }) => field === 'Inbox Accounts'
    );
    const hasSavedStreamFilterApplied = globalFilters.find(
      ({ field }) => field === 'Saved Streams'
    );
    let account_ids = [];
    let search_stream_ids = [];

    if (hasAccountFilterApplied && !hasSavedStreamFilterApplied) {
      account_ids = await this.convertAccountFilters(globalFilters, true).then(
        (accounts) => accounts
      );
    } else if (!hasAccountFilterApplied && hasSavedStreamFilterApplied) {
      search_stream_ids = await this.convertSavedStreamFilters(
        globalFilters,
        true
      ).then((streams) => streams);
    } else {
      account_ids = await this.convertAccountFilters(globalFilters, true).then(
        (accounts) => accounts
      );
      search_stream_ids = await this.convertSavedStreamFilters(
        globalFilters,
        true
      ).then((streams) => streams);
    }

    const payload = {
      account_ids,
      search_stream_ids,
      filters: globalFilters.filter(
        ({ field }) =>
          field && field !== 'Saved Streams' && field !== 'Inbox Accounts'
      )
    };

    return this.api
      .post(endpoint, payload)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  getInsightsClusterDocumentStatus(
    ticket: string
  ): Promise<{ completed: boolean; ticket: string }> {
    const endpoint = `${this.api.url}/insights/insightsClusterDocumentStatus?ticket=${ticket}`;
    // const endpoint = `https://beta.socialsignin.co.uk/insights/insightsClusterDocument?ticket=${ticket}`;

    return this.api
      .get(endpoint)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  getInsightsClusterDocument(
    ticket: string
  ): Promise<InisightsClusterDocument[]> {
    const endpoint = `${this.api.url}/insights/insightsClusterDocument?ticket=${ticket}`;
    // const endpoint = `https://beta.socialsignin.co.uk/insights/insightsClusterDocument?ticket=${ticket}`;

    return this.api
      .get(endpoint)
      .pipe(
        map((response: any) => {
          return response.document;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  /**
   * Used for populating location dropdown options as selections grows
   */
  async getLocations(
    globalFilters: any,
    locationTier: LocationTier = LocationTier.Country
  ): Promise<any> {
    const endpoint = `${this.api.url}/insights/insightsFiltersTopLocations`;

    const search_stream_ids = await this.convertSavedStreamFilters(
      globalFilters,
      true
    ).then((streams) => streams);

    const account_ids = await this.convertAccountFilters(
      globalFilters,
      true
    ).then((accounts) => accounts);

    const payload = {
      account_ids,
      location_tier: locationTier,
      search_stream_ids,
      filters: globalFilters
    };

    return this.api
      .post(endpoint, payload)
      .pipe(
        map((response: any) => {
          return Object.keys(response.data);
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }
}
