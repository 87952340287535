<div class="drilldown-modal-content-container modal-popup">
  <div class="heading">
    <h2 class="title">View Post Results</h2>
    <div class="buttons">
      <button
        *ngIf="displayApplyFilters"
        class="g-btn"
        (click)="applyFilter()"
      >
        Apply Filter
      </button>
      <button class="g-btn g-btn-secondary bg-white"
              (click)="modalInstance.dismiss()">
        Close
      </button>
    </div>
  </div>

  <div class="tabs" *ngIf="enableThemeClusters">
    <div
      class="tab-name"
      [class.active]="activeTab === 'results'"
      (click)="changeTab('results')"
    >Results</div>
    <div
      class="tab-name"
      [class.active]="activeTab === 'clusters'"
      (click)="changeTab('clusters')"
    >Theme Clusters</div>
    <div
      *ngIf="point && !templateMode"
      class="tab-name"
      [class.active]="activeTab === 'annotations'"
      (click)="changeTab('annotations')"
    >Moments In Time</div>
  </div>

  <div class="drilldown-content">
    <div
      *ngIf="activeTab === 'results'"
      class="posts-area"
      [ssiObserveWidthAndHeight]="true"
      (onWidthChange)="onContainerWidthChange($event)"
    >

      <div class="report-action-bar-actions" *ngIf="selectedPosts.length > 0">
        <div class="report-action-bar-container report-action-bar-container-wrap">
          <div class="report-actions-bar-bulk-actions">
            <div class="report-action-bar-actions-left">
              <div class="report-action-bar-selected-posts-container">
                <ssi-checkbox-2
                  [label]=""
                  [variant]="'square'"
                  [(ngModel)]="selectedPosts.length"
                  (ngModelChange)="clearSelectedPosts()"></ssi-checkbox-2><span>{{ selectedPosts.length }} results selected</span>
              </div>
            </div>
            <div class="report-action-bar-actions-right">
              <div class="report-bulk-actions">
                <ul class="report-bulk-actions-list">
                  <li class="report-bulk-actions-list-item"
                      (click)="assignPost()">
                    <i class="ssi ssi-assign-correct"></i>
                    <span [class.label-disabled]="containerWidth < 1100">Assign post</span>
                  </li>
                  <li class="report-bulk-actions-list-item"
                      (click)="tagsSelector.show($event); populateTags()">
                    <i class="ssi ssi-tag-test"></i>
                    <span [class.label-disabled]="containerWidth < 1100">Tag post</span>
                  </li>
                  <li class="report-bulk-actions-list-item"
                      (click)="showSentimentMenu = true"
                      cdkOverlayOrigin
                      #sentimentMenuTriggerOrigin="cdkOverlayOrigin">
                    <i class="ssi ssi-add-sentiment"></i>
                    <span [class.label-disabled]="containerWidth < 1100">Amend Sentiment</span>
                  </li>
                  <li class="report-bulk-actions-list-item"
                      [tooltipDisabled]="!disableBulkEmotions"
                      [class.button-disabled]="disableBulkEmotions"
                      [ssiTooltip]="'WERE_STILL_ANALYSING_SOME_OF_THESE_RESULTS_PLEASE_TRY_AGAIN_LATER' | translate"
                      (click)="emotionsSelector.show($event)">
                    <i class="ssi ssi-emotion"></i>
                    <span [class.label-disabled]="containerWidth < 1100">Amend Emotion</span>
                  </li>
                  <li class="report-bulk-actions-list-item"
                      [tooltipDisabled]="!disableBulkDelete"
                      [class.button-disabled]="disableBulkDelete"
                      [ssiTooltip]="'WE_ARE_UNABLE_TO_DELETE_INBOX_ACCOUNT_MESSAGES_PLEASE_USE_THE_INBOX_INSTEAD' | translate"
                      (click)="deletePosts()">
                    <i class="ssi ssi-delete delete-icon"></i>
                    <span [class.label-disabled]="containerWidth < 1100">Delete posts</span>
                  </li>
                </ul>

                <ssi-dropdown-select-2 #tagsSelector
                                       [headless]="true"
                                       [options]="tags"
                                       [yOffset]="'-28px'"
                                       [xPlacement]="'right'"
                                       [filterable]="true"
                                       [multiple]="true"
                                       [toggleAllEnabled]="false"
                                       [filterPlaceholder]="'TYPE_TAG_NAME_SENTANCE_CASE' | translate"
                                       [(ngModel)]="selectedTags"
                                       (ngModelChange)="updateTags($event)"></ssi-dropdown-select-2>

                <ng-template
                  #sentimentMenu="cdkConnectedOverlay"
                  cdkConnectedOverlay
                  [cdkConnectedOverlayOrigin]="sentimentMenuTriggerOrigin"
                  [cdkConnectedOverlayOpen]="showSentimentMenu"
                  [cdkConnectedOverlayFlexibleDimensions]="false"
                  [cdkConnectedOverlayGrowAfterOpen]="true"
                  [cdkConnectedOverlayHasBackdrop]="true"
                  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
                  [cdkConnectedOverlayPush]="true"
                  (backdropClick)="showSentimentMenu = false; clearSelectedPosts();"
                >
                  <ssi-sentiment-amend-box
                    [isInsights]="true"
                    [sentiment]="null"
                    (onChangeSentiment)="changeSentiment($event)"
                  ></ssi-sentiment-amend-box>
                </ng-template>

                <ssi-dropdown-select-2 #emotionsSelector
                                       [headless]="true"
                                       [options]="emotionsSorted"
                                       [yOffset]="'-28px'"
                                       [xPlacement]="'right'"
                                       [filterable]="true"
                                       [multiple]="true"
                                       [toggleAllEnabled]="false"
                                       [filterPlaceholder]="'SEARCH_EMOTIONS' | translate"
                                       [(ngModel)]="selectedEmotions"
                                       [disabled]="disableBulkEmotions"
                                       (ngModelChange)="updateEmotions($event)"></ssi-dropdown-select-2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ssi-insights-posts
        [clusterId]="clusterId"
        [jobTicket]="jobTicket"
        [activeFilters]="activeFilters"
        [sanitizeFilters]="false"
        [streamIds]="streamIds"
        [initialLoadingTemplate]="initialLoadingTemplate"
        [selectedPosts]="selectedPosts"
        [bulkSentimentUpdate]="bulkSentimentUpdate"
        (postSelectToggled)="onPostSelectToggled($event)"
      ></ssi-insights-posts>
    </div>

    <div
      *ngIf="activeTab === 'clusters'"
      class="interaction-area"
    >
      <div class="drilldown-content">
        <div class="interaction-area">
          <!-- <div class="sentiments-area">
          <div
            class="sentiment-block"
            *ngFor="let s of sentimentsConstIterable"
          >
            <span
              [ngStyle]="{'background-color': s.color2}"
              class="sentiment-color"
            ></span><span
              class="sentiment-label"
              translate="{{s.label}}"
            ></span>
          </div>
        </div> -->
          <div
            class="themes-container"
            [class.centered]="loadingClusters"
          >
            <div
              class="themes-loading"
              *ngIf="loadingClusters; else themesContent"
            >
              <div class="message-info">
                <ng-container *ngIf="querySubmitted && !clusteringJobFailed">
                  <ssi-spinner [visible]="loadingClusters"></ssi-spinner>
                  <h5>Hang tight</h5>
                  <p>
                    I'm using AI clustering technology to search for themes across loads of results, phew!
                  </p>
                </ng-container>
                <ng-container *ngIf="querySubmitted && clusteringJobFailed">
                  <h5>Job failed!</h5>
                  <p>
                    Oops, we couldn't find the data for your query.
                  </p>
                </ng-container>
                <ng-container *ngIf="!querySubmitted">
                  <h5>Query failed!</h5>
                  <p>
                    Please try new search or clicking on a different widget
                  </p>
                </ng-container>

              </div>
            </div>
            <ng-template #themesContent>
              <div class="themes-content">
                <ul>
                  <li
                    *ngFor="let theme of clusterData; let index = index"
                    (click)="selectClusterContent(theme.content)"
                  >
                    <span class="theme-index">{{index + 1}}</span> <span class="theme-name">"{{theme.openai_theme ||
                      'Unknown'}}"</span>
                    <ssi-big-number [number]="theme.size"></ssi-big-number>
                    <span class="theme-results">results</span>
                  </li>
                </ul>
              </div>
            </ng-template>

          </div>
        </div>

        <div
          class="posts-area"
          [class.dotted]="!clusterFilters"
        >
          <ssi-insights-posts
            [clusterId]="clusterId"
            [jobTicket]="jobTicket"
            [activeFilters]="clusterFilters"
            [sanitizeFilters]="false"
            [streamIds]="streamIds"
            [beforeLoadTemplate]="beforeLoadTemplate"
            [initialLoadingTemplate]="initialLoadingTemplate"
          ></ssi-insights-posts>
        </div>
      </div>
    </div>
    <div
      *ngIf="activeTab === 'annotations'"
      class="annotation-area"
    >
      <div [class.active]="!!annotationFormOpen" class="dwm-form">
        <div class="dwm-form-half">
          <div class="dwm-body-row">
            <label
              class="dwm-label"
              for="datetime"
            >Moment in time</label>
            <div class="dwm-body-row-input">
              <input
                class="dwm-input"
                id="datetime"
                type="text"
                [value]="annotationModel.datetime"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="dwm-form-half">
          <div class="dwm-body-row">
            <label
              class="dwm-label"
              for="widgetTitle"
            >Moment in time label</label>
            <div class="dwm-body-row-input">
              <input
                class="dwm-input"
                id="widgetTitle"
                type="text"
                placeholder="Add your label here..."
                [(ngModel)]="annotationModel.title"
              >
              <span
                [ngClass]="{'dwm-body-row-input-meta-active':  annotationModel.title.length}"
                class="dwm-body-row-input-meta"
              >
                {{annotationTitleMaxLength - annotationModel.title.length}}
              </span>
            </div>
          </div>
        </div>
        <div class="dwm-form-full">
          <div class="dwm-body-row-input">
            <textarea
              class="dwm-input dwm-textarea"
              id="description"
              rows="5"
              placeholder="Type your description here..."
              [(ngModel)]="annotationModel.description"
            ></textarea>
            <div class="dwm-inline-submit">
              <div (click)="annotationFormOpen = false">
                <span>Cancel</span>
              </div>
              <div (click)="saveAnnotation()">
                <span>Save Moment</span>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <div class="annotations-new" *ngIf="annotations.length === 0">
        <div class="annotations-new-container">
          <i class="ssi ssi-moment-in-time"></i>
          <h2>Add your first moment in time!</h2>
          <p>Add your custom label along with a description for the point of time you have selected on the graph</p>
          <button (click)="annotationFormOpen = true"><i class="ssi ssi-addsmall"></i></button>
        </div>
      </div>
      <div *ngIf="annotations.length > 0">
        <div class="annotations-header">
          <div>Moment in time</div>
          <div>Label</div>
          <div>Description</div>
          <div><button class="annotations-add-button" (click)="annotationFormOpen = true"><i class="ssi ssi-addsmall"></i>Create New</button></div>
        </div>
        <div class="annotations-list scrollable">
          <div class="annotations-row" *ngFor="let annotation of annotations">
            <div>{{annotation.datetime}}</div>
            <div>{{annotation.title}}</div>
            <div>{{annotation.description}}</div>
            <div>
              <button class="annotations-action-button edit" (click)="editAnnotation(annotation)"><i class="ssi ssi-correct-edit-small"></i></button>
              <button class="annotations-action-button delete" (click)="deleteAnnotation(annotation.datetime)"><i class="ssi ssi-small-delete"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #beforeLoadTemplate>
  <div class="message-info">
    <i class="ssi ssi-select-microcopy"></i>
    <h5>Select a Cluster!</h5>
    <p>
      Drill down further and view stream results from your Cluster
    </p>
  </div>
</ng-template>

<ng-template #initialLoadingTemplate>
  <div class="message-info">
    <ssi-spinner [visible]="true"></ssi-spinner>
    <h5>Please wait!</h5>
    <p>
      We are loading the posts that match your theme selection
    </p>
  </div>
</ng-template>