import './date-time-picker.component.scss';
import moment from 'moment';
import {
  Component,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  AfterContentChecked,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateButton } from 'angular-bootstrap-datetimepicker';
import { Subject } from 'rxjs';
import {
  endOfDay,
  getHours,
  getMinutes,
  isPast,
  isSameDay,
  isValid,
  setDate,
  setDay,
  setHours,
  setMinutes
} from 'date-fns';
import { CalendarMonthViewDay } from 'angular-calendar';

export const DATE_PICKER_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateTimePickerComponent), //tslint:disable-line
  multi: true
};
let _minDate; // Needed for datetimepicker getSelectableDates scope issue
let _maxDate; // Needed for datetimepicker getSelectableDates scope issue

interface MonthDayMeta {
  isDisabled?: boolean;
  isScheduled?: boolean;
  scheduledCount?: number;
}

type MonthDay = CalendarMonthViewDay<MonthDayMeta>;

function markDisabled(day: MonthDay) {
  day.cssClass = 'disabled';
  day.meta = {
    isDisabled: true
  };
}

function addZeroBefore(hour: number): string {
  return (hour < 10 ? '0' : '') + hour;
}

@Component({
  selector: 'ssi-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  providers: [DATE_PICKER_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class DateTimePickerComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @Input() disabled: boolean;
  @Input() dateFormat: string;
  @Input() dateLabel: string;
  @Input() timeLabel: string;
  @Input() pastDateTooltipTitle: string;
  @Input() pastDateTooltipBody: string;
  @Input() showTime: boolean = true;
  @Input() placeholder: string = 'No date set';
  @Input() selectedDate: Date = new Date();
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() required: boolean;
  @Input() calHeaderTitle: string = 'Select date';
  @Input() closeOnSelect: boolean = true;

  meridian: 'am' | 'pm' = 'am';
  calendarDate = new Date();
  showDatePicker = false;
  hours = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12'
  ];

  minutes = Array.from(Array(60).keys())
    .map((n) => n.toString())
    .map((stringNo, i) => {
      if (i <= 9) {
        return `0${stringNo}`;
      } else {
        return stringNo;
      }
    });

  date: { date: Date; hour: string; minute: string } = {
    date: undefined,
    hour: this.hours[0],
    minute: this.minutes[0]
  };

  refreshCalendar = new Subject();

  @Output() onDateSelected = new EventEmitter();

  defaultDateFormat = 'EEEE d MMM y';

  constructor() {}

  ngOnInit() {
    _minDate = this.minDate;
    _maxDate = this.maxDate;

    if (this.selectedDate) {
      this.setTheInitialDateTime();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes['selectedDate'] &&
      !changes['selectedDate'].currentValue
    ) {
      this.date = {
        date: undefined,
        hour: this.hours[0],
        minute: this.minutes[0]
      };
    }
  }

  checkMeridianAddZeroBefore(hour: number): string {
    let stringHour: string = '';
    if (hour >= 12) {
      this.meridian = 'pm';
      const res = hour - 12;
      stringHour = addZeroBefore(res);
    } else if (hour >= 10 && hour < 12) {
      stringHour = `${hour}`;
    } else {
      // below 10
      stringHour = `0${hour}`;
    }
    return stringHour;
  }

  setTheInitialDateTime() {
    this.date = {
      date: this.selectedDate,
      hour: this.checkMeridianAddZeroBefore(getHours(this.selectedDate)),
      minute: addZeroBefore(getMinutes(this.selectedDate))
    };
    this.onSelectDate();
  }

  private onChangeCallback: (_: any) => void = () => {};

  writeValue(newValue: Date): void {
    this.selectedDate = newValue;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {}

  private setViewValue(newValue: Date) {
    this.writeValue(newValue);
    this.onChangeCallback(newValue);
  }

  onSelectDate() {
    this.onDateSelected.emit(this.selectedDate);
    this.setViewValue(this.selectedDate);
  }

  getSelectableDates(dateButton: DateButton) {
    if (_minDate && moment(dateButton.value).isBefore(moment(_minDate))) {
      return;
    }
    if (_maxDate && moment(dateButton.value).isAfter(moment(_maxDate))) {
      return;
    }
    return dateButton;
  }

  async monthChanged() {
    this.refreshCalendar.next();
  }

  dayClicked($event: { date: Date }) {
    if (!this.selectedDate || typeof this.selectedDate === 'string') {
      this.selectedDate = new Date($event.date);
    }
    this.selectedDate.setDate($event.date.getDate());

    this.date.date = $event.date;
    this.showDatePicker = !this.closeOnSelect;

    this.onSelectDate();
  }

  onHourChange(hour: string) {
    if (!this.selectedDate || !moment(this.selectedDate).isValid()) {
      this.selectedDate = new Date();
      this.date.date = this.selectedDate;
    }
    this.selectedDate = setHours(this.selectedDate, Number(hour));
    if (this.meridian === 'pm') {
      this.setMeridianTime('pm');
    }
    this.onSelectDate();
  }

  onMinuteChange(minute: string) {
    if (!this.selectedDate || !isValid(this.selectedDate)) {
      this.selectedDate = new Date();
      this.date.date = this.selectedDate;
    }
    this.selectedDate = setMinutes(this.selectedDate, Number(minute));
    this.onSelectDate();
  }

  setMeridianTime(value: 'am' | 'pm') {
    this.meridian = value;
    this.selectedDate =
      value === 'am'
        ? setHours(this.selectedDate, getHours(this.selectedDate) - 12)
        : setHours(this.selectedDate, getHours(this.selectedDate) + 12);
    this.onSelectDate();
  }

  addCssClasses(days: MonthDay[]) {
    days.forEach((day) => {
      if (isPast(endOfDay(day.date))) {
        markDisabled(day);
      } else if (isSameDay(day.date, this.selectedDate)) {
        day.cssClass = 'active';
      }
    });
  }
}
