<div
  class="rounded-box-check-container"
  [class.selected]="selected"
  [class.bordered-initial]="borderedInital"
>
  <i
    *ngIf="leadingIcon && selected || suggestiveCheck"
    class="{{leadingIcon}}"
  ></i>
  <span>
    {{text}}
  </span>
</div>