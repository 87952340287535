import { utils, Record } from 'js-data';
import { Subject } from 'rxjs/Subject';
import { get } from 'lodash-es';
import {
  TeamsService,
  Team,
  ColleaguesService,
  Colleague
} from '../../../../../apps/angular/common/services/api';
import {
  ContentCheckFlag,
  sensitiveContentFlags,
  SocialType
} from '../../../../../apps/angular/common/enums';
import { appInjector } from '../../../../../apps/angular/app-injector';
import { api } from '../../core/services/api';
import { Note, NoteModel } from '../../note/services/noteModel';
import { Account } from '../../account/services/accountModel';
import { services } from '../../common';
import { User, UserModel } from '../../user/services/userModel';
import { Model } from '../../model';
import { AccountModel } from '../../exports';
import { OutboxModel, Outbox } from '../../publish/services/outboxModel';
import { OutboxPublisher } from '../../publish/services/outboxPublisher';

function toggleLike(activity, isLike, requestPromise) {
  activity.social_actions.is_liked = isLike;
  return requestPromise
    .then(({ data: partialActivity }) => {
      utils.deepMixIn(activity, partialActivity);
      return activity;
    })
    .catch((error) => {
      console.error(error);
      activity.social_actions.is_liked = !isLike;
    });
}

function recursiveUpdate(activity, parentActivity, applyFunction) {
  applyFunction(activity, parentActivity);
  activity
    .getChildren()
    .forEach((childActivity) =>
      recursiveUpdate(childActivity, activity, applyFunction)
    );
}
// down below put all the file types that Filestack is sending us
const MEDIA_TYPES = Object.freeze({
  image: Object.freeze(['photo', 'animated_gif']),
  video: ['video'],
  document: ['pdf']
});

export enum ActivityStatus {
  Unread = 'unread',
  Unactioned = 'unactioned',
  Actioned = 'actioned'
}

export interface ActivityMedia {
  link: string;
  size: {
    full: string;
    thumb: string;
  };
  type: string;
}

export interface ActivityAuthor {
  avatar: string;
  id: string;
  influence: number;
  link: string;
  name: string;
  username: string;
  verified?: boolean;
}

export enum ShareType {
  QuotedTweet = 'quoted-tweet',
  LinkedinShare = 'linkedin-share'
}

export interface SharePreview {
  url: string;
  type: ShareType;
  social_id: string;
}

export function getActivityMediaByType(media: ActivityMedia[]) {
  media = media || []; // may be undefined in unit tests
  const mediaByType: {
    image: ActivityMedia[];
    video: ActivityMedia[];
    document: ActivityMedia[];
  } = {
    image: [],
    video: [],
    document: []
  };
  Object.entries(MEDIA_TYPES).forEach(([mediaType, activityTypes]) => {
    mediaByType[mediaType] = media.filter((iMedia) =>
      activityTypes.includes(iMedia.type)
    );
  });
  return mediaByType;
}

export function getLinkPreview(media) {
  return Array.isArray(media)
    ? media.find((item) => item.type === 'link-preview') || null
    : null;
}

export function getSharePreview(media): SharePreview {
  return (
    Array.isArray(media) &&
    media.find(
      (m) =>
        m.type === ShareType.QuotedTweet || m.type === ShareType.LinkedinShare
    )
  );
}

function getCanToggleSilenced(activity: Activity) {
  const replyType: string = get(activity, 'social_actions.reply_type');
  const isPrivate: boolean = get(activity, 'interaction.is_private');
  return !isPrivate;
}

function getCanReply(activity: Activity) {
  const deletedAt: string = get(activity, 'interaction.deleted_at');
  if (deletedAt) {
    return false;
  }
  const flattenPrivateThreads: boolean = get(
    services.models.get<AccountModel>('account').get(activity.account_id), // account relation not available yet
    'socialNetwork.activity.flattenPrivateThreads'
  );
  const canReply: boolean = get(activity, 'social_actions.can_reply');
  const isPrivate: boolean = get(activity, 'interaction.is_private');
  const conversationDepth: number = get(activity, 'conversation.depth');
  if (
    canReply ||
    (isPrivate && conversationDepth > 0 && flattenPrivateThreads)
  ) {
    return true;
  } else {
    return false;
  }
}

function getSentiment(
  activity: Activity
): { key: string; label: string; value: number } {
  const sentimentEnum: number = get(activity, 'properties.sentiment_enum');
  const sentiment: number = get(activity, 'properties.sentiment');

  if (typeof sentimentEnum === 'number') {
    // normally lookup to the sentiments constants should be done instead of ifology below but for some reason constants can't be imported here
    if (sentimentEnum === -2) {
      return {
        key: 'negative',
        label: 'Very negative',
        value: -0.75
      };
    } else if (sentimentEnum === -1) {
      return {
        key: 'semiNegative',
        label: 'Negative',
        value: -0.25
      };
    } else if (sentimentEnum === 0) {
      return {
        key: 'neutral',
        label: 'Neutral',
        value: 0
      };
    } else if (sentimentEnum === 1) {
      return {
        key: 'semiPositive',
        label: 'Positive',
        value: 0.25
      };
    } else if (sentimentEnum === 2) {
      return {
        key: 'positive',
        label: 'Very positive',
        value: 0.75
      };
    } else {
      return {
        key: 'neutral',
        label: 'Neutral',
        value: 0
      };
    }
  } else if (typeof sentiment === 'number') {
    if (sentiment < -0.5) {
      return {
        key: 'negative',
        label: 'Very negative',
        value: -0.75
      };
    } else if (sentiment < 0) {
      return {
        key: 'semiNegative',
        label: 'Negative',
        value: -0.25
      };
    } else if (sentiment === 0) {
      return {
        key: 'neutral',
        label: 'Neutral',
        value: 0
      };
    } else if (sentiment <= 0.5) {
      return {
        key: 'semiPositive',
        label: 'Positive',
        value: 0.25
      };
    } else {
      return {
        key: 'positive',
        label: 'Very positive',
        value: 0.75
      };
    }
  } else {
    // no sentiment set
    return null;
  }
}

function getAccountFromId(accountId) {
  return services.models.get<AccountModel>('account').get(accountId);
}

export function getLargerActivityAuthorAvatar(
  accountId: string,
  author: ActivityAuthor
): string {
  return getAccountFromId(
    accountId
  ).socialNetwork.activity.getLargerAuthorAvatar(author);
}

export class Activity extends Record {
  id: string;
  account_id: string;
  account: Account;
  etag: string;

  author: ActivityAuthor;

  campaign?: {
    id: number;
  };

  conversation: {
    depth: number;
    length: number;
    responses: string[];
    thread_id: string;
    root_thread_id?: string;
  };

  inbox: {
    actioned_at: string;
    actioned_by: string;
    actioned_response_time: number;
    assigned_to_group: number;
    assigned_to_user: number;
    is_actioned: boolean;
    is_viewed: boolean;
    requires_action: boolean;
    is_silenced?: boolean;
    silenced_at?: string;
    silenced_by?: string;
    priority?: string; // numeric string
  };

  outbox_message?: any;
  outbox?: Outbox; // Outbox instantiated from 'outbox_message'
  /**
   * Only set when filling fake activities
   */
  isTempActivity?: boolean;
  reply_requires_validation?: boolean;
  interaction: {
    content: string;
    original_content?: string;
    created_at: string;
    deleted_at: string;
    deleted_by: number;
    redacted_at: string;
    redacted_by: number;
    entities: {
      hashtags: { text: string; indices: [number, number] }[];
      symbols: any;
      urls: {
        display_url: string;
        expanded_url: string;
        indices: [number, number];
        url: string;
      }[];
      user_mentions: {
        id: number;
        id_str: string;
        indices: [number, number];
        name: string;
        screen_name: string;
      }[];
    };
    id: string;
    id_int: number;
    is_private: boolean;
    link: string;
    object_type: string;
    /**
     * Could be `instagram_graph_hashtag`, `facebook`, `linkedin`, etc. from `SocialType`
     */
    social_type: SocialType;
    source: string;
    updated_at: string;
    rating?: number; // 1-5
    title?: string;
  };

  media: ActivityMedia[];

  content_check: {
    flags: ContentCheckFlag[];
  };

  notes: Note[];

  properties: {
    language: string;
    sentiment: number;
    paid_post: boolean;
  };

  social_actions: {
    can_delete: boolean;
    can_hide: boolean;
    can_like: boolean;
    can_reply: boolean;
    can_share: boolean;
    is_commented: boolean;
    is_hidden: boolean;
    is_liked: boolean;
    is_shared: boolean;
    like_count: number;
    reply_type: string;
    share_count: number;
  };

  insights: {
    content: {
      emotion: string[];
      // locations: any[];
    };
    author: {
      gender: string;
      min_age: number;
      terms: string[];
      // locations: any[];
    };
    location?: {
      address: {
        city: string;
        country: string;
        locality: string;
        state: string;
      };
      geo: {
        lat: number;
        lon: number;
      };
    };
  };

  tags: string[];

  /**
   * Insights monitoring result data from LexisNexis
   */
  moreover_extra?: {
    /**
     * i.e. "United Kingdom" - not used, `activity.insights.location.address.country` is used instead
     */
    country: string;
    /**
     * i.e. `2024-07-31T10:18:36+00:00`
     */
    published_date: Date;
    /**
     * i.e. `South Wales Guardian`
     */
    source_name: string;
  };
  // monitoring activities only
  search_stream_id: string;

  isConvertedToActivity: boolean;

  get assignedToTeam(): Team {
    if (!this.inbox || !this.inbox.assigned_to_group) {
      return undefined;
    }
    const teamsService = appInjector().get(TeamsService);
    return teamsService.store.find(this.inbox.assigned_to_group);
  }

  get deletedBy(): Colleague {
    if (!this.interaction || !this.interaction.deleted_by) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.interaction.deleted_by);
  }

  get redactedBy(): Colleague {
    if (!this.interaction || !this.interaction.redacted_by) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.interaction.redacted_by);
  }

  get assignedToUser(): Colleague {
    if (!this.inbox || !this.inbox.assigned_to_user) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.inbox.assigned_to_user);
  }

  get actionedBy(): Colleague {
    if (!this.inbox || !this.inbox.actioned_by) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.inbox.actioned_by);
  }

  get silencedBy(): Colleague {
    if (!this.inbox || !this.inbox.silenced_by) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.inbox.silenced_by);
  }

  get hasSensitiveContent(): boolean {
    if (!Array.isArray(this.content_check && this.content_check.flags)) {
      return false;
    }
    return this.content_check.flags.some(
      (f) => sensitiveContentFlags.indexOf(f) > -1
    );
  }

  // setters must be present since deepMixIn utility is trying
  // to set the property (when deep copying the object) without checking if can set
  set assignedToTeam(t: Team) {}
  set deletedBy(c: Colleague) {}
  set redactedBy(c: Colleague) {}
  set assignedToUser(c: Colleague) {}
  set actionedBy(c: Colleague) {}
  set silencedBy(c: Colleague) {}
  set hasSensitiveContent(val: boolean) {}

  set statusText(val) {}
  set usingMessengerApi(val) {}
  set sessionValidityPeriodInDays(val) {}
  set replyToSessionExpired(val) {}

  sensitiveContentRevealed = false;

  sentiment: {
    key: string;
    label: string;
    value: number;
  } | null = getSentiment(this);

  get statusText() {
    const isViewed: boolean = get(this, 'inbox.is_viewed');
    const isActioned: boolean = get(this, 'inbox.is_actioned');
    /* istanbul ignore next */
    if (!isViewed && !isActioned) {
      return ActivityStatus.Unread;
    } else if (isViewed && !isActioned) {
      return ActivityStatus.Unactioned;
    } else if (isViewed && isActioned) {
      return ActivityStatus.Actioned;
    }
  }

  get usingMessengerApi(): boolean {
    return this.interaction && this.interaction.object_type === 'message';
  }

  get sessionValidityPeriodInDays(): number {
    // logic copied from activity.ctrl.ts
    const SESSION_VALIDITY_PERIOD_BY_SOCIAL_TYPE: { [key: string]: number } = {
      whatsapp: 1,
      facebook: this.usingMessengerApi ? 7 : 1
    };

    return SESSION_VALIDITY_PERIOD_BY_SOCIAL_TYPE[
      this.interaction && this.interaction.social_type
    ];
  }

  get replyToSessionExpired(): boolean {
    // applies to private inbound activities and seemingly for facebook and whatsapp only
    // TODO: cannot respond to e.g. review_trackers etc., see reply-box.component.ts, 'messageSessionInvalid' flag
    // TODO: fix and reuse the logic for determining 'this.lastMessageReceivedAt' in the activity.ctrl.ts
    // see also: https://orlo.slack.com/archives/C020DUYTZU0/p1669988120790899

    if (!this.sessionValidityPeriodInDays) {
      return false;
    }

    const sessionValidityPeriodInMs =
      this.sessionValidityPeriodInDays * 24 * 60 * 60 * 1000;

    const createdAt = Date.parse(this.interaction.created_at);

    return createdAt + sessionValidityPeriodInMs <= new Date().getTime();
  }

  mediaByType = getActivityMediaByType(this.media);
  linkPreview = getLinkPreview(this.media);
  sharePreview: SharePreview = getSharePreview(this.media);
  canToggleSilenced = getCanToggleSilenced(this);
  canReply = getCanReply(this);

  getChildren() {
    return services.models.get<ActivityModel>('activity').filter({
      'conversation.depth': this.conversation.depth + 1,
      'conversation.thread_id': this.id
    });
  }

  getResponses() {
    const promises = this.conversation.responses.map((outboxId) =>
      services.models.get<OutboxModel>('outbox').findAll({ id: outboxId })
    );
    return utils.Promise.all(promises);
  }

  like() {
    return toggleLike(
      this,
      true,
      api.post('activity/like', { id: this.id, recursive: false })
    );
  }

  unlike() {
    return toggleLike(
      this,
      false,
      api.del('activity/like', { params: { id: this.id, recursive: false } })
    );
  }

  changeStatus(status: ActivityStatus) {
    switch (status) {
      case ActivityStatus.Actioned:
        if (this.inbox.is_actioned) {
          return utils.Promise.resolve(false);
        }
        break;

      case ActivityStatus.Unactioned:
        if (this.inbox.is_viewed && !this.inbox.is_actioned) {
          return utils.Promise.resolve(false);
        }
        break;

      case ActivityStatus.Unread:
        if (!this.inbox.is_viewed) {
          return utils.Promise.resolve(false);
        }
        break;

      default:
        return utils.Promise.reject(new Error('Unknown status'));
    }
    return api
      .post('activity/status', { id: this.id, status, recursive: false })
      .then(({ data: newActivity }) => {
        const activity = services.models
          .get<ActivityModel>('activity')
          .inject(newActivity);

        return activity;
      });
  }

  autoShare() {
    return api
      .post('activity/share', { id: this.id, recursive: false })
      .then(({ data: partialActivity }) => {
        utils.deepMixIn(this, partialActivity);
        return this;
      });
  }

  changeSentiment(newSentiment: number, isMonitoringActivity = false) {
    if (isMonitoringActivity) {
      return api
        .post('insights/insightsUpdateMonitoringSentiment', {
          id: this.id,
          sentiment: newSentiment
        })
        .then((result) => {
          const activity = services.models
            .get<ActivityModel>('activity')
            .inject(result.data);
          activity.sentiment = getSentiment(this);
          return activity;
        });
    } else {
      return api
        .post('activity/sentiment', { id: this.id, sentiment: newSentiment })
        .then((result) => {
          const activity = services.models
            .get<ActivityModel>('activity')
            .inject(result.data);
          activity.sentiment = getSentiment(this);
          return activity;
        });
    }
  }

  changePriority(priority: string) {
    // https://github.com/orlo/orlo/blob/master/activities/priority.md
    return api
      .post('activity/priority', { id: this.id, priority: priority })
      .then((result) => {
        const activity = services.models
          .get<ActivityModel>('activity')
          .inject(result.data);

        return activity;
      });
  }

  remove(isMonitoringActivity = false) {
    if (isMonitoringActivity) {
      return api.del('insights/insightsDeleteMonitoringResult', {
        params: { id: this.id }
      });
    } else {
      return api
        .del('activity/index', { params: { id: this.id } })
        .then((result) => {
          const activityModel = services.models.get<ActivityModel>('activity');
          recursiveUpdate(this, null, (childActivity) => {
            const activity = activityModel.inject({
              id: childActivity.id,
              interaction: {
                deleted_at: new Date().toISOString(),
                deleted_by: services.models.get<UserModel>('user').getAll()[0]
                  .id
              },
              social_actions: {
                is_hidden: false
              }
            });
            activity.canReply = getCanReply(activity);
          });
          return result;
        });
    }
  }

  redact() {
    return api.post('activity/redact', { id: this.id }).then((result) => {
      const activityModel = services.models.get<ActivityModel>('activity');
      recursiveUpdate(this, null, (childActivity) => {
        const activity = activityModel.inject({
          id: childActivity.id,
          interaction: {
            redacted_at: new Date().toISOString(),
            redacted_by: services.models.get<UserModel>('user').getAll()[0].id
          },
          social_actions: {
            is_hidden: false
          }
        });
        activity.canReply = getCanReply(activity);
      });
      return result;
    });
  }

  toggleVisibility(isHidden) {
    return api
      .post('activity/hide', { id: this.id, state: isHidden })
      .then((result) =>
        services.models.get<ActivityModel>('activity').inject(result.data)
      );
  }

  assignTo(userOrTeam, isMonitoringActivity = false) {
    const params: any = { id: this.id };
    if (
      userOrTeam instanceof Colleague ||
      services.models.get<UserModel>('user').is(userOrTeam)
    ) {
      params.user_id = userOrTeam.id;
    } else if (userOrTeam instanceof Team) {
      params.group_id = userOrTeam.id;
    }

    if (isMonitoringActivity) {
      return api.put('monitoring/assign', {
        id: this.id, // search_stream_result_id
        user_id: params.user_id
      });
    } else {
      return api.post('activity/assign', params).then((result) => {
        const activity = services.models
          .get<ActivityModel>('activity')
          .inject(result.data);

        return activity;
      });
    }
  }

  /**
   * @deprecated - remove in the near future once addResponse is the default. Still used by the fucking mobile app
   */
  saveReply(reply) {
    let activityResponseId: string = this.id;
    if (
      this.interaction.is_private &&
      this.conversation.depth > 0 &&
      this.account.socialNetwork.activity &&
      this.account.socialNetwork.activity.flattenPrivateThreads
    ) {
      activityResponseId = this.conversation.thread_id;
    }
    reply.id = activityResponseId;
    return api
      .post('activity/reply', reply)
      .then(({ data: partialActivity }: { data: any }) => {
        let replyActivity;
        if (this.social_actions.reply_type === 'comment') {
          replyActivity = partialActivity.conversation.responses;
        }

        if (partialActivity.id !== this.id) {
          Object.assign(this.inbox, partialActivity.inbox);
        } else {
          utils.deepMixIn(this, partialActivity);
        }
        services.models.get<ActivityModel>('activity').events.newReply.next();
        return replyActivity;
      });
  }

  getResponseActivity(): Activity {
    let activityResponseId: string = this.id;
    if (
      this.interaction.is_private &&
      this.conversation.depth > 0 &&
      this.account.socialNetwork.activity &&
      this.account.socialNetwork.activity.flattenPrivateThreads
    ) {
      activityResponseId = this.conversation.thread_id;
    }

    return services.models
      .get<ActivityModel>('activity')
      .get(activityResponseId);
  }

  addResponse(publisher: OutboxPublisher): Promise<Outbox> {
    return utils.Promise.all([
      publisher.publish(),
      services.models.get<UserModel>('user').getAuthUser()
    ]).then(([[outboxReply], authUser]: [[Outbox], User]) => {
      const activityId = publisher.reply
        ? publisher.reply.activityId
        : publisher.privateMessage.activityId;
      const respondToActivity = services.models
        .get<ActivityModel>('activity')
        .get(activityId);
      respondToActivity.conversation.responses.push(outboxReply.id);
      respondToActivity.inbox.actioned_at = new Date().toISOString();
      respondToActivity.inbox.is_actioned = true;
      respondToActivity.inbox.is_viewed = true;
      respondToActivity.inbox.actioned_by = authUser.id;

      services.models.get<ActivityModel>('activity').events.newReply.next();
      return outboxReply;
    });
  }

  getReplies() {
    const getReplies = (activity, type) => {
      return api
        .get('activity/reply', { params: { id: activity.id, type } })
        .then(({ data }: { data: any }) => {
          data.activities = services.models
            .get<ActivityModel>('activity')
            .inject(data.data);
          delete data.data;
          return data;
        });
    };

    return utils.Promise.all([
      getReplies(this, 'pre'),
      getReplies(this, 'post')
    ]).then(([pre, post]) => ({ pre, post }));
  }

  getNotes() {
    if (!this.notes) {
      return services.models
        .get<NoteModel>('note')
        .findAll(
          {
            subject: 'activity',
            subject_id: this.id
          },
          {
            showLoading: true,
            autoError: false
          }
        )
        .then((notes) => {
          this.notes = notes;
          return this;
        })
        .catch((e) => {
          // It's the social wall widget in dashboard, when loading twitter account - these are not real activities,
          // the API response was just made to look like activities to keep things simple.
          console.error('Could not get notes for activity:', e);
        });
    } else {
      return utils.Promise.resolve(this);
    }
  }

  addNote(content) {
    return services.models
      .get<NoteModel>('note')
      .create(
        {
          subject: 'activity',
          subject_id: this.id,
          content
        },
        {
          showLoading: true
        }
      )
      .then((note) => {
        function sortByCreated(a, b) {
          const dateA = new Date(a.created_at).getTime();
          const dateB = new Date(b.created_at).getTime();
          return dateA < dateB ? 1 : -1;
        }
        this.notes = this.notes || [];
        this.notes.push(note);
        this.notes.sort(sortByCreated);
        return this;
      });
  }

  deleteNote(note) {
    return note.destroy().then(() => {
      this.notes = this.notes.filter((iNote) => iNote !== note);
      return this;
    });
  }

  pushToInbox() {
    return api.post('activity/spam', { id: this.id }).then((result) => {
      services.models.get<ActivityModel>('activity').inject(result.data);
    });
  }

  markAsSpam() {
    return api.post('activity/spam', { id: this.id }).then((result) => {
      this.content_check = this.content_check || ({} as any);
      this.content_check.flags = this.content_check.flags || [];

      if (this.markedAsSpam) {
        this.content_check.flags = this.content_check.flags.filter(
          (flag) => flag !== ContentCheckFlag.SPAM_FLAGGED_SPAM
        );
      } else {
        const hasSpamFlag = this.content_check.flags.includes(
          ContentCheckFlag.SPAM_FLAGGED_SPAM
        );
        if (!hasSpamFlag) {
          this.content_check.flags.push(ContentCheckFlag.SPAM_FLAGGED_SPAM);
        }
      }
    });
  }

  get markedAsSpam(): boolean {
    return (
      Array.isArray(this.content_check && this.content_check.flags) &&
      this.content_check.flags.includes(ContentCheckFlag.SPAM_FLAGGED_SPAM)
    );
  }

  pushMonitoringToInbox(account) {
    return api.post('monitoring/inbox', {
      search_stream_result_id: this.id,
      search_stream_id: this.search_stream_id,
      account_id: account.id
    });
    // .then((result) =>
    //   services.models.get<ActivityModel>('activity').inject(result.data)
    // );
  }

  email(email, message, sendCopy) {
    return api.post(
      'activity/email',
      {
        id: this.id,
        message,
        send_copy: sendCopy,
        to_email: email,
        content: this.interaction.content,
        created_at: this.interaction.created_at,
        author_name: this.author.name,
        author_avatar: this.author.avatar
      },
      {
        showLoading: true
      }
    );
  }

  setTags(tags: string[], monitoring = false) {
    tags = tags.map((tag) =>
      tag
        .replace(/&lt;/g, `<`)
        .replace(/&gt;/g, `>`)
        .replace(/&quot;/g, `"`)
        .replace(/&#039;/g, `'`)
        .replace(/&amp;/g, `&`)
    );

    if (monitoring) {
      return api
        .post('monitoring/tag', { id: this.id, tag: tags, _method: 'PUT' })
        .then((result: { data: any }) => {
          this.tags = result.data.tags;
          return this;
        });
    } else {
      return api
        .post('activity/tag_v2', { id: this.id, tag: tags, _method: 'PUT' })
        .then((result: { data: any }) => {
          this.tags = result.data.tags;
          return this;
        });
    }
  }

  updateEmotions(emotions: string[], monitoring = false) {
    if (monitoring) {
      return api
        .post('insights/insightsUpdateMonitoringEmotion', {
          id: this.id,
          emotion: emotions,
          _method: 'PUT'
        })
        .then((result: { data: any }) => {
          this.insights.content.emotion = emotions;
          return this;
        });
    } else {
      return api
        .post('insights/insightsUpdateActivityEmotion', {
          id: this.id,
          emotion: emotions,
          _method: 'PUT'
        })
        .then((result: { data: any }) => {
          this.insights.content.emotion = emotions;
          return this;
        });
    }
  }

  updateLanguage(newCode) {
    return api
      .post('activity/language', { id: this.id, code: newCode })
      .then((result) =>
        services.models.get<ActivityModel>('activity').inject(result.data)
      );
  }

  loadMedia() {
    if (
      !['3', '4', '12', '19'].includes(this.account.account_type_id) ||
      this.media.length === 0 ||
      (this.media[0].type === 'link' && this.account.account_type_id === '12') // to avoid error_code: 400 - not found from backend for Instagram Story
    ) {
      return utils.Promise.resolve(this);
    }
    return api
      .get('activity/media', { params: { id: this.id }, autoError: false })
      .then((result: { data: any }) => {
        this.media = result.data;
        this.mediaByType = getActivityMediaByType(this.media);
        this.linkPreview = getLinkPreview(this.media);
        this.sharePreview = getSharePreview(this.media);
        return this;
      });
  }

  toggleSilenced(isSilenced) {
    return utils.Promise.all([
      api.post('activity/silence', {
        id: this.conversation.thread_id,
        is_silenced: isSilenced,
        _method: 'PUT'
      }),
      services.models.get<UserModel>('user').getAuthUser()
    ]).then(([, user]) => {
      const silencedBy = isSilenced ? user.id : null;
      const silencedAt = isSilenced ? new Date().toString() : null;
      const activityModel = services.models.get<ActivityModel>('activity');

      activityModel
        .filter({
          'conversation.thread_id': this.conversation.thread_id
        })
        .forEach((threadActivity: Activity) => {
          // inject is used as a workaround to force the relations to update
          const activity = activityModel.inject({
            id: threadActivity.id,
            inbox: {
              is_silenced: isSilenced,
              silenced_at: silencedAt,
              silenced_by: silencedBy
            }
          });
        });

      return this;
    });
  }
}

export class ActivityModel extends Model<Activity> {
  lastTotal: number;

  events = {
    newReply: new Subject()
  };

  constructor() {
    super('activity', {
      endpoint: 'activity/index',
      deserialize(resourceConfig, result) {
        if (typeof result.data.total !== 'undefined') {
          // inbox endpoint returns an object
          services.models.get<ActivityModel>('activity').lastTotal =
            result.data.total;
        }

        return result.data.results || result.data; // activity endpoint return an array of objects
      },
      recordClass: Activity,
      relations: {
        belongsTo: {
          account: {
            localKey: 'account_id',
            localField: 'account'
          }
          // colleague: [
          //   {
          //     localKey: 'interaction.deleted_by',
          //     localField: 'deletedBy'
          //   },
          //   {
          //     localKey: 'inbox.assigned_to_user',
          //     localField: 'assignedToUser'
          //   },
          //   {
          //     localKey: 'inbox.actioned_by',
          //     localField: 'actionedBy'
          //   },
          //   {
          //     localKey: 'inbox.silenced_by',
          //     localField: 'silencedBy'
          //   }
          // ]
          // team: {
          //   localKey: 'inbox.assigned_to_group',
          //   localField: 'assignedToTeam'
          // }
        }
      },
      beforeAdd(activities: any[]) {
        return !Array.isArray(activities)
          ? []
          : activities.map((activity) => {
              if (activity.interaction && !activity.interaction.updated_at) {
                activity.interaction.updated_at =
                  activity.interaction.created_at;
              }

              // force activity id to always be a string so that it links up to the account model id correctly which is also a string type
              if (activity.account_id) {
                activity.account_id = String(activity.account_id);
              }

              delete activity.notes;

              // activity.author might be undefined / the account might not exist in unit tests
              if (
                activity.author &&
                activity.account_id &&
                getAccountFromId(activity.account_id)
              ) {
                activity.author.avatar = getLargerActivityAuthorAvatar(
                  activity.account_id,
                  activity.author
                );
              }
              return activity;
            });
      }
    });
  }

  markAllActioned(filter) {
    return api.post(
      'inbox/bulkComment',
      Object.assign({}, filter, { update_type: 'action' }),
      {
        showLoading: true
      }
    );
  }

  markAllUnactioned(filter) {
    return api.post(
      'inbox/bulkComment',
      Object.assign({}, filter, { update_type: 'mark_read' }),
      {
        showLoading: true
      }
    );
  }

  findOneById(
    id: string,
    { bypassCache = false }: { bypassCache?: boolean } = {}
  ): Promise<Activity> {
    if (this.get(id) && !bypassCache) {
      return utils.Promise.resolve(this.get(id));
    }
    return api
      .get<{ data: Partial<Activity> }>('activity/index', {
        params: {
          id
        }
      })
      .then(({ data: activity }) => {
        return this.inject(activity);
      });
  }
}

export function activityModelFactory(dataStore?) {
  return services.models.get('activity') || new ActivityModel();
}
