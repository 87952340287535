<highcharts-chart *ngIf="hasConfigData && !rerendering; else loading"
                  [Highcharts]="highcharts"
                  [options]="config"
                  [ngClass]="theme"
                  [(update)]="updateFlag"
                  (chartInstance)="chartInstance.emit($event)"
                  style="width: 100%; height: 100%; display: block;"></highcharts-chart>

<ng-template #loading>
  <div class="loading"></div>
</ng-template>