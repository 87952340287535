import './compose-area.component.scss';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  ViewChild,
  OnInit,
  ElementRef,
  OnDestroy,
  Inject
} from '@angular/core';

import {
  Campaign,
  Account,
  AccountModel,
  OutboxPublisher,
  OutboxPublisherLink,
  OutboxFileType,
  OutboxPublisherMention,
  OutboxPublisherHighlightType,
  UserModel,
  ActivityTags,
  MediaCategory,
  ProfileSearchResult,
  User
} from '@ui-resources-angular';
import { WorkflowManagerService } from '../../../services/workflow-manager/workflow-manager.service';
import { AccountTypeIdString } from '../../../enums';

// publisher-landing
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { OutboxTagsService, Tag, TagType } from '../../../services/api';
import {
  PUBLISHER_ACTIVE,
  PublisherActive,
  PublisherActiveParams,
  PublisherDisableOptions
} from '../../publisher/publisher-active';
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive';
import { AsyncTracker, AsyncTrackerFactory } from 'angular-async-tracker';
import {
  FileUploaderDirective,
  FileUploadFile
} from '../../../directives/file-uploader/file-uploader.directive';
import { ContextMenuComponent } from '../../context-menu/context-menu.component';
import { TextInputAutocompleteDirective } from '../../text-input-autocomplete';
import { TextInputHighlightComponent } from '../../text-input-highlight';
import {
  FilestackSources,
  FilestackService,
  FilestackClient,
  FilestackPickOptions,
  CUSTOM_SOURCE_NAME,
  FilestackFile
} from '../../../services/filestack/filestack.service';
import { PublisherMentionsAutocompleteMenuComponent } from '../../publisher/publisher-landing/publisher-mentions-autocomplete-menu/publisher-mentions-autocomplete-menu.component';
import { PublisherActionsAndMediaComponent } from '../../publisher/publisher-landing/publisher-actions-and-media/publisher-actions-and-media.component';
import { CompanyService } from '../../../services/company/company.service';
import {
  maximumFileSizeForImagesInBytes,
  permittedSources,
  acceptedFileTypesForImages
} from '../../../../../../library/constants/filestack';
import { Option } from '../../../../common/components/dropdown-select-2/dropdown-select-2.component';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';
import { AiWritingToolModalComponent } from '../../publisher/publisher-landing/ai-writing-tool-modal/ai-writing-tool-modal.component';
import { youtubePostOptions } from '../youtube-post-options-select/youtube-post-options-select.component';
import { twitterReplyOptions } from '../twitter-post-options-select/twitter-post-options-select.component';
import { DeviceService } from '../../../services/device/device.service';
import {
  PublisherHighlightTagEvent,
  Stage
} from '../../publisher/publisher-landing/publisher-landing.component';
import { EditLinksModalComponent } from '../../edit-links-modal/edit-links-modal.component';
import { debounce } from '../../../util';
import {
  Draft,
  DraftListItem,
  DraftType
} from '../../../../modules/auth/marketing/drafts-library/drafts-library.service';

const MENTIONS_AUTO_COMPLETE_DEBOUNCE = 400;
const MAX_MENU_MENTIONS = 10;

@Component({
  selector: 'ssi-compose-area',
  templateUrl: './compose-area.component.html',
  styles: []
})
export class ComposeAreaComponent implements OnInit, OnChanges, OnDestroy {
  @Input() post: OutboxPublisher;
  @Input() params: PublisherActiveParams;
  @Input() accounts: Account[] = [];
  @Input() postAccounts: Account[] = [];
  @Input() campaigns: Campaign[] = [];
  @Input() disable: PublisherDisableOptions;
  @Input() slideAnimationCompleted: boolean;
  @Input() publishingLoadingTracker: AsyncTracker;
  @Input() postInvalidTooltipTemplate: TemplateRef<any>;
  @Input() mediaUploadOptionsMenuBodyTemplate: TemplateRef<any>;

  @Input() textBoxOnly = false;
  @Input() fileUploadActive = false;

  @Output() selectedAccountsChanged = new EventEmitter<Account[]>();
  @Output() gifUploadSuccess = new EventEmitter<FilestackFile>();
  @Output() changeStage = new EventEmitter();
  @Output() changeDraftState = new EventEmitter<Draft>();
  @Output() publish = new EventEmitter();
  @Output() saveAsDraft = new EventEmitter<void>();
  @Output() showPostPreview = new EventEmitter();

  linkConfigTooltipHost: HTMLElement;

  @ViewChild('linkedInImageTooltip') linkedInImageTooltip: TooltipDirective;
  @ViewChild(TextInputHighlightComponent)
  textInputHighlight: TextInputHighlightComponent;
  @ViewChild(TextInputAutocompleteDirective)
  textInputAutocomplete: TextInputAutocompleteDirective;
  @ViewChild('textarea') textarea: ElementRef;
  @ViewChild('linkHighlightTagHoverTooltip')
  linkHighlightTagHoverTooltip: TooltipDirective;
  @ViewChild('publisherActionsAndMedia')
  publisherActionsAndMedia: PublisherActionsAndMediaComponent;

  tooltipTemplate: ElementRef;
  stage: Stage = Stage.ActionsAndMedia;
  stages = Stage;
  MediaCategory = MediaCategory;
  // fileUploadingActive = false;
  activeLink: OutboxPublisherLink;
  linkPreviewLoadingTracker = this.asyncTrackerFactory.create();
  OutboxFileType = OutboxFileType;
  OutboxPublisherHighlightType = OutboxPublisherHighlightType;
  mentionsMenuComponent = PublisherMentionsAutocompleteMenuComponent;
  textareaInitialised = false;
  userToggledActiveLink = false;
  editTitle: boolean;
  hasWritingToolEnabled = false;
  showAiContextMenu = false;
  supportsContentExpiry = false;
  isMobile = false;
  AccountTypeIdString = AccountTypeIdString;

  selectedAccounts: Account[] = [];

  tags: Tag[] = [];
  selectedTags: Tag[] = [];
  createTagButtonVisible = false;
  locationInput = '';
  locationTags: ProfileSearchResult[];
  selectedLocationProfile: ProfileSearchResult;
  searchingLocation = false;
  authUser: User;

  inputFocused: boolean = false;
  draftTitle: string;

  replyOptionsLinkedIn: { [key: string]: Option } = {
    everyone: {
      key: '',
      label: 'Everyone',
      icon: 'ssi-everyone',
      description: 'Choose who can reply to this post'
    },
    noOne: {
      key: 'disabled',
      label: 'No one',
      icon: 'ssi ssi-disable',
      description: 'Choose who can reply to this post'
    }
  };
  replyOptionsLinkedInIterable: Option[] = Object.values(
    this.replyOptionsLinkedIn
  );
  selectedLinkedInReplyOption: Option = this.replyOptionsLinkedIn.everyone;

  replyOptionsTwitter: { [key: string]: Option } = {
    everyone: {
      key: '',
      label: 'Everyone',
      icon: 'ssi-everyone',
      description: 'Choose who can reply to this post. Anyone can always reply.'
    },
    following: {
      key: 'following',
      label: 'Accounts you follow',
      icon: 'ssi ssi-following',
      description:
        'Choose who can reply to this post. Anyone you follow can always reply.'
    },
    verified: {
      key: 'verified',
      label: 'Verified Accounts',
      icon: 'ssi ssi-verified-icon',
      description:
        'Choose who can reply to this post. Anyone verified can always reply.'
    },
    mentionedUsers: {
      key: 'mentionedUsers',
      label: 'Only accounts you mention',
      icon: 'ssi ssi-mentioned',
      description:
        'Choose who can reply to this post. Anyone mentioned can always reply.'
    }
  };
  replyOptionsTwitterIterable: Option[] = Object.values(
    this.replyOptionsTwitter
  );
  selectedTwitterReplyOption: Option = this.replyOptionsTwitter.everyone;

  // twitterReplyOptions = twitterReplyOptions;
  // selectedTwitterReplyOption: Option = twitterReplyOptions.everyone;

  linkTooltipInterval;
  highlightTagHoverElement: HTMLElement;

  destroyed$ = new Subject<void>();

  debouncedGetAutoCompleteMentions = debounce(async (searchText: string) => {
    const results = await this.post.getAutocompleteProfiles(searchText);
    return results.slice(0, MAX_MENU_MENTIONS);
  }, MENTIONS_AUTO_COMPLETE_DEBOUNCE);

  getAutocompleteMentions = (searchText: string) => {
    if (this.post.autocompleteProfilesAvailable() && searchText) {
      return this.debouncedGetAutoCompleteMentions(searchText);
    } else {
      return [];
    }
  };

  constructor(
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService,
    protected changeDetectorRef: ChangeDetectorRef,
    private asyncTrackerFactory: AsyncTrackerFactory,
    private cdr: ChangeDetectorRef,
    private company: CompanyService,
    private userModel: UserModel,
    public modal: NgbModal,
    private device: DeviceService,
    @Inject(PUBLISHER_ACTIVE) public publisherActive: PublisherActive
  ) {}

  ngOnChanges(changes) {
    if (changes.slideAnimationCompleted && this.slideAnimationCompleted) {
      this.refreshTextInputHighlight();
    }

    // fix needed for outdated matt component we can't update
    if (this.post) {
      this.post.text = this.post.text;
    }

    if (changes['postAccounts'] && Array.isArray(this.postAccounts)) {
      // cannot directly pass postAccounts to the dropdown, most likely because of the way it's being defined in the OutboxPublisher
      this.selectedAccounts = [...this.postAccounts];
      this.selectedReplyOptionChanged();
    }
  }

  async ngOnInit() {
    this.performInitChecks();
  }

  async performInitChecks() {
    this.device.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
      });

    try {
      this.hasWritingToolEnabled = !!(await this.company.hasFeatureAccess(
        'COMPOSER_WRITING_TOOL'
      ));

      this.authUser = await this.userModel.getAuthUser();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  // publisher landing
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  supportsCustomLinkOption() {
    return this.post.accounts.every((acc) =>
      ['4', '8'].includes(acc.account_type_id)
    );
  }

  async onDraftStatusChanged(event) {
    if (event) {
      let outboxMessages = null;
      try {
        outboxMessages = await this.post.toOutboxMessages();
      } catch (e) {}
      const draftParams = {
        id: this.params.draft ? this.params.draft.id : null,
        title: this.params.draft ? this.params.draft.title : null,
        draft_type: this.params.draft
          ? this.params.draft.draft_type
          : DraftType.Private,
        expires_at: null,
        created_at: null,
        created_by: null,
        outbox_messages: outboxMessages,
        company_id: null,
        extra: {
          isSplit: this.post.isSplit,
          splitPostAccountId: this.post.splitPostAccount.id,
          targeting: this.post.targeting,
          igShareToFeed: this.post.igShareToFeed,
          scheduleFirstCommentToggled: this.post.scheduleFirstCommentToggled
        }
      };

      this.changeDraftState.emit(draftParams);
      console.log('new draft emitted', draftParams);
    } else {
      this.post.isDraft = false;
      throw new Error(
        'Post is empty, try adding some text before saving it as a draft!'
      );
    }
  }

  refreshTextInputHighlight() {
    setTimeout(() => {
      this.textInputHighlight.refresh();
    });
  }

  draftTitleValueChanged() {
    this.onDraftStatusChanged(true);
  }

  textareaValueChanged() {
    // for some reason IE11 doesn't initialise text styles until it has a value
    if (this.post.text && !this.textareaInitialised) {
      this.textareaInitialised = true;
      this.refreshTextInputHighlight();
      this.post.text = this.post.text;
    }
    this.post.updateValidity();
  }

  handleChangeStage(event) {
    if (event.root) {
      this.changeStage.emit({ stage: event.stage });

      return;
    }

    if (event.stage !== Stage.Targeting || this.post.features.targeting) {
      this.stage = event.stage;

      return;
    }
  }

  editMention(): void {
    this.linkHighlightTagHoverTooltip.hide();
    this.textInputAutocomplete.editChoice(this.activeLink.data);
  }

  getLinkPreview() {
    this.linkPreviewLoadingTracker.add(
      Promise.resolve(this.post.getLinkPreview(this.activeLink))
    );
  }

  getSelectedChoices(): Array<{ username: string; id: string }> {
    return this.post.mentions.map((m) => {
      return m.data;
    });
  }

  getChoiceLabel(choice: { username: string; id: string }) {
    return OutboxPublisher.getMentionLabel(choice);
  }

  checkTikTokAccountSelected(): Account {
    return this.post.accounts.find(
      (account) => account.account_type_id === '16'
    );
  }

  checkYoutubeAccountSelected(): Account {
    return this.post.accounts.find(
      (account) => account.account_type_id === '6'
    );
  }

  openAiContextMenu() {
    if (this.hasWritingToolEnabled) {
      this.showAiContextMenu = true;
    }
    return;
  }

  openAiWritingToolModal(variant: 'generate' | 'rephrase' | 'hashtags') {
    if ((variant === 'rephrase' || variant === 'hashtags') && !this.post.text) {
      return;
    }
    this.showAiContextMenu = false;
    const modal = this.modal.open(AiWritingToolModalComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.variant = variant;
    modal.componentInstance.topicText = this.post.text;
    modal.componentInstance.twitterSelected = this.post.accounts.some(
      (acc) => acc.account_type_id === '2'
    );

    modal.result.then(async (suggestedText) => {
      if (suggestedText) {
        this.post.text =
          variant === 'hashtags'
            ? this.post.text + ' ' + suggestedText
            : suggestedText;
      }
    });
  }

  onOptionsFiltered(params: {
    filterTerm: string;
    filteredOptions: Tag[];
  }): void {
    const optionExists = params.filteredOptions.some(
      (o) => o.name === params.filterTerm
    );
    this.createTagButtonVisible = !optionExists;
  }

  onKeyUp(event: KeyboardEvent): void {
    if (event.keyCode === 13 || event.key === 'Enter') {
      this.searchLocation();
    }
  }

  async searchLocation() {
    this.searchingLocation = true;
    this.locationTags = null;
    this.locationTags = await this.post.getLocationTags(this.locationInput);
    this.searchingLocation = false;
  }

  setLocationTag(profileWithLocation: ProfileSearchResult) {
    if (this.selectedLocationProfile === profileWithLocation) {
      this.selectedLocationProfile = null;
      this.post.profileWithLocation = null;
    } else {
      this.selectedLocationProfile = profileWithLocation;
      this.post.profileWithLocation = profileWithLocation;
    }
  }

  getInstagramStoryMimetypes(): string[] {
    return [
      ...this.post.mediaRestrictions.imageStory.mimetypes,
      ...this.post.mediaRestrictions.videoStory.mimetypes
    ];
  }

  getInstagramCarouselMimetypes(): string[] {
    return [
      ...this.post.mediaRestrictions.image.mimetypes,
      ...this.post.mediaRestrictions.video.mimetypes
    ];
  }

  async setIsSplit(isSplit: boolean): Promise<void> {
    if (
      !isSplit &&
      this.post.isSplit &&
      this.post.splitPostAccount.isInstagram() &&
      !this.post.accounts.every((a) => a.isInstagram())
    ) {
      const igAcc = this.post.splitPostAccount;
      if (this.hasInstagramMediaThatCannotBeCombined(igAcc)) {
        await this.showConflictingMediaAttachmentsDialog();
        return;
      }
      // when going from split mode to combine mode and there are some instagram specific media (e.g. Reel), and there are other networks selected ->
      // change the category to "Post" so e.g. reel gets treated universaly (as a standard video), like for all other networks
      const files = this.post.getFilesForAccount(igAcc);
      files.forEach((f) => {
        f.mediaCategory = MediaCategory.Post;
      });
    }

    this.post.isSplit = isSplit;
  }

  hasInstagramMediaThatCannotBeCombined(igAcc: Account): boolean {
    const files = this.post.getFilesForAccount(igAcc);
    const hasInstagramSpecificMedia = files.some(
      (f) => f.mediaCategory === MediaCategory.Story
      // || f.mediaCategory === MediaCategory.Reel // TODO: reel can stay and be converted to standrad video post?
    );
    const hasMixOfImagesAndVideos =
      files.some((f) => f.type === OutboxFileType.Image) &&
      files.some((f) => f.type === OutboxFileType.Video);
    const hasMultipleVideos =
      files.filter((f) => f.type === OutboxFileType.Video).length > 1;

    return (
      hasInstagramSpecificMedia || hasMixOfImagesAndVideos || hasMultipleVideos
    );
  }

  async showConflictingMediaAttachmentsDialog(): Promise<boolean> {
    const confirmationModal = this.modal.open(ConfirmationModalComponent, {
      windowClass: 'orlo-modal'
    });
    // confirmationModal.componentInstance.icon = 'ssi ssi-paper-clip';
    confirmationModal.componentInstance.title = 'Conflicting Media Attachments';
    confirmationModal.componentInstance.info = `You cannot combine a post that has Instagram specific media types attached, like a Story or Reel with other Networks. Remove your Instagram media attachments you have uploaded first to be able to combine your post.`;
    // confirmationModal.componentInstance.cancelButton = `Don't show again`;
    confirmationModal.componentInstance.confirmButton = `Okay thanks!`;

    return confirmationModal.result;
  }
  // publisher-landing

  highlightTagMouseEnter(event) {
    this.activeLink = event.tag as OutboxPublisherLink;
    console.log('activeLink: ', this.activeLink);
    event.target.classList.add('publisher-link-tag-hover');

    if (event.target !== this.highlightTagHoverElement) {
      console.log('new target');
      this.linkHighlightTagHoverTooltip.hide();
      clearInterval(this.linkTooltipInterval);
      this.cdr.detectChanges();
    }

    // if (event.tag.data.type === OutboxPublisherHighlightType.Link) {
    this.highlightTagHoverElement = event.target;
    this.cdr.detectChanges();
    clearInterval(this.linkTooltipInterval);
    this.linkHighlightTagHoverTooltip.show();
    // }
  }

  highlightTagMouseLeave(event?) {
    if (event) {
      event.target.classList.remove('publisher-link-tag-hover');
    }

    this.linkTooltipInterval = setInterval(() => {
      this.linkHighlightTagHoverTooltip.hide();
      this.highlightTagHoverElement = undefined;
    }, 1000);
  }

  clearLinkTooltipInterval() {
    clearInterval(this.linkTooltipInterval);
  }

  async openLinkHighlightModal() {
    this.linkHighlightTagHoverTooltip.hide();
    this.highlightTagHoverElement = undefined;

    if (
      !this.activeLink.data.preview.title ||
      !this.activeLink.data.preview.description ||
      !this.activeLink.data.preview.selectedImageIndex
    ) {
      this.getLinkPreview();
    }

    const modal = this.modal.open(EditLinksModalComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.loader = this.linkPreviewLoadingTracker;
    modal.componentInstance.linkData = this.activeLink;
    modal.componentInstance.authUser = this.authUser;
    modal.componentInstance.maxImageSize = this.post.mediaRestrictions.image.size.max;
    modal.componentInstance.vanityDomain = this.post.vanityDomains
      ? this.post.vanityDomains.primary.domain
      : null;
    modal.componentInstance.postAccounts = this.post.accounts;
    const updates = await modal.result;

    if (updates) {
      this.activeLink.data.shorten = updates.linkTracking;
      this.activeLink.data.utm.enabled = updates.utmTracking;
      this.activeLink.data.prefix = updates.prefix;
      this.activeLink.data.preview.selectedImageIndex =
        updates.selectedImageIndex;
      this.post.updateCharactersRemaining();
    }
  }

  selectedReplyOptionChanged(newOption?: Option) {
    if (!newOption) {
      if (this.post.linkedInAccountsOnly()) {
        this.post.reply_control = this.selectedLinkedInReplyOption.key;
      } else if (this.post.twitterAccountsOnly()) {
        this.post.reply_control = this.selectedTwitterReplyOption.key;
      } else {
        this.post.reply_control = '';
      }
      return;
    }

    this.post.reply_control = newOption.key;
  }

  // onTwitterReplyOptionSelect(option: Option): void {
  //   this.selectedTwitterReplyOption = option;
  // }
}
